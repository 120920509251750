@import '../../node_modules/angular-material/angular-material.min.css';
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';
body #main #pageContent header {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}
body #main #pageContent article {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}
body #main #pageContent article .parent {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}
body #main #pageContent article .parent #scheduleCustomerData {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}
body #main #pageContent article .parent #scheduleCustomerData div {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}
body #main #pageContent article .parent blocworx-forbidden {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}
body #main #pageContent article .parent blocworx-authentication {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}
body #main #pageContent article .parent > * {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}
body #main #pageContent article .parent > *.home-page {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}
body #main #pageContent article .parent > * blocworx-navbar {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}
body #main #pageContent article .parent > * blocworx-navbar > div {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}
body #main #pageContent article .parent > * blocworx-navbar .sidebar {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}
body #main #pageContent article .parent > * .content {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}
body #main #pageContent article .parent > * .content .inner-standard-box-wrapper {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}
body #main #pageContent article .parent > * .content .inner-standard-box-wrapper .field-rules-hover {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}
body #main #pageContent article .parent > * .content .login-page {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}
body #main #pageContent article .parent > * .content .icon-link-wrapper {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}
body #main #pageContent article .parent > * .content .scan-station-entry {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}
body #main #pageContent article .parent > * .content .scan-station-entry * {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}
body #main #pageContent article .parent > * blocworx-authentication, body #main #pageContent article .parent > * .content {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}
body #main #pageContent article .parent > * search-data {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}
body #main #pageContent article .parent > * search-data div {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}
body #main #pageContent article .parent > * table thead, body #main #pageContent article .parent > * table tbody, body #main #pageContent article .parent > * table tr, body #main #pageContent article .parent > * table th, body #main #pageContent article .parent > * table td {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}
body #main #pageContent article .parent #scanStationHeader {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}
body #main #pageContent article .parent #scanStationHeader div, body #main #pageContent article .parent #scanStationHeader span {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}
.notransition {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}

#body.blocworx-iframe #main #pageContent article .is-in-iframe .page-bar,
#body.blocworx-iframe #main #pageContent article .is-in-iframe .advanced-reports-tab,
#body.blocworx-iframe #main #pageContent article .is-in-iframe .sop-files-list-for-station,
#body.blocworx-iframe #main #pageContent article .is-in-iframe .import-data-from-excell-tab {
  display: none !important;
}
.mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}

.mat-option {
  color: rgba(0, 0, 0, 0.87);
}

.mat-option:hover:not(.mat-option-disabled), .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}

.mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}

.mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}

.mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #673ab7;
}

.mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #ffd740;
}

.mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #f44336;
}

.mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}

.mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}

.mat-pseudo-checkbox::after {
  color: #fafafa;
}

.mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #673ab7;
}

.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-indeterminate,
.mat-accent .mat-pseudo-checkbox-checked,
.mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #ffd740;
}

.mat-warn .mat-pseudo-checkbox-checked,
.mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #f44336;
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}

.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-theme-loaded-marker {
  display: none;
}

.mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}

.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}

.mat-badge {
  position: relative;
}

.mat-badge.mat-badge {
  overflow: visible;
}

.mat-badge-hidden .mat-badge-content {
  display: none;
}

.mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}

.ng-animate-disabled .mat-badge-content,
.mat-badge-content._mat-animation-noopable {
  transition: none;
}

.mat-badge-content.mat-badge-active {
  transform: none;
}

.mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}

.mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}

.mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}

.mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}

[dir=rtl] .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}

.mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}

[dir=rtl] .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}

.mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}

[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}

.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}

[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}

.mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}

.mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}

.mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}

.mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}

[dir=rtl] .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}

.mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}

[dir=rtl] .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}

.mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}

[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}

.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}

[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}

.mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}

.mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}

.mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}

.mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}

[dir=rtl] .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}

.mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}

[dir=rtl] .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}

.mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}

[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}

.mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}

[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}

.mat-badge-content {
  color: white;
  background: #673ab7;
}

.cdk-high-contrast-active .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.mat-badge-accent .mat-badge-content {
  background: #ffd740;
  color: rgba(0, 0, 0, 0.87);
}

.mat-badge-warn .mat-badge-content {
  color: white;
  background: #f44336;
}

.mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}

.mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-button, .mat-icon-button, .mat-stroked-button {
  color: inherit;
  background: transparent;
}

.mat-button.mat-primary, .mat-icon-button.mat-primary, .mat-stroked-button.mat-primary {
  color: #673ab7;
}

.mat-button.mat-accent, .mat-icon-button.mat-accent, .mat-stroked-button.mat-accent {
  color: #ffd740;
}

.mat-button.mat-warn, .mat-icon-button.mat-warn, .mat-stroked-button.mat-warn {
  color: #f44336;
}

.mat-button.mat-primary.mat-button-disabled, .mat-button.mat-accent.mat-button-disabled, .mat-button.mat-warn.mat-button-disabled, .mat-button.mat-button-disabled.mat-button-disabled, .mat-icon-button.mat-primary.mat-button-disabled, .mat-icon-button.mat-accent.mat-button-disabled, .mat-icon-button.mat-warn.mat-button-disabled, .mat-icon-button.mat-button-disabled.mat-button-disabled, .mat-stroked-button.mat-primary.mat-button-disabled, .mat-stroked-button.mat-accent.mat-button-disabled, .mat-stroked-button.mat-warn.mat-button-disabled, .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}

.mat-button.mat-primary .mat-button-focus-overlay, .mat-icon-button.mat-primary .mat-button-focus-overlay, .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #673ab7;
}

.mat-button.mat-accent .mat-button-focus-overlay, .mat-icon-button.mat-accent .mat-button-focus-overlay, .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #ffd740;
}

.mat-button.mat-warn .mat-button-focus-overlay, .mat-icon-button.mat-warn .mat-button-focus-overlay, .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #f44336;
}

.mat-button.mat-button-disabled .mat-button-focus-overlay, .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}

.mat-button .mat-ripple-element, .mat-icon-button .mat-ripple-element, .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}

.mat-button-focus-overlay {
  background: black;
}

.mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}

.mat-flat-button, .mat-raised-button, .mat-fab, .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}

.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  color: white;
}

.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  color: rgba(0, 0, 0, 0.87);
}

.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  color: white;
}

.mat-flat-button.mat-primary.mat-button-disabled, .mat-flat-button.mat-accent.mat-button-disabled, .mat-flat-button.mat-warn.mat-button-disabled, .mat-flat-button.mat-button-disabled.mat-button-disabled, .mat-raised-button.mat-primary.mat-button-disabled, .mat-raised-button.mat-accent.mat-button-disabled, .mat-raised-button.mat-warn.mat-button-disabled, .mat-raised-button.mat-button-disabled.mat-button-disabled, .mat-fab.mat-primary.mat-button-disabled, .mat-fab.mat-accent.mat-button-disabled, .mat-fab.mat-warn.mat-button-disabled, .mat-fab.mat-button-disabled.mat-button-disabled, .mat-mini-fab.mat-primary.mat-button-disabled, .mat-mini-fab.mat-accent.mat-button-disabled, .mat-mini-fab.mat-warn.mat-button-disabled, .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}

.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  background-color: #673ab7;
}

.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  background-color: #ffd740;
}

.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  background-color: #f44336;
}

.mat-flat-button.mat-primary.mat-button-disabled, .mat-flat-button.mat-accent.mat-button-disabled, .mat-flat-button.mat-warn.mat-button-disabled, .mat-flat-button.mat-button-disabled.mat-button-disabled, .mat-raised-button.mat-primary.mat-button-disabled, .mat-raised-button.mat-accent.mat-button-disabled, .mat-raised-button.mat-warn.mat-button-disabled, .mat-raised-button.mat-button-disabled.mat-button-disabled, .mat-fab.mat-primary.mat-button-disabled, .mat-fab.mat-accent.mat-button-disabled, .mat-fab.mat-warn.mat-button-disabled, .mat-fab.mat-button-disabled.mat-button-disabled, .mat-mini-fab.mat-primary.mat-button-disabled, .mat-mini-fab.mat-accent.mat-button-disabled, .mat-mini-fab.mat-warn.mat-button-disabled, .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}

.mat-flat-button.mat-primary .mat-ripple-element, .mat-raised-button.mat-primary .mat-ripple-element, .mat-fab.mat-primary .mat-ripple-element, .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}

.mat-flat-button.mat-accent .mat-ripple-element, .mat-raised-button.mat-accent .mat-ripple-element, .mat-fab.mat-accent .mat-ripple-element, .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}

.mat-flat-button.mat-warn .mat-ripple-element, .mat-raised-button.mat-warn .mat-ripple-element, .mat-fab.mat-warn .mat-ripple-element, .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}

.mat-stroked-button:not([class*=mat-elevation-z]), .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-fab:not([class*=mat-elevation-z]), .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}

.mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}

.mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}

.mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e0e0e0;
}

[dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e0e0e0;
}

.mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e0e0e0;
}

.mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}

.mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}

.mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}

.mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}

.mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  border: solid 1px #e0e0e0;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 48px;
}

.mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}

.mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}

.mat-checkbox-checkmark {
  fill: #fafafa;
}

.mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}

.mat-checkbox-mixedmark {
  background-color: #fafafa;
}

.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #673ab7;
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #ffd740;
}

.mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #f44336;
}

.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}

.mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}

.mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.38);
}

.mat-checkbox .mat-ripple-element {
  background-color: black;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #673ab7;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #ffd740;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #f44336;
}

.mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}

.mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}

.mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}

.mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}

.mat-chip.mat-standard-chip::after {
  background: black;
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #673ab7;
  color: white;
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #f44336;
  color: white;
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #ffd740;
  color: rgba(0, 0, 0, 0.87);
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}

.mat-table {
  background: white;
}

.mat-table thead, .mat-table tbody, .mat-table tfoot,
mat-header-row, mat-row, mat-footer-row,
[mat-header-row], [mat-row], [mat-footer-row],
.mat-table-sticky {
  background: inherit;
}

mat-row, mat-header-row, mat-footer-row,
th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}

.mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}

.mat-cell, .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}

.mat-calendar-arrow {
  fill: rgba(0, 0, 0, 0.54);
}

.mat-datepicker-toggle,
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}

.mat-calendar-table-header,
.mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-body-cell-content,
.mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}

.mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}

.mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}

.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}

.mat-calendar-body-in-range::before {
  background: rgba(103, 58, 183, 0.2);
}

.mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}

.mat-calendar-body-comparison-bridge-start::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(103, 58, 183, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-calendar-body-comparison-bridge-end::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(103, 58, 183, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}

.mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}

.mat-calendar-body-selected {
  background-color: #673ab7;
  color: white;
}

.mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(103, 58, 183, 0.4);
}

.mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}

.cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(103, 58, 183, 0.3);
}

@media (hover: hover) {
  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(103, 58, 183, 0.3);
  }
}
.mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(255, 215, 64, 0.2);
}

.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}

.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(255, 215, 64, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(255, 215, 64, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}

.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}

.mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #ffd740;
  color: rgba(0, 0, 0, 0.87);
}

.mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(255, 215, 64, 0.4);
}

.mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.87);
}

.mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(255, 215, 64, 0.3);
}

@media (hover: hover) {
  .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(255, 215, 64, 0.3);
  }
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(244, 67, 54, 0.2);
}

.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}

.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}

.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}

.mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #f44336;
  color: white;
}

.mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(244, 67, 54, 0.4);
}

.mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}

.mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(244, 67, 54, 0.3);
}

@media (hover: hover) {
  .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(244, 67, 54, 0.3);
  }
}
.mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-datepicker-toggle-active {
  color: #673ab7;
}

.mat-datepicker-toggle-active.mat-accent {
  color: #ffd740;
}

.mat-datepicker-toggle-active.mat-warn {
  color: #f44336;
}

.mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}

.mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}

.mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}

@media (hover: none) {
  .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}

.mat-expansion-panel-header-description,
.mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}

.mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}

.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}

.mat-expansion-panel-header {
  height: 48px;
}

.mat-expansion-panel-header.mat-expanded {
  height: 64px;
}

.mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}

.mat-hint {
  color: rgba(0, 0, 0, 0.6);
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: #673ab7;
}

.mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #ffd740;
}

.mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #f44336;
}

.mat-focused .mat-form-field-required-marker {
  color: #ffd740;
}

.mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}

.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #673ab7;
}

.mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #ffd740;
}

.mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #f44336;
}

.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #673ab7;
}

.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #ffd740;
}

.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #f44336;
}

.mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #f44336;
}

.mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #f44336;
}

.mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #f44336;
}

.mat-error {
  color: #f44336;
}

.mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}

.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}

.mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}

.mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}

.mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}

.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}

.mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}

.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}

.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}

.mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}

.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #673ab7;
}

.mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #ffd740;
}

.mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #f44336;
}

.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #f44336;
}

.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}

.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}

.mat-icon.mat-primary {
  color: #673ab7;
}

.mat-icon.mat-accent {
  color: #ffd740;
}

.mat-icon.mat-warn {
  color: #f44336;
}

.mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}

.mat-input-element:disabled,
.mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}

.mat-input-element {
  caret-color: #673ab7;
}

.mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}

.mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}

.mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}

.mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}

.mat-form-field.mat-accent .mat-input-element {
  caret-color: #ffd740;
}

.mat-form-field.mat-warn .mat-input-element,
.mat-form-field-invalid .mat-input-element {
  caret-color: #f44336;
}

.mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #f44336;
}

.mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87);
}

.mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87);
}

.mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
}

.mat-list-base .mat-list-item-disabled {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.38);
}

.mat-list-option:hover, .mat-list-option:focus,
.mat-nav-list .mat-list-item:hover,
.mat-nav-list .mat-list-item:focus,
.mat-action-list .mat-list-item:hover,
.mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}

.mat-list-single-selected-option, .mat-list-single-selected-option:hover, .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}

.mat-menu-panel {
  background: white;
}

.mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}

.mat-menu-item[disabled],
.mat-menu-item[disabled] .mat-menu-submenu-icon,
.mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(0, 0, 0, 0.38);
}

.mat-menu-item .mat-icon-no-color,
.mat-menu-submenu-icon {
  color: rgba(0, 0, 0, 0.54);
}

.mat-menu-item:hover:not([disabled]),
.mat-menu-item.cdk-program-focused:not([disabled]),
.mat-menu-item.cdk-keyboard-focused:not([disabled]),
.mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}

.mat-paginator {
  background: white;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}

.mat-paginator-decrement,
.mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}

.mat-paginator-first,
.mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}

.mat-icon-button[disabled] .mat-paginator-decrement,
.mat-icon-button[disabled] .mat-paginator-increment,
.mat-icon-button[disabled] .mat-paginator-first,
.mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-paginator-container {
  min-height: 56px;
}

.mat-progress-bar-background {
  fill: #d5cae9;
}

.mat-progress-bar-buffer {
  background-color: #d5cae9;
}

.mat-progress-bar-fill::after {
  background-color: #673ab7;
}

.mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #fbf1cc;
}

.mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #fbf1cc;
}

.mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #ffd740;
}

.mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #f9ccc9;
}

.mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #f9ccc9;
}

.mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #f44336;
}

.mat-progress-spinner circle, .mat-spinner circle {
  stroke: #673ab7;
}

.mat-progress-spinner.mat-accent circle, .mat-spinner.mat-accent circle {
  stroke: #ffd740;
}

.mat-progress-spinner.mat-warn circle, .mat-spinner.mat-warn circle {
  stroke: #f44336;
}

.mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}

.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #673ab7;
}

.mat-radio-button.mat-primary .mat-radio-inner-circle,
.mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #673ab7;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #ffd740;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #ffd740;
}

.mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #f44336;
}

.mat-radio-button.mat-warn .mat-radio-inner-circle,
.mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #f44336;
}

.mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}

.mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}

.mat-radio-button .mat-ripple-element {
  background-color: black;
}

.mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}

.mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}

.mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}

.mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}

.mat-select-panel {
  background: white;
}

.mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}

.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #673ab7;
}

.mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #ffd740;
}

.mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #f44336;
}

.mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #f44336;
}

.mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}

.mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-drawer.mat-drawer-push {
  background-color: white;
}

.mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}

[dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}

[dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #ffd740;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(255, 215, 64, 0.54);
}

.mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #ffd740;
}

.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #673ab7;
}

.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(103, 58, 183, 0.54);
}

.mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #673ab7;
}

.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #f44336;
}

.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(244, 67, 54, 0.54);
}

.mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #f44336;
}

.mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}

.mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}

.mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}

.mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}

.mat-slider.mat-primary .mat-slider-track-fill,
.mat-slider.mat-primary .mat-slider-thumb,
.mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #673ab7;
}

.mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: white;
}

.mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(103, 58, 183, 0.2);
}

.mat-slider.mat-accent .mat-slider-track-fill,
.mat-slider.mat-accent .mat-slider-thumb,
.mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #ffd740;
}

.mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: rgba(0, 0, 0, 0.87);
}

.mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(255, 215, 64, 0.2);
}

.mat-slider.mat-warn .mat-slider-track-fill,
.mat-slider.mat-warn .mat-slider-thumb,
.mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #f44336;
}

.mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: white;
}

.mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(244, 67, 54, 0.2);
}

.mat-slider:hover .mat-slider-track-background,
.mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}

.mat-slider.mat-slider-disabled .mat-slider-track-background,
.mat-slider.mat-slider-disabled .mat-slider-track-fill,
.mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}

.mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}

.mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}

.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}

.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}

.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}

.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}

.mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}

.mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}

.mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}

.mat-step-header.cdk-keyboard-focused, .mat-step-header.cdk-program-focused, .mat-step-header:hover:not([aria-disabled]), .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(0, 0, 0, 0.04);
}

.mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}

@media (hover: none) {
  .mat-step-header:hover {
    background: none;
  }
}
.mat-step-header .mat-step-label,
.mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}

.mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}

.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: #673ab7;
  color: white;
}

.mat-step-header.mat-accent .mat-step-icon {
  color: rgba(0, 0, 0, 0.87);
}

.mat-step-header.mat-accent .mat-step-icon-selected,
.mat-step-header.mat-accent .mat-step-icon-state-done,
.mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #ffd740;
  color: rgba(0, 0, 0, 0.87);
}

.mat-step-header.mat-warn .mat-step-icon {
  color: white;
}

.mat-step-header.mat-warn .mat-step-icon-selected,
.mat-step-header.mat-warn .mat-step-icon-state-done,
.mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #f44336;
  color: white;
}

.mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #f44336;
}

.mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}

.mat-step-header .mat-step-label.mat-step-label-error {
  color: #f44336;
}

.mat-stepper-horizontal, .mat-stepper-vertical {
  background-color: white;
}

.mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}

.mat-horizontal-stepper-header::before,
.mat-horizontal-stepper-header::after,
.mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-horizontal-stepper-header {
  height: 72px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header,
.mat-vertical-stepper-header {
  padding: 24px 24px;
}

.mat-stepper-vertical-line::before {
  top: -16px;
  bottom: -16px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header::after, .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::before {
  top: 36px;
}

.mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
  top: 36px;
}

.mat-sort-header-arrow {
  color: #757575;
}

.mat-tab-nav-bar,
.mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.mat-tab-group-inverted-header .mat-tab-nav-bar,
.mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}

.mat-tab-label, .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}

.mat-tab-label.mat-tab-disabled, .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}

.mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}

.mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-tab-group[class*=mat-background-] > .mat-tab-header,
.mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}

.mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(209, 196, 233, 0.3);
}

.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #673ab7;
}

.mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}

.mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 229, 127, 0.3);
}

.mat-tab-group.mat-accent .mat-ink-bar, .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #ffd740;
}

.mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: rgba(0, 0, 0, 0.87);
}

.mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}

.mat-tab-group.mat-warn .mat-ink-bar, .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #f44336;
}

.mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}

.mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(209, 196, 233, 0.3);
}

.mat-tab-group.mat-background-primary > .mat-tab-header, .mat-tab-group.mat-background-primary > .mat-tab-link-container, .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #673ab7;
}

.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}

.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}

.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}

.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}

.mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}

.mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 229, 127, 0.3);
}

.mat-tab-group.mat-background-accent > .mat-tab-header, .mat-tab-group.mat-background-accent > .mat-tab-link-container, .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #ffd740;
}

.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}

.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.4);
}

.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: rgba(0, 0, 0, 0.87);
}

.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: black;
  opacity: 0.4;
}

.mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: black;
  opacity: 0.12;
}

.mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}

.mat-tab-group.mat-background-warn > .mat-tab-header, .mat-tab-group.mat-background-warn > .mat-tab-link-container, .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #f44336;
}

.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}

.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}

.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}

.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}

.mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}

.mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}

.mat-toolbar.mat-primary {
  background: #673ab7;
  color: white;
}

.mat-toolbar.mat-accent {
  background: #ffd740;
  color: rgba(0, 0, 0, 0.87);
}

.mat-toolbar.mat-warn {
  background: #f44336;
  color: white;
}

.mat-toolbar .mat-form-field-underline,
.mat-toolbar .mat-form-field-ripple,
.mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}

.mat-toolbar .mat-form-field-label,
.mat-toolbar .mat-focused .mat-form-field-label,
.mat-toolbar .mat-select-value,
.mat-toolbar .mat-select-arrow,
.mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}

.mat-toolbar .mat-input-element {
  caret-color: currentColor;
}

.mat-toolbar-multiple-rows {
  min-height: 64px;
}

.mat-toolbar-row, .mat-toolbar-single-row {
  height: 64px;
}

@media (max-width: 599px) {
  .mat-toolbar-multiple-rows {
    min-height: 56px;
  }
  .mat-toolbar-row, .mat-toolbar-single-row {
    height: 56px;
  }
}
.mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}

.mat-tree {
  background: white;
}

.mat-tree-node,
.mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}

.mat-tree-node {
  min-height: 48px;
}

.mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-simple-snackbar-action {
  color: #ffd740;
}

* {
  -webkit-transition: text-shadow 0.25s linear, border 0.25s linear, background-color 0.25s linear, width 0.25s linear, height 0.25s linear, max-height 0.25s linear;
  -moz-transition: text-shadow 0.25s linear, border 0.25s linear, background-color 0.25s linear, width 0.25s linear, height 0.25s linear, max-height 0.25s linear;
  -o-transition: text-shadow 0.25s linear, border 0.25s linear, background-color 0.25s linear, width 0.25s linear, height 0.25s linear, max-height 0.25s linear;
  transition: text-shadow 0.25s linear, border 0.25s linear, background-color 0.25s linear, width 0.25s linear, height 0.25s linear, max-height 0.25s linear;
}

.two-factor {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

body {
  box-sizing: border-box;
  overflow-x: hidden;
  font-family: "Montserrat", sans-serif;
  padding: 0px;
  margin: 0px;
  height: 100%;
  min-height: 100vh;
  display: flex;
  align-content: stretch;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
}
body #modal {
  display: none;
}
body #modal .md-dialog-container {
  z-index: 9999999;
}
body #main #pageContent {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  height: 100%;
  width: 100%;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: flex-start;
  align-items: flex-start;
}
body #main #pageContent header {
  display: flex;
  width: 100%;
  -webkit-flex-direction: row;
  flex-direction: row;
  flex: 1 1 90px;
  max-height: 90px;
}
body #main #pageContent header .header {
  width: 100%;
  padding-right: 6px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: stretch;
  align-content: stretch;
  flex-wrap: nowrap;
}
body #main #pageContent header .header .logo {
  flex: 1 auto;
}
body #main #pageContent header .header ul.desktop {
  display: flex;
  justify-content: flex-end;
}
body #main #pageContent header .header ul.desktop li:last-child {
  margin-right: 20px;
}
body #main #pageContent article {
  width: 100%;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  flex: 1 1 auto;
}
body #main #pageContent article blocworx-navbar {
  position: relative;
  display: flex;
  align-items: stretch;
  flex-direction: row;
  flex: 0 1 auto;
}
body #main #pageContent article blocworx-navbar .sidebar {
  display: flex;
  flex-direction: row;
  width: 252px;
  height: 100%;
  font-size: 19px !important;
  z-index: 2;
  box-sizing: border-box;
  padding: 22px;
  overflow: auto;
}
body #main #pageContent article #blocworxNavbar .sidebar .only-mobile a {
  display: none;
}
body #main #pageContent article #blocworxNavbar .menu-item-wrapper:not(.sidebar-page):last-child {
  border-bottom: 4px solid #ee3124;
  border-radius: 0px 0px 3px 3px;
}
body #main #pageContent article .parent {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: stretch;
  align-content: stretch;
  flex-wrap: nowrap;
}
body #main #pageContent article .parent .logout {
  margin-top: 20px;
}
body #main #pageContent article .parent .orButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
body #main #pageContent article .parent .orButton > span {
  padding-bottom: 3vh;
}
body #main #pageContent article .parent > * {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-content: stretch;
  align-items: stretch;
  justify-content: flex-start;
}
body #main #pageContent article .parent > * > div {
  width: 100%;
}
body #main #pageContent article .parent > *.home-page {
  padding-top: 20px;
}
body #main #pageContent article .parent > * .content {
  display: flex;
  align-items: stretch;
  box-sizing: border-box;
  padding-left: 25px;
  padding-right: 25px;
  position: relative;
  flex-direction: column;
}
body #main #pageContent article .parent > * .content .switchTo {
  width: 100%;
}
body #main #pageContent article .parent > * .content .login-page input {
  background: #fff !important;
}
body #main #pageContent article .parent > * .content .inner-standard-box-wrapper h4 {
  margin-top: 0px;
  font-weight: normal;
}
body #main #pageContent article .parent > * .content .inner-standard-box-wrapper input[type=checkbox] {
  margin-right: 10px;
  float: left;
  clear: both;
  margin-bottom: 5px;
}
body #main #pageContent article .parent > * .content .inner-standard-box-wrapper .resize-grid-popup label {
  font-size: 13px;
  width: 122px;
}
body #main #pageContent article .parent > * blocworx-authentication, body #main #pageContent article .parent > * .content {
  flex: 1 1 auto;
  min-width: 74%;
  max-width: 100%;
}
body #main #iframe #pageContent article .content {
  padding: 0;
  z-index: 9999;
}
body #main #iframe #pageContent article .content .standard-box {
  margin: 0px;
}
body #main div.skin-setting-dark {
  background: #232d2f;
  color: #f0f4f5;
}
body #main div.skin-setting-dark .job-single-in-list div {
  color: #004854;
}
body #main div.skin-setting-dark #pageContent article .parent .content {
  background: #232d2f;
}
body #main div.skin-setting-dark #pageContent article .parent .content .inner-standard-box-wrapper .field-rules-hover {
  background: #374244;
}
body #main div.skin-setting-dark #pageContent article .parent .content .inner-standard-box-wrapper .resize-grid-popup {
  background: #374244;
  border: 1px solid #ddd;
}
body #main div.skin-setting-dark #pageContent article .row-blocworx {
  width: 100%;
}
body #main div.skin-setting-dark #pageContent article .row-blocworx:hover .child-element-add-field {
  border: none;
}
body #main div.skin-setting-bright {
  background-color: #fff;
  color: #004854;
}
body #main div.skin-setting-bright #pageContent article .parent .content {
  background: #fff;
}
body #main div.skin-setting-bright #pageContent article .parent .content .inner-standard-box-wrapper .field-rules-hover {
  background: #c7cacc;
}
body #main div.skin-setting-bright #pageContent article .parent .content .inner-standard-box-wrapper .resize-grid-popup {
  background: #eaeaea;
  border: 1px solid rgba(69, 80, 82, 0.5215686275);
}
body #main div.skin-setting-bright #pageContent .row-blocworx {
  width: 100%;
}
body #main div.skin-setting-bright #pageContent .row-blocworx:hover .child-element-add-field {
  border: none;
}

html.dark {
  background: #232d2f;
  color: #f0f4f5;
}
html .bright {
  background-color: #fff;
  color: #004854;
}

.dark::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
.dark::-webkit-scrollbar-thumb {
  background: #455052;
}
.dark::-webkit-scrollbar-track {
  background: #232d2f;
}
.dark {
  scrollbar-face-color: #455052;
  scrollbar-track-color: #232d2f;
}

.bright::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
.bright::-webkit-scrollbar-thumb {
  background: #343a40;
}
.bright::-webkit-scrollbar-track {
  background: #eaeaea;
}
.bright {
  scrollbar-face-color: #343a40;
  scrollbar-track-color: #eaeaea;
}

@media only screen and (max-width: 976px) {
  body #modal, #body #modal {
    display: none;
  }
  body #main #pageContent header, #body #main #pageContent header {
    flex: 2;
    min-height: 90px;
  }
  body #main #pageContent header .header ul.desktop, #body #main #pageContent header .header ul.desktop {
    display: none;
  }
  body #main #pageContent article, #body #main #pageContent article {
    flex: 14;
    flex-direction: column;
  }
  body #main #pageContent article #blocworxNavbar .sidebar .only-mobile a, #body #main #pageContent article #blocworxNavbar .sidebar .only-mobile a {
    display: block;
  }
}
@media only screen and (max-width: 480px) {
  body #main #pageContent article #blocworxNavbar .sidebar .only-mobile a, #body #main #pageContent article #blocworxNavbar .sidebar .only-mobile a {
    display: block;
  }
}
@media (min-width: 977px) and (max-width: 2570px) {
  body #main #pageContent article > .parent, #body #main #pageContent article > .parent {
    width: calc(100% - 252px);
  }
  body #main #iframe #pageContent article > .parent, #body #main #iframe #pageContent article > .parent {
    width: 100%;
  }
}
#body #main .button-container,
#body #main .multi-button-container {
  float: left;
  /* width: 100%; */
  margin-bottom: 19px;
}
#body #main .button-container button,
#body #main .multi-button-container button {
  max-width: 200px;
}
#body #main .button-container .multi-button:nth-child(1),
#body #main .button-container .multi-button:nth-child(8),
#body #main .button-container .multi-button.green-colour,
#body #main .multi-button-container .multi-button:nth-child(1),
#body #main .multi-button-container .multi-button:nth-child(8),
#body #main .multi-button-container .multi-button.green-colour {
  background: #58cb78; /* Old browsers */
  background: -moz-linear-gradient(top, #58cb78 0%, #35b835 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #58cb78 0%, #35b835 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #58cb78 0%, #35b835 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#58cb78", endColorstr="#35b835", GradientType=0); /* IE6-9 */
}
#body #main .button-container .multi-button:nth-child(1):hover, #body #main .button-container .multi-button:nth-child(1).selected,
#body #main .button-container .multi-button:nth-child(8):hover,
#body #main .button-container .multi-button:nth-child(8).selected,
#body #main .button-container .multi-button.green-colour:hover,
#body #main .button-container .multi-button.green-colour.selected,
#body #main .multi-button-container .multi-button:nth-child(1):hover,
#body #main .multi-button-container .multi-button:nth-child(1).selected,
#body #main .multi-button-container .multi-button:nth-child(8):hover,
#body #main .multi-button-container .multi-button:nth-child(8).selected,
#body #main .multi-button-container .multi-button.green-colour:hover,
#body #main .multi-button-container .multi-button.green-colour.selected {
  background: #007E08;
  border-color: #000;
}
#body #main .button-container .multi-button:nth-child(2),
#body #main .button-container .multi-button:nth-child(9),
#body #main .button-container .multi-button.red-colour,
#body #main .multi-button-container .multi-button:nth-child(2),
#body #main .multi-button-container .multi-button:nth-child(9),
#body #main .multi-button-container .multi-button.red-colour {
  background: #f36f66; /* Old browsers */
  background: -moz-linear-gradient(top, #f36f66 0%, #ee3124 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #f36f66 0%, #ee3124 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #f36f66 0%, #ee3124 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f36f66", endColorstr="#ee3124", GradientType=0); /* IE6-9 */
}
#body #main .button-container .multi-button:nth-child(2):hover, #body #main .button-container .multi-button:nth-child(2).selected,
#body #main .button-container .multi-button:nth-child(9):hover,
#body #main .button-container .multi-button:nth-child(9).selected,
#body #main .button-container .multi-button.red-colour:hover,
#body #main .button-container .multi-button.red-colour.selected,
#body #main .multi-button-container .multi-button:nth-child(2):hover,
#body #main .multi-button-container .multi-button:nth-child(2).selected,
#body #main .multi-button-container .multi-button:nth-child(9):hover,
#body #main .multi-button-container .multi-button:nth-child(9).selected,
#body #main .multi-button-container .multi-button.red-colour:hover,
#body #main .multi-button-container .multi-button.red-colour.selected {
  background: #b60000;
  border-color: #000;
}
#body #main .button-container .multi-button:nth-child(3),
#body #main .button-container .multi-button:nth-child(10),
#body #main .button-container .multi-button.orange-colour,
#body #main .multi-button-container .multi-button:nth-child(3),
#body #main .multi-button-container .multi-button:nth-child(10),
#body #main .multi-button-container .multi-button.orange-colour {
  background: #fcbe5f; /* Old browsers */
  background: -moz-linear-gradient(top, #fcbe5f 0%, #faa21a 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #fcbe5f 0%, #faa21a 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #fcbe5f 0%, #faa21a 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fcbe5f", endColorstr="#faa21a", GradientType=0); /* IE6-9 */
}
#body #main .button-container .multi-button:nth-child(3):hover, #body #main .button-container .multi-button:nth-child(3).selected,
#body #main .button-container .multi-button:nth-child(10):hover,
#body #main .button-container .multi-button:nth-child(10).selected,
#body #main .button-container .multi-button.orange-colour:hover,
#body #main .button-container .multi-button.orange-colour.selected,
#body #main .multi-button-container .multi-button:nth-child(3):hover,
#body #main .multi-button-container .multi-button:nth-child(3).selected,
#body #main .multi-button-container .multi-button:nth-child(10):hover,
#body #main .multi-button-container .multi-button:nth-child(10).selected,
#body #main .multi-button-container .multi-button.orange-colour:hover,
#body #main .multi-button-container .multi-button.orange-colour.selected {
  background: #da8200;
  border-color: #000;
}
#body #main .button-container .multi-button:nth-child(4),
#body #main .button-container .multi-button:nth-child(11),
#body #main .button-container .multi-button.blue-colour,
#body #main .multi-button-container .multi-button:nth-child(4),
#body #main .multi-button-container .multi-button:nth-child(11),
#body #main .multi-button-container .multi-button.blue-colour {
  background: #6891c9; /* Old browsers */
  background: -moz-linear-gradient(top, #6891c9 0%, #2761b1 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #6891c9 0%, #2761b1 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #6891c9 0%, #2761b1 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#6891c9", endColorstr="#2761b1", GradientType=0); /* IE6-9 */
}
#body #main .button-container .multi-button:nth-child(4):hover, #body #main .button-container .multi-button:nth-child(4).selected,
#body #main .button-container .multi-button:nth-child(11):hover,
#body #main .button-container .multi-button:nth-child(11).selected,
#body #main .button-container .multi-button.blue-colour:hover,
#body #main .button-container .multi-button.blue-colour.selected,
#body #main .multi-button-container .multi-button:nth-child(4):hover,
#body #main .multi-button-container .multi-button:nth-child(4).selected,
#body #main .multi-button-container .multi-button:nth-child(11):hover,
#body #main .multi-button-container .multi-button:nth-child(11).selected,
#body #main .multi-button-container .multi-button.blue-colour:hover,
#body #main .multi-button-container .multi-button.blue-colour.selected {
  background: #114B9B;
  border-color: #000;
}
#body #main .button-container .multi-button:nth-child(5),
#body #main .button-container .multi-button:nth-child(12),
#body #main .button-container .multi-button.cyan-colour,
#body #main .multi-button-container .multi-button:nth-child(5),
#body #main .multi-button-container .multi-button:nth-child(12),
#body #main .multi-button-container .multi-button.cyan-colour {
  background: #7de0e3; /* Old browsers */
  background: -moz-linear-gradient(top, #7de0e3 0%, #45d2d7 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #7de0e3 0%, #45d2d7 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #7de0e3 0%, #45d2d7 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#7de0e3", endColorstr="#45d2d7", GradientType=0); /* IE6-9 */
}
#body #main .button-container .multi-button:nth-child(5):hover, #body #main .button-container .multi-button:nth-child(5).selected,
#body #main .button-container .multi-button:nth-child(12):hover,
#body #main .button-container .multi-button:nth-child(12).selected,
#body #main .button-container .multi-button.cyan-colour:hover,
#body #main .button-container .multi-button.cyan-colour.selected,
#body #main .multi-button-container .multi-button:nth-child(5):hover,
#body #main .multi-button-container .multi-button:nth-child(5).selected,
#body #main .multi-button-container .multi-button:nth-child(12):hover,
#body #main .multi-button-container .multi-button:nth-child(12).selected,
#body #main .multi-button-container .multi-button.cyan-colour:hover,
#body #main .multi-button-container .multi-button.cyan-colour.selected {
  background: #079499;
  border-color: #000;
}
#body #main .button-container .multi-button:nth-child(6),
#body #main .button-container .multi-button:nth-child(13),
#body #main .button-container .multi-button.purple-colour,
#body #main .multi-button-container .multi-button:nth-child(6),
#body #main .multi-button-container .multi-button:nth-child(13),
#body #main .multi-button-container .multi-button.purple-colour {
  background: #b971bd; /* Old browsers */
  background: -moz-linear-gradient(top, #b971bd 0%, #9b34a1 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #b971bd 0%, #9b34a1 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #b971bd 0%, #9b34a1 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#b971bd", endColorstr="#9b34a1", GradientType=0); /* IE6-9 */
}
#body #main .button-container .multi-button:nth-child(6):hover, #body #main .button-container .multi-button:nth-child(6).selected,
#body #main .button-container .multi-button:nth-child(13):hover,
#body #main .button-container .multi-button:nth-child(13).selected,
#body #main .button-container .multi-button.purple-colour:hover,
#body #main .button-container .multi-button.purple-colour.selected,
#body #main .multi-button-container .multi-button:nth-child(6):hover,
#body #main .multi-button-container .multi-button:nth-child(6).selected,
#body #main .multi-button-container .multi-button:nth-child(13):hover,
#body #main .multi-button-container .multi-button:nth-child(13).selected,
#body #main .multi-button-container .multi-button.purple-colour:hover,
#body #main .multi-button-container .multi-button.purple-colour.selected {
  background: #7F1885;
  border-color: #000;
}
#body #main .button-container .multi-button:nth-child(7),
#body #main .button-container .multi-button:nth-child(14),
#body #main .button-container .multi-button.dark-green-colour,
#body #main .multi-button-container .multi-button:nth-child(7),
#body #main .multi-button-container .multi-button:nth-child(14),
#body #main .multi-button-container .multi-button.dark-green-colour {
  background: #4d7f88; /* Old browsers */
  background: -moz-linear-gradient(top, #4d7f88 0%, #004854 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #4d7f88 0%, #004854 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #4d7f88 0%, #004854 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#4d7f88", endColorstr="#004854", GradientType=0); /* IE6-9 */
}
#body #main .button-container .multi-button:nth-child(7):hover, #body #main .button-container .multi-button:nth-child(7).selected,
#body #main .button-container .multi-button:nth-child(14):hover,
#body #main .button-container .multi-button:nth-child(14).selected,
#body #main .button-container .multi-button.dark-green-colour:hover,
#body #main .button-container .multi-button.dark-green-colour.selected,
#body #main .multi-button-container .multi-button:nth-child(7):hover,
#body #main .multi-button-container .multi-button:nth-child(7).selected,
#body #main .multi-button-container .multi-button:nth-child(14):hover,
#body #main .multi-button-container .multi-button:nth-child(14).selected,
#body #main .multi-button-container .multi-button.dark-green-colour:hover,
#body #main .multi-button-container .multi-button.dark-green-colour.selected {
  background: #004854;
  border-color: #000;
}
#body #main .field-wrap {
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 10px 0px;
  width: 100%;
  align-content: center;
}
#body #main .blocworx-multi-button {
  min-width: 97px;
  clear: none;
  margin: 5px;
  color: #fff;
  padding: 6px 20px;
  -webkit-border-radius: 14px;
  -moz-border-radius: 14px;
  border-radius: 14px;
  font-size: 15px;
  font-weight: 600;
  box-sizing: border-box;
  transition: none;
  -moz-transition: none;
  -webkit-transition: none;
  -o-transition: none;
}
#body #main .blocworx-multi-button.full-size {
  width: 100%;
  margin: 0px;
}
#body #main .blocworx-green-colour {
  background: #58cb78;
  background: -moz-linear-gradient(top, #58cb78 0%, #35b835 100%);
  background: -webkit-linear-gradient(top, #58cb78 0%, #35b835 100%);
  background: linear-gradient(to bottom, #58cb78 0%, #35b835 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#58cb78", endColorstr="#35b835", GradientType=0);
}
#body #main .blocworx-green-colour.selected {
  background: #007E08;
  border-color: #000;
}
#body #main .blocworx-green-colour:hover {
  background: #007E08;
}
#body #main .blocworx-dark-green-colour {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#4d7f88+0,004854+100 */
  background: #4d7f88; /* Old browsers */
  background: -moz-linear-gradient(top, #4d7f88 0%, #004854 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #4d7f88 0%, #004854 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #4d7f88 0%, #004854 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#4d7f88", endColorstr="#004854", GradientType=0); /* IE6-9 */
}
#body #main .blocworx-dark-green-colour.selected {
  background: #004854;
  border-color: #00080e;
}
#body #main .blocworx-dark-green-colour:hover {
  background: #004854;
}
#body #main .blocworx-red-colour {
  background: #f36f66; /* Old browsers */
  background: -moz-linear-gradient(top, #f36f66 0%, #ee3124 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #f36f66 0%, #ee3124 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #f36f66 0%, #ee3124 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f36f66", endColorstr="#ee3124", GradientType=0); /* IE6-9 */
}
#body #main .blocworx-red-colour.selected {
  background: #b60000;
  border-color: #3E0000;
}
#body #main .blocworx-red-colour:hover {
  background: #ee3124;
}
#body #main .blocworx-orange-colour {
  background: #fcbe5f; /* Old browsers */
  background: -moz-linear-gradient(top, #fcbe5f 0%, #faa21a 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #fcbe5f 0%, #faa21a 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #fcbe5f 0%, #faa21a 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fcbe5f", endColorstr="#faa21a", GradientType=0); /* IE6-9 */
}
#body #main .blocworx-orange-colour.selected {
  background: #da8200;
  border-color: #000;
}
#body #main .blocworx-orange-colour:hover {
  background: #da8200;
}
#body #main .blocworx-blue-colour {
  background: #6891c9; /* Old browsers */
  background: -moz-linear-gradient(top, #6891c9 0%, #2761b1 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #6891c9 0%, #2761b1 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #6891c9 0%, #2761b1 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#6891c9", endColorstr="#2761b1", GradientType=0); /* IE6-9 */
}
#body #main .blocworx-blue-colour.selected {
  background: #114B9B;
  border-color: #000;
}
#body #main .blocworx-blue-colour:hover {
  background: #114B9B;
}
#body #main .blocworx-cyan-colour {
  background: #7de0e3; /* Old browsers */
  background: -moz-linear-gradient(top, #7de0e3 0%, #45d2d7 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #7de0e3 0%, #45d2d7 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #7de0e3 0%, #45d2d7 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#7de0e3", endColorstr="#45d2d7", GradientType=0); /* IE6-9 */
}
#body #main .blocworx-cyan-colour.selected {
  background: #079499;
  border-color: #000;
}
#body #main .blocworx-cyan-colour:hover {
  background: #079499;
}
#body #main .blocworx-purple-colour {
  background: #b971bd; /* Old browsers */
  background: -moz-linear-gradient(top, #b971bd 0%, #9b34a1 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #b971bd 0%, #9b34a1 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #b971bd 0%, #9b34a1 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#b971bd", endColorstr="#9b34a1", GradientType=0); /* IE6-9 */
}
#body #main .blocworx-purple-colour.selected {
  background: #7F1885;
  border-color: #000;
}
#body #main .blocworx-purple-colour:hover {
  background: #7F1885;
}
#body #main .field-inner-container .field-wrap {
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0px;
  width: 100%;
  align-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
#body #main .field-inner-container .blocworx-green-colour,
#body #main .field-inner-container .blocworx-dark-green-colour,
#body #main .field-inner-container .blocworx-red-colour,
#body #main .field-inner-container .blocworx-orange-colour,
#body #main .field-inner-container .blocworx-blue-colour,
#body #main .field-inner-container .blocworx-cyan-colour,
#body #main .field-inner-container .blocworx-purple-colour {
  margin-right: 5px;
  margin-bottom: 9px;
  padding: 6px 22px;
  border-radius: 14px;
  font-size: 15px;
  font-weight: 600;
  box-sizing: border-box;
  transition: none !important;
  -moz-transition: none !important;
  -webkit-transition: none !important;
  -o-transition: none !important;
}
body #main #pageContent article .strong {
  font-weight: 600;
}
body #main #pageContent article .flex-container {
  display: inline-flex;
  width: 100%;
  align-content: center;
  align-items: stretch;
}
body #main #pageContent article .flex-container.spaced {
  margin: 10px 0px 10px 0px;
}
body #main #pageContent article .flex-container h2 {
  font-size: 19px;
  width: 110%;
  margin: 0px 0px 10px -6px;
  background: rgba(0, 0, 0, 0.3);
  padding: 10px 5px;
  border-radius: 11px;
}
body #main #pageContent article .flex-container h3 {
  font-size: 18px;
  width: 100%;
  font-weight: 600;
  margin: 10px 0px;
}
body #main #pageContent article .flex-container .flex-child-featured {
  width: 100%;
  flex: 1 1 100%;
}
body #main #pageContent article .flex-container .flex-child-x {
  flex: 1 1 20%;
  width: 20%;
  cursor: pointer;
  display: flex;
  justify-content: center;
}
body #main #pageContent article .flex-container .flex-child-button {
  flex: 1 1 27%;
  width: 27%;
  cursor: pointer;
  max-width: 100px;
}
body #main #pageContent article .flex-container .flex-half-title, body #main #pageContent article .flex-container .flex-half-child {
  width: 47%;
  flex: 1 1 47%;
}
body #main #pageContent article .flex-container .flex-half-title.right-margin, body #main #pageContent article .flex-container .flex-half-child.right-margin {
  margin: 0px 2% 0px 0px;
}
body #main #pageContent article .flex-container .flex-half-title.left-margin, body #main #pageContent article .flex-container .flex-half-child.left-margin {
  margin: 0px 0px 0px 2%;
}
body #main #pageContent article .flex-container .left-padding {
  padding: 0px 0px 0px 2.1%;
}

#body #modal {
  height: 0px;
}
#body #modal md-dialog {
  border-radius: 11px;
}
#body #modal md-dialog button md-icon {
  color: white;
}
#body #modal .box-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  box-sizing: border-box;
  border-radius: 11px;
}
#body #modal .box-container .box-title {
  color: #fff;
  width: 100%;
  float: left;
  box-sizing: border-box;
  padding: 10px 20px;
  font-size: 20.5px;
  font-weight: 600;
  border-radius: 11px 11px 0px 0px;
}
#body #modal .wrapper {
  width: 100%;
  box-sizing: border-box;
  padding: 14px;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  align-content: stretch;
  justify-content: flex-start;
}
#body #modal .wrapper .info-wrapper, #body #modal .wrapper .fields-wrapper, #body #modal .wrapper .button-wrapper {
  min-height: 70px;
  width: 100%;
}
#body #modal .wrapper .warn-wrapper {
  width: 100%;
  background: #744848;
  padding: 10px;
  border-radius: 11px;
  margin: 0px 0px 10px 0px;
  color: white;
}
#body #modal .options-wrapper, #body #modal .info-wrapper, #body #modal .fields-wrapper {
  width: 100%;
  display: inline-block;
}
#body #modal .button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  min-height: 40px;
  margin-top: 10px;
}
#body #modal .button-wrapper button {
  display: inline-flex;
  font-size: 16px;
}
#body #modal .button-wrapper .delete:hover {
  background-color: pink;
}
#body #modal .button-wrapper .mobile {
  display: none;
}
#body #modal .column {
  flex: 1;
  margin: 10px;
  border-radius: 11px;
}
#body #modal .column.main {
  margin: 0px;
}
#body #modal .column.half {
  flex: 1 1 50%;
}
#body #modal .column.third {
  flex: 1 1 33%;
}
#body #modal .column.left-side {
  margin-left: 0px;
}
#body #modal .column.right-side {
  margin-right: 0px;
}
#body #modal .flexbox {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
#body #modal .flexbox .flex-child {
  border-radius: 11px;
}
#body #modal .flexbox .flex-child.full {
  flex: 1 1 100%;
  max-width: 100%;
}
#body #modal .flexbox .flex-child.half {
  flex: 1 1 50%;
  max-width: 49%;
}
#body #modal .flexbox .flex-child.third {
  flex: 1 1 33%;
  max-width: 32%;
}
#body #modal .flexbox .flex-child.left-side {
  margin-left: 0px;
}
#body #modal .flexbox .flex-child.right-side {
  margin-right: 0px;
}
#body #modal .flexbox .flex-child hr.dashed {
  border-top: 3px dashed rgba(187, 187, 187, 0.1019607843);
}
#body #modal .flexbox .flex-child h2 {
  color: #fff;
  width: 100%;
  float: left;
  box-sizing: border-box;
  padding: 10px 20px;
  font-size: 20.5px;
  font-weight: 600;
  border-radius: 11px 11px 0px 0px;
}
#body #modal .flexbox .flex-child h3 {
  box-sizing: border-box;
  padding: 0px 0px 0px 10px;
  font-size: 18.5px;
  font-weight: 600;
  border-radius: 0px;
  color: #fff;
  background: rgba(255, 255, 255, 0.1294117647);
  position: relative;
  left: -14px;
  width: 106%;
}
#body #modal .flexbox .flex-child .blocworx-content {
  width: 100%;
  box-sizing: border-box;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  align-content: stretch;
  justify-content: flex-start;
  padding: 14px;
}
#body #modal .flexbox .flex-child .blocworx-content form {
  width: 100%;
}
#body #modal .flexbox .flex-child .button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  min-height: 40px;
  margin-top: 10px;
  width: 100%;
}
#body #modal .flexbox .flex-child .flexbox .flex-child .box-title {
  border-radius: 0px;
  background-color: inherit;
}
#body #modal .flexboxHeader {
  margin: 2px 0px 10px 0px;
}
#body #modal.dark .box-container .column, #body #modal.dark .box-container .flex-child, #body #modal.dark .flexbox .column, #body #modal.dark .flexbox .flex-child {
  background: #455052;
}
#body #modal.dark .box-container .box-title, #body #modal.dark .flexbox .box-title {
  color: white;
  background-color: rgba(52, 58, 64, 0.8117647059);
}
#body #modal.dark .box-container .bloc-setting-panel .box-title, #body #modal.dark .flexbox .bloc-setting-panel .box-title {
  color: #f0f4f5;
  background: inherit;
}
#body #modal.dark .box-container .info-wrapper, #body #modal.dark .box-container p, #body #modal.dark .box-container label, #body #modal.dark .flexbox .info-wrapper, #body #modal.dark .flexbox p, #body #modal.dark .flexbox label {
  color: white;
}
#body #modal.light .box-container .column, #body #modal.light .box-container .flex-child, #body #modal.light .flexbox .column, #body #modal.light .flexbox .flex-child {
  background: #eaeaea;
}
#body #modal.light .box-container .box-title, #body #modal.light .flexbox .box-title {
  color: white;
  background-color: rgba(69, 80, 82, 0.7098039216);
}
#body #modal.light .box-container .bloc-setting-panel .box-title, #body #modal.light .flexbox .bloc-setting-panel .box-title {
  color: #004854;
  background: inherit;
}
#body #modal.light .box-container button p, #body #modal.light .flexbox button p {
  color: white;
}
#body #modal.light .box-container .info-wrapper, #body #modal.light .box-container p, #body #modal.light .box-container label, #body #modal.light .flexbox .info-wrapper, #body #modal.light .flexbox p, #body #modal.light .flexbox label {
  color: #004854;
}
#body #main #pageContent article md-dialog {
  border-radius: 11px;
}
#body #main #pageContent article md-dialog button md-icon {
  color: white;
}
#body #main #pageContent article .box-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  box-sizing: border-box;
  border-radius: 11px;
}
#body #main #pageContent article .box-container .box-title {
  color: #fff;
  width: 100%;
  float: left;
  box-sizing: border-box;
  padding: 10px 20px;
  font-size: 20.5px;
  font-weight: 600;
  border-radius: 11px 11px 0px 0px;
}
#body #main #pageContent article .wrapper {
  width: 100%;
  box-sizing: border-box;
  padding: 14px;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  align-content: stretch;
  justify-content: flex-start;
}
#body #main #pageContent article .wrapper .info-wrapper, #body #main #pageContent article .wrapper .fields-wrapper, #body #main #pageContent article .wrapper .button-wrapper {
  min-height: 70px;
  width: 100%;
}
#body #main #pageContent article .wrapper .warn-wrapper {
  width: 100%;
  background: #744848;
  padding: 10px;
  border-radius: 11px;
  margin: 0px 0px 10px 0px;
  color: white;
}
#body #main #pageContent article .options-wrapper, #body #main #pageContent article .info-wrapper, #body #main #pageContent article .fields-wrapper {
  width: 100%;
  display: inline-block;
}
#body #main #pageContent article .button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  min-height: 40px;
  margin-top: 10px;
}
#body #main #pageContent article .button-wrapper button {
  display: inline-flex;
  font-size: 16px;
}
#body #main #pageContent article .button-wrapper .delete:hover {
  background-color: pink;
}
#body #main #pageContent article .button-wrapper .mobile {
  display: none;
}
#body #main #pageContent article .column {
  flex: 1;
  margin: 10px;
  border-radius: 11px;
}
#body #main #pageContent article .column.main {
  margin: 0px;
}
#body #main #pageContent article .column.half {
  flex: 1 1 50%;
}
#body #main #pageContent article .column.third {
  flex: 1 1 33%;
}
#body #main #pageContent article .column.left-side {
  margin-left: 0px;
}
#body #main #pageContent article .column.right-side {
  margin-right: 0px;
}
#body #main #pageContent article .flexbox {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
#body #main #pageContent article .flexbox .flex-child {
  border-radius: 11px;
}
#body #main #pageContent article .flexbox .flex-child.full {
  flex: 1 1 100%;
  max-width: 100%;
}
#body #main #pageContent article .flexbox .flex-child.half {
  flex: 1 1 50%;
  max-width: 49%;
}
#body #main #pageContent article .flexbox .flex-child.third {
  flex: 1 1 33%;
  max-width: 32%;
}
#body #main #pageContent article .flexbox .flex-child.left-side {
  margin-left: 0px;
}
#body #main #pageContent article .flexbox .flex-child.right-side {
  margin-right: 0px;
}
#body #main #pageContent article .flexbox .flex-child hr.dashed {
  border-top: 3px dashed rgba(187, 187, 187, 0.1019607843);
}
#body #main #pageContent article .flexbox .flex-child h2 {
  color: #fff;
  width: 100%;
  float: left;
  box-sizing: border-box;
  padding: 10px 20px;
  font-size: 20.5px;
  font-weight: 600;
  border-radius: 11px 11px 0px 0px;
}
#body #main #pageContent article .flexbox .flex-child h3 {
  box-sizing: border-box;
  padding: 0px 0px 0px 10px;
  font-size: 18.5px;
  font-weight: 600;
  border-radius: 0px;
  color: #fff;
  background: rgba(255, 255, 255, 0.1294117647);
  position: relative;
  left: -14px;
  width: 106%;
}
#body #main #pageContent article .flexbox .flex-child .blocworx-content {
  width: 100%;
  box-sizing: border-box;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  align-content: stretch;
  justify-content: flex-start;
  padding: 14px;
}
#body #main #pageContent article .flexbox .flex-child .blocworx-content form {
  width: 100%;
}
#body #main #pageContent article .flexbox .flex-child .button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  min-height: 40px;
  margin-top: 10px;
  width: 100%;
}
#body #main #pageContent article .flexbox .flex-child .flexbox .flex-child .box-title {
  border-radius: 0px;
  background-color: inherit;
}
#body #main #pageContent article .flexboxHeader {
  margin: 2px 0px 10px 0px;
}
#body #main div.skin-setting-dark #pageContent article .box-container .column, #body #main div.skin-setting-dark #pageContent article .box-container .flex-child, #body #main div.skin-setting-dark #pageContent article .flexbox .column, #body #main div.skin-setting-dark #pageContent article .flexbox .flex-child {
  background: #455052;
}
#body #main div.skin-setting-dark #pageContent article .box-container .box-title, #body #main div.skin-setting-dark #pageContent article .flexbox .box-title {
  color: white;
  background-color: rgba(52, 58, 64, 0.8117647059);
}
#body #main div.skin-setting-dark #pageContent article .box-container .bloc-setting-panel .box-title, #body #main div.skin-setting-dark #pageContent article .flexbox .bloc-setting-panel .box-title {
  color: #f0f4f5;
  background: inherit;
}
#body #main div.skin-setting-dark #pageContent article .box-container .info-wrapper, #body #main div.skin-setting-dark #pageContent article .box-container p, #body #main div.skin-setting-dark #pageContent article .box-container label, #body #main div.skin-setting-dark #pageContent article .flexbox .info-wrapper, #body #main div.skin-setting-dark #pageContent article .flexbox p, #body #main div.skin-setting-dark #pageContent article .flexbox label {
  color: white;
}
#body #main div.skin-setting-dark #pageContent article .box-container::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
#body #main div.skin-setting-dark #pageContent article .box-container::-webkit-scrollbar-thumb {
  background: #455052;
}
#body #main div.skin-setting-dark #pageContent article .box-container::-webkit-scrollbar-track {
  background: #232d2f;
}
#body #main div.skin-setting-dark #pageContent article .box-container {
  scrollbar-face-color: #455052;
  scrollbar-track-color: #232d2f;
}
#body #main div.skin-setting-bright #pageContent article .box-container .column, #body #main div.skin-setting-bright #pageContent article .box-container .flex-child, #body #main div.skin-setting-bright #pageContent article .flexbox .column, #body #main div.skin-setting-bright #pageContent article .flexbox .flex-child {
  background: #eaeaea;
}
#body #main div.skin-setting-bright #pageContent article .box-container .box-title, #body #main div.skin-setting-bright #pageContent article .flexbox .box-title {
  color: white;
  background-color: rgba(69, 80, 82, 0.7098039216);
}
#body #main div.skin-setting-bright #pageContent article .box-container .bloc-setting-panel .box-title, #body #main div.skin-setting-bright #pageContent article .flexbox .bloc-setting-panel .box-title {
  color: #004854;
  background: inherit;
}
#body #main div.skin-setting-bright #pageContent article .box-container button p, #body #main div.skin-setting-bright #pageContent article .flexbox button p {
  color: white;
}
#body #main div.skin-setting-bright #pageContent article .box-container .info-wrapper, #body #main div.skin-setting-bright #pageContent article .box-container p, #body #main div.skin-setting-bright #pageContent article .box-container label, #body #main div.skin-setting-bright #pageContent article .flexbox .info-wrapper, #body #main div.skin-setting-bright #pageContent article .flexbox p, #body #main div.skin-setting-bright #pageContent article .flexbox label {
  color: #004854;
}
#body #main div.skin-setting-bright #pageContent article .box-container::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
#body #main div.skin-setting-bright #pageContent article .box-container::-webkit-scrollbar-thumb {
  background: #343a40;
}
#body #main div.skin-setting-bright #pageContent article .box-container::-webkit-scrollbar-track {
  background: #eaeaea;
}
#body #main div.skin-setting-bright #pageContent article .box-container {
  scrollbar-face-color: #343a40;
  scrollbar-track-color: #eaeaea;
}

#body.dark .blocworx-popup md-dialog {
  border-radius: 11px;
}
#body.dark .blocworx-popup md-dialog button md-icon {
  color: white;
}
#body.dark .blocworx-popup .box-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  box-sizing: border-box;
  border-radius: 11px;
}
#body.dark .blocworx-popup .box-container .box-title {
  color: #fff;
  width: 100%;
  float: left;
  box-sizing: border-box;
  padding: 10px 20px;
  font-size: 20.5px;
  font-weight: 600;
  border-radius: 11px 11px 0px 0px;
}
#body.dark .blocworx-popup .wrapper {
  width: 100%;
  box-sizing: border-box;
  padding: 14px;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  align-content: stretch;
  justify-content: flex-start;
}
#body.dark .blocworx-popup .wrapper .info-wrapper, #body.dark .blocworx-popup .wrapper .fields-wrapper, #body.dark .blocworx-popup .wrapper .button-wrapper {
  min-height: 70px;
  width: 100%;
}
#body.dark .blocworx-popup .wrapper .warn-wrapper {
  width: 100%;
  background: #744848;
  padding: 10px;
  border-radius: 11px;
  margin: 0px 0px 10px 0px;
  color: white;
}
#body.dark .blocworx-popup .options-wrapper, #body.dark .blocworx-popup .info-wrapper, #body.dark .blocworx-popup .fields-wrapper {
  width: 100%;
  display: inline-block;
}
#body.dark .blocworx-popup .button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  min-height: 40px;
  margin-top: 10px;
}
#body.dark .blocworx-popup .button-wrapper button {
  display: inline-flex;
  font-size: 16px;
}
#body.dark .blocworx-popup .button-wrapper .delete:hover {
  background-color: pink;
}
#body.dark .blocworx-popup .button-wrapper .mobile {
  display: none;
}
#body.dark .blocworx-popup .column {
  flex: 1;
  margin: 10px;
  border-radius: 11px;
}
#body.dark .blocworx-popup .column.main {
  margin: 0px;
}
#body.dark .blocworx-popup .column.half {
  flex: 1 1 50%;
}
#body.dark .blocworx-popup .column.third {
  flex: 1 1 33%;
}
#body.dark .blocworx-popup .column.left-side {
  margin-left: 0px;
}
#body.dark .blocworx-popup .column.right-side {
  margin-right: 0px;
}
#body.dark .blocworx-popup .flexbox {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
#body.dark .blocworx-popup .flexbox .flex-child {
  border-radius: 11px;
}
#body.dark .blocworx-popup .flexbox .flex-child.full {
  flex: 1 1 100%;
  max-width: 100%;
}
#body.dark .blocworx-popup .flexbox .flex-child.half {
  flex: 1 1 50%;
  max-width: 49%;
}
#body.dark .blocworx-popup .flexbox .flex-child.third {
  flex: 1 1 33%;
  max-width: 32%;
}
#body.dark .blocworx-popup .flexbox .flex-child.left-side {
  margin-left: 0px;
}
#body.dark .blocworx-popup .flexbox .flex-child.right-side {
  margin-right: 0px;
}
#body.dark .blocworx-popup .flexbox .flex-child hr.dashed {
  border-top: 3px dashed rgba(187, 187, 187, 0.1019607843);
}
#body.dark .blocworx-popup .flexbox .flex-child h2 {
  color: #fff;
  width: 100%;
  float: left;
  box-sizing: border-box;
  padding: 10px 20px;
  font-size: 20.5px;
  font-weight: 600;
  border-radius: 11px 11px 0px 0px;
}
#body.dark .blocworx-popup .flexbox .flex-child h3 {
  box-sizing: border-box;
  padding: 0px 0px 0px 10px;
  font-size: 18.5px;
  font-weight: 600;
  border-radius: 0px;
  color: #fff;
  background: rgba(255, 255, 255, 0.1294117647);
  position: relative;
  left: -14px;
  width: 106%;
}
#body.dark .blocworx-popup .flexbox .flex-child .blocworx-content {
  width: 100%;
  box-sizing: border-box;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  align-content: stretch;
  justify-content: flex-start;
  padding: 14px;
}
#body.dark .blocworx-popup .flexbox .flex-child .blocworx-content form {
  width: 100%;
}
#body.dark .blocworx-popup .flexbox .flex-child .button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  min-height: 40px;
  margin-top: 10px;
  width: 100%;
}
#body.dark .blocworx-popup .flexbox .flex-child .flexbox .flex-child .box-title {
  border-radius: 0px;
  background-color: inherit;
}
#body.dark .blocworx-popup .flexboxHeader {
  margin: 2px 0px 10px 0px;
}
#body.dark .blocworx-popup .box-container .column, #body.dark .blocworx-popup .box-container .flex-child, #body.dark .blocworx-popup .flexbox .column, #body.dark .blocworx-popup .flexbox .flex-child {
  background: #455052;
}
#body.dark .blocworx-popup .box-container .box-title, #body.dark .blocworx-popup .flexbox .box-title {
  color: white;
  background-color: rgba(52, 58, 64, 0.8117647059);
}
#body.dark .blocworx-popup .box-container .bloc-setting-panel .box-title, #body.dark .blocworx-popup .flexbox .bloc-setting-panel .box-title {
  color: #f0f4f5;
  background: inherit;
}
#body.dark .blocworx-popup .box-container .info-wrapper, #body.dark .blocworx-popup .box-container p, #body.dark .blocworx-popup .box-container label, #body.dark .blocworx-popup .flexbox .info-wrapper, #body.dark .blocworx-popup .flexbox p, #body.dark .blocworx-popup .flexbox label {
  color: white;
}
#body.bright .blocworx-popup md-dialog {
  border-radius: 11px;
}
#body.bright .blocworx-popup md-dialog button md-icon {
  color: white;
}
#body.bright .blocworx-popup .box-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  box-sizing: border-box;
  border-radius: 11px;
}
#body.bright .blocworx-popup .box-container .box-title {
  color: #fff;
  width: 100%;
  float: left;
  box-sizing: border-box;
  padding: 10px 20px;
  font-size: 20.5px;
  font-weight: 600;
  border-radius: 11px 11px 0px 0px;
}
#body.bright .blocworx-popup .wrapper {
  width: 100%;
  box-sizing: border-box;
  padding: 14px;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  align-content: stretch;
  justify-content: flex-start;
}
#body.bright .blocworx-popup .wrapper .info-wrapper, #body.bright .blocworx-popup .wrapper .fields-wrapper, #body.bright .blocworx-popup .wrapper .button-wrapper {
  min-height: 70px;
  width: 100%;
}
#body.bright .blocworx-popup .wrapper .warn-wrapper {
  width: 100%;
  background: #744848;
  padding: 10px;
  border-radius: 11px;
  margin: 0px 0px 10px 0px;
  color: white;
}
#body.bright .blocworx-popup .options-wrapper, #body.bright .blocworx-popup .info-wrapper, #body.bright .blocworx-popup .fields-wrapper {
  width: 100%;
  display: inline-block;
}
#body.bright .blocworx-popup .button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  min-height: 40px;
  margin-top: 10px;
}
#body.bright .blocworx-popup .button-wrapper button {
  display: inline-flex;
  font-size: 16px;
}
#body.bright .blocworx-popup .button-wrapper .delete:hover {
  background-color: pink;
}
#body.bright .blocworx-popup .button-wrapper .mobile {
  display: none;
}
#body.bright .blocworx-popup .column {
  flex: 1;
  margin: 10px;
  border-radius: 11px;
}
#body.bright .blocworx-popup .column.main {
  margin: 0px;
}
#body.bright .blocworx-popup .column.half {
  flex: 1 1 50%;
}
#body.bright .blocworx-popup .column.third {
  flex: 1 1 33%;
}
#body.bright .blocworx-popup .column.left-side {
  margin-left: 0px;
}
#body.bright .blocworx-popup .column.right-side {
  margin-right: 0px;
}
#body.bright .blocworx-popup .flexbox {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
#body.bright .blocworx-popup .flexbox .flex-child {
  border-radius: 11px;
}
#body.bright .blocworx-popup .flexbox .flex-child.full {
  flex: 1 1 100%;
  max-width: 100%;
}
#body.bright .blocworx-popup .flexbox .flex-child.half {
  flex: 1 1 50%;
  max-width: 49%;
}
#body.bright .blocworx-popup .flexbox .flex-child.third {
  flex: 1 1 33%;
  max-width: 32%;
}
#body.bright .blocworx-popup .flexbox .flex-child.left-side {
  margin-left: 0px;
}
#body.bright .blocworx-popup .flexbox .flex-child.right-side {
  margin-right: 0px;
}
#body.bright .blocworx-popup .flexbox .flex-child hr.dashed {
  border-top: 3px dashed rgba(187, 187, 187, 0.1019607843);
}
#body.bright .blocworx-popup .flexbox .flex-child h2 {
  color: #fff;
  width: 100%;
  float: left;
  box-sizing: border-box;
  padding: 10px 20px;
  font-size: 20.5px;
  font-weight: 600;
  border-radius: 11px 11px 0px 0px;
}
#body.bright .blocworx-popup .flexbox .flex-child h3 {
  box-sizing: border-box;
  padding: 0px 0px 0px 10px;
  font-size: 18.5px;
  font-weight: 600;
  border-radius: 0px;
  color: #fff;
  background: rgba(255, 255, 255, 0.1294117647);
  position: relative;
  left: -14px;
  width: 106%;
}
#body.bright .blocworx-popup .flexbox .flex-child .blocworx-content {
  width: 100%;
  box-sizing: border-box;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  align-content: stretch;
  justify-content: flex-start;
  padding: 14px;
}
#body.bright .blocworx-popup .flexbox .flex-child .blocworx-content form {
  width: 100%;
}
#body.bright .blocworx-popup .flexbox .flex-child .button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  min-height: 40px;
  margin-top: 10px;
  width: 100%;
}
#body.bright .blocworx-popup .flexbox .flex-child .flexbox .flex-child .box-title {
  border-radius: 0px;
  background-color: inherit;
}
#body.bright .blocworx-popup .flexboxHeader {
  margin: 2px 0px 10px 0px;
}
#body.bright .blocworx-popup .box-container .column, #body.bright .blocworx-popup .box-container .flex-child, #body.bright .blocworx-popup .flexbox .column, #body.bright .blocworx-popup .flexbox .flex-child {
  background: #eaeaea;
}
#body.bright .blocworx-popup .box-container .box-title, #body.bright .blocworx-popup .flexbox .box-title {
  color: white;
  background-color: rgba(69, 80, 82, 0.7098039216);
}
#body.bright .blocworx-popup .box-container .bloc-setting-panel .box-title, #body.bright .blocworx-popup .flexbox .bloc-setting-panel .box-title {
  color: #004854;
  background: inherit;
}
#body.bright .blocworx-popup .box-container button p, #body.bright .blocworx-popup .flexbox button p {
  color: white;
}
#body.bright .blocworx-popup .box-container .info-wrapper, #body.bright .blocworx-popup .box-container p, #body.bright .blocworx-popup .box-container label, #body.bright .blocworx-popup .flexbox .info-wrapper, #body.bright .blocworx-popup .flexbox p, #body.bright .blocworx-popup .flexbox label {
  color: #004854;
}

@media only screen and (max-width: 367px) {
  #body #modal .box-container, #body #modal .flexbox {
    flex-direction: column;
  }
  #body #modal .box-container .box-title, #body #modal .flexbox .box-title {
    font-size: 16px;
  }
  #body #modal .box-container .column, #body #modal .box-container .flex-child, #body #modal .flexbox .column, #body #modal .flexbox .flex-child {
    margin-left: 0px;
    margin-right: 0px;
  }
  #body #modal .box-container .wrapper .button-wrapper button, #body #modal .flexbox .wrapper .button-wrapper button {
    font-size: 14px;
  }
  #body #modal .box-container .wrapper .button-wrapper .mobile, #body #modal .flexbox .wrapper .button-wrapper .mobile {
    display: inline-flex;
  }
  #body #modal .box-container .wrapper .button-wrapper .desktop, #body #modal .flexbox .wrapper .button-wrapper .desktop {
    display: none;
  }
  #body #modal .flexbox .flex-child {
    margin-bottom: 20px;
  }
  #body #modal .flexbox .flex-child.half {
    max-width: 100%;
  }
  #body #modal .flexbox .flex-child.third {
    max-width: 100%;
  }
  #body #main #pageContent article .box-container, #body #main #pageContent article .flexbox {
    flex-direction: column;
  }
  #body #main #pageContent article .box-container .box-title, #body #main #pageContent article .flexbox .box-title {
    font-size: 16px;
  }
  #body #main #pageContent article .box-container .column, #body #main #pageContent article .box-container .flex-child, #body #main #pageContent article .flexbox .column, #body #main #pageContent article .flexbox .flex-child {
    margin-left: 0px;
    margin-right: 0px;
  }
  #body #main #pageContent article .box-container .wrapper .button-wrapper button, #body #main #pageContent article .flexbox .wrapper .button-wrapper button {
    font-size: 14px;
  }
  #body #main #pageContent article .box-container .wrapper .button-wrapper .mobile, #body #main #pageContent article .flexbox .wrapper .button-wrapper .mobile {
    display: inline-flex;
  }
  #body #main #pageContent article .box-container .wrapper .button-wrapper .desktop, #body #main #pageContent article .flexbox .wrapper .button-wrapper .desktop {
    display: none;
  }
  #body #main #pageContent article .flexbox .flex-child {
    margin-bottom: 20px;
  }
  #body #main #pageContent article .flexbox .flex-child.half {
    max-width: 100%;
  }
  #body #main #pageContent article .flexbox .flex-child.third {
    max-width: 100%;
  }
}
@media only screen and (min-width: 367px) and (max-width: 767px) {
  #body #modal .box-container, #body #modal .flexbox {
    flex-direction: column;
  }
  #body #modal .box-container .box-title, #body #modal .flexbox .box-title {
    font-size: 16px;
  }
  #body #modal .box-container .column, #body #modal .box-container .flex-child, #body #modal .flexbox .column, #body #modal .flexbox .flex-child {
    margin-left: 0px;
    margin-right: 0px;
  }
  #body #modal .box-container .wrapper .button-wrapper button, #body #modal .flexbox .wrapper .button-wrapper button {
    font-size: 14px;
  }
  #body #modal .box-container .wrapper .button-wrapper .mobile, #body #modal .flexbox .wrapper .button-wrapper .mobile {
    display: inline-flex;
  }
  #body #modal .box-container .wrapper .button-wrapper .desktop, #body #modal .flexbox .wrapper .button-wrapper .desktop {
    display: none;
  }
  #body #modal .flexbox .flex-child {
    margin-bottom: 20px;
  }
  #body #modal .flexbox .flex-child.half {
    max-width: 100%;
  }
  #body #modal .flexbox .flex-child.third {
    max-width: 100%;
  }
  #body #main #pageContent article .box-container, #body #main #pageContent article .flexbox {
    flex-direction: column;
  }
  #body #main #pageContent article .box-container .box-title, #body #main #pageContent article .flexbox .box-title {
    font-size: 16px;
  }
  #body #main #pageContent article .box-container .column, #body #main #pageContent article .box-container .flex-child, #body #main #pageContent article .flexbox .column, #body #main #pageContent article .flexbox .flex-child {
    margin-left: 0px;
    margin-right: 0px;
  }
  #body #main #pageContent article .box-container .wrapper .button-wrapper button, #body #main #pageContent article .flexbox .wrapper .button-wrapper button {
    font-size: 14px;
  }
  #body #main #pageContent article .box-container .wrapper .button-wrapper .mobile, #body #main #pageContent article .flexbox .wrapper .button-wrapper .mobile {
    display: inline-flex;
  }
  #body #main #pageContent article .box-container .wrapper .button-wrapper .desktop, #body #main #pageContent article .flexbox .wrapper .button-wrapper .desktop {
    display: none;
  }
  #body #main #pageContent article .flexbox .flex-child {
    margin-bottom: 20px;
  }
  #body #main #pageContent article .flexbox .flex-child.half {
    max-width: 100%;
  }
  #body #main #pageContent article .flexbox .flex-child.third {
    max-width: 100%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 979px) {
  #body #modal .box-container .wrapper .column, #body #modal .box-container .wrapper .flex-child, #body #modal .flexbox .wrapper .column, #body #modal .flexbox .wrapper .flex-child {
    margin-left: 0px;
    margin-right: 0px;
  }
  #body #modal .box-container .wrapper .button-wrapper .mobile, #body #modal .flexbox .wrapper .button-wrapper .mobile {
    display: none;
  }
  #body #modal .box-container .wrapper .button-wrapper .desktop, #body #modal .flexbox .wrapper .button-wrapper .desktop {
    display: inline-flex;
  }
  #body #main #pageContent article .box-container .wrapper .column, #body #main #pageContent article .box-container .wrapper .flex-child, #body #main #pageContent article .flexbox .wrapper .column, #body #main #pageContent article .flexbox .wrapper .flex-child {
    margin-left: 0px;
    margin-right: 0px;
  }
  #body #main #pageContent article .box-container .wrapper .button-wrapper .mobile, #body #main #pageContent article .flexbox .wrapper .button-wrapper .mobile {
    display: none;
  }
  #body #main #pageContent article .box-container .wrapper .button-wrapper .desktop, #body #main #pageContent article .flexbox .wrapper .button-wrapper .desktop {
    display: inline-flex;
  }
}
@media (min-width: 979px) and (max-width: 1024px) {
  #body #modal .box-container .wrapper .column, #body #modal .box-container .wrapper .flex-child, #body #modal .flexbox .wrapper .column, #body #modal .flexbox .wrapper .flex-child {
    margin-left: 0px;
    margin-right: 0px;
  }
  #body #modal .box-container .wrapper .button-wrapper .mobile, #body #modal .flexbox .wrapper .button-wrapper .mobile {
    display: none;
  }
  #body #modal .box-container .wrapper .button-wrapper .desktop, #body #modal .flexbox .wrapper .button-wrapper .desktop {
    display: inline-flex;
  }
  #body #main #pageContent article .box-container .wrapper .column, #body #main #pageContent article .box-container .wrapper .flex-child, #body #main #pageContent article .flexbox .wrapper .column, #body #main #pageContent article .flexbox .wrapper .flex-child {
    margin-left: 0px;
    margin-right: 0px;
  }
  #body #main #pageContent article .box-container .wrapper .button-wrapper .mobile, #body #main #pageContent article .flexbox .wrapper .button-wrapper .mobile {
    display: none;
  }
  #body #main #pageContent article .box-container .wrapper .button-wrapper .desktop, #body #main #pageContent article .flexbox .wrapper .button-wrapper .desktop {
    display: inline-flex;
  }
}
@media (min-width: 1025px) and (max-width: 1340px) {
  #body #modal .box-container .wrapper .column, #body #modal .box-container .wrapper .flex-child, #body #modal .flexbox .wrapper .column, #body #modal .flexbox .wrapper .flex-child {
    margin-left: 0px;
    margin-right: 0px;
  }
  #body #modal .box-container .wrapper .button-wrapper .mobile, #body #modal .flexbox .wrapper .button-wrapper .mobile {
    display: none;
  }
  #body #modal .box-container .wrapper .button-wrapper .desktop, #body #modal .flexbox .wrapper .button-wrapper .desktop {
    display: inline-flex;
  }
  #body #main #pageContent article .box-container .wrapper .column, #body #main #pageContent article .box-container .wrapper .flex-child, #body #main #pageContent article .flexbox .wrapper .column, #body #main #pageContent article .flexbox .wrapper .flex-child {
    margin-left: 0px;
    margin-right: 0px;
  }
  #body #main #pageContent article .box-container .wrapper .button-wrapper .mobile, #body #main #pageContent article .flexbox .wrapper .button-wrapper .mobile {
    display: none;
  }
  #body #main #pageContent article .box-container .wrapper .button-wrapper .desktop, #body #main #pageContent article .flexbox .wrapper .button-wrapper .desktop {
    display: inline-flex;
  }
}
@media (min-width: 1340px) {
  #body #modal .box-container .wrapper .column, #body #modal .box-container .wrapper .flex-child, #body #modal .flexbox .wrapper .column, #body #modal .flexbox .wrapper .flex-child {
    margin-left: 0px;
    margin-right: 0px;
  }
  #body #modal .box-container .wrapper .button-wrapper .mobile, #body #modal .flexbox .wrapper .button-wrapper .mobile {
    display: none;
  }
  #body #modal .box-container .wrapper .button-wrapper .desktop, #body #modal .flexbox .wrapper .button-wrapper .desktop {
    display: inline-flex;
  }
  #body #main #pageContent article .box-container .wrapper .column, #body #main #pageContent article .box-container .wrapper .flex-child, #body #main #pageContent article .flexbox .wrapper .column, #body #main #pageContent article .flexbox .wrapper .flex-child {
    margin-left: 0px;
    margin-right: 0px;
  }
  #body #main #pageContent article .box-container .wrapper .button-wrapper .mobile, #body #main #pageContent article .flexbox .wrapper .button-wrapper .mobile {
    display: none;
  }
  #body #main #pageContent article .box-container .wrapper .button-wrapper .desktop, #body #main #pageContent article .flexbox .wrapper .button-wrapper .desktop {
    display: inline-flex;
  }
}
#body #main #pageContent article .table-header md-toolbar {
  padding: 10px;
}
@media only screen and (max-width: 599px) {
  #body #main #pageContent article .table-header md-toolbar {
    font-size: 16px;
  }
}
#body #main #pageContent article .table-header mat-grid-list {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}
#body #main #pageContent article .table-header dl {
  margin: 0px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  flex-direction: row;
}
#body #main #pageContent article .table-header dl dd {
  margin-left: 4px;
}
#body #main #pageContent article .table-header dl * {
  margin: 0px;
  padding: 0px;
}
#body #main #pageContent article .material-table-container {
  width: 100%;
  display: inline-flex;
  max-width: 98.5em;
}
@media only screen and (min-width: 3840px) {
  #body #main #pageContent article .material-table-container {
    max-width: 3840px;
  }
}
@media only screen and (min-width: 2560px) and (max-width: 3839px) {
  #body #main #pageContent article .material-table-container {
    max-width: 2226px;
  }
}
@media only screen and (min-width: 1920px) and (max-width: 2559px) {
  #body #main #pageContent article .material-table-container {
    max-width: 1586px;
  }
}
#body #main #pageContent article .material-table-container table {
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  display: inline-block;
}
#body #main #pageContent article .material-table-container table::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
#body #main #pageContent article .material-table-container table::-webkit-scrollbar-thumb {
  background: #142124;
}
#body #main #pageContent article .material-table-container table::-webkit-scrollbar-track {
  background: #455052;
}
#body #main #pageContent article .material-table-container table {
  scrollbar-face-color: #142124;
  scrollbar-track-color: #455052;
}
#body #main #pageContent article .material-table-container tbody td button {
  float: none;
  margin: 0px;
  width: 90px;
}
#body #main #pageContent article .material-table-container thead, #body #main #pageContent article .material-table-container tbody {
  display: flex;
  flex-wrap: wrap;
}
#body #main #pageContent article .material-table-container thead tr, #body #main #pageContent article .material-table-container tbody tr {
  display: flex;
  flex: 1 1 100%;
}
#body #main #pageContent article .material-table-container thead tr th, #body #main #pageContent article .material-table-container tbody tr td {
  flex: 1 1 100%;
  display: inline-flex;
  justify-content: center;
  align-content: center;
  overflow-wrap: break-word;
  white-space: normal;
  flex-direction: column;
  align-items: stretch;
  max-width: 300px;
  width: 140px;
}
#body #main div.skin-setting-dark #pageContent article .material-table-container table {
  border-radius: 0px 0px 11px 11px;
  border-color: #343a40;
  border-style: solid;
  border-width: 0px 10px 10px 10px;
}
#body #main div.skin-setting-dark #pageContent article .material-table-container table tr th {
  color: white;
}
#body #main div.skin-setting-dark #pageContent article .material-table-container table tr th > * {
  color: white;
}
#body #main div.skin-setting-dark #pageContent article .material-table-container table tbody tr:nth-child(even) {
  background: #c8cbcb;
}
#body #main div.skin-setting-dark #pageContent article .material-table-container table tbody tr:nth-child(odd) {
  background: #cedade;
}
#body #main div.skin-setting-dark #pageContent article .material-table-container table tbody tr td {
  color: #004854;
}
#body #main div.skin-setting-dark #pageContent article .material-table-container table tbody tr td > * {
  color: #004854;
}
#body #main div.skin-setting-dark #pageContent article .material-table-container table tbody tr td > button {
  color: white;
}
#body #main div.skin-setting-dark #pageContent article .material-table-container table tbody tr td .red-link {
  color: #ee3124;
}
#body #main div.skin-setting-bright #pageContent article .material-table-container table {
  border-radius: 0px 0px 11px 11px;
  border-color: #757d7e;
  border-style: solid;
  border-width: 0px 10px 10px 10px;
}
#body #main div.skin-setting-bright #pageContent article .material-table-container table tr th {
  color: #004854;
}
#body #main div.skin-setting-bright #pageContent article .material-table-container table tr th > * {
  color: white;
}
#body #main div.skin-setting-bright #pageContent article .material-table-container table tbody tr:nth-child(even) {
  background: #c8cbcb;
}
#body #main div.skin-setting-bright #pageContent article .material-table-container table tbody tr:nth-child(odd) {
  background: #cedade;
}
#body #main div.skin-setting-bright #pageContent article .material-table-container table tbody tr td {
  color: #004854;
}
#body #main div.skin-setting-bright #pageContent article .material-table-container table tbody tr td > * {
  color: #004854;
}
#body #main div.skin-setting-bright #pageContent article .material-table-container table tbody tr td > button {
  color: white;
}
#body #main div.skin-setting-bright #pageContent article .material-table-container table tbody tr td .red-link {
  color: #ee3124;
}

#body #main #pageContent article md-content {
  padding: 0px;
  border-radius: 11px;
}
#body #main #pageContent article md-content md-card {
  margin: 0px;
}
#body #main #pageContent article md-content md-card md-card-header md-card-header-text .md-subhead {
  margin-top: 4px;
}
#body #main #pageContent article md-content:nth-child(odd) {
  margin-top: 20px;
}
#body #main div.skin-setting-dark #pageContent article md-content md-card {
  background: #455052;
}
#body #main div.skin-setting-dark #pageContent article md-content md-card md-card-header {
  background: #343a40;
}
#body #main div.skin-setting-dark #pageContent article md-content md-card md-card-header md-card-header-text .md-title {
  color: white;
  background: #343a40;
}
#body #main div.skin-setting-dark #pageContent article md-content md-card md-card-header md-card-header-text .md-subhead {
  color: white;
  background: #343a40;
}
#body #main div.skin-setting-dark #pageContent article md-content md-card md-card-title md-card-title-text .md-headline {
  color: white;
}
#body #main div.skin-setting-dark #pageContent article md-content md-card md-card-title md-card-title-text .md-subhead {
  color: white;
}
#body #main div.skin-setting-dark #pageContent article md-content md-card md-card-actions button {
  color: white;
}
#body #main div.skin-setting-dark #pageContent article md-content md-card .md-button {
  color: white;
  background: #343a40;
}
#body #main div.skin-setting-dark #pageContent article md-content md-card md-card-content p, #body #main div.skin-setting-dark #pageContent article md-content md-card md-card-content div, #body #main div.skin-setting-dark #pageContent article md-content md-card md-card-content span {
  color: white;
}
#body #main div.skin-setting-dark #pageContent article md-content md-card mat-grid-tile {
  display: inline-flex;
  align-items: center;
}
#body #main div.skin-setting-dark #pageContent article md-content md-card md-toolbar {
  color: #d1d3d3;
  background: #343a40;
  border-radius: 11px 11px 0px 0px;
  display: inline-flex;
}
#body #main div.skin-setting-bright #pageContent article md-content md-card {
  background: #eaeaea;
}
#body #main div.skin-setting-bright #pageContent article md-content md-card md-card-header {
  background: #757d7e;
}
#body #main div.skin-setting-bright #pageContent article md-content md-card md-card-header md-card-header-text .md-title {
  color: white;
  background: #757d7e;
}
#body #main div.skin-setting-bright #pageContent article md-content md-card md-card-header md-card-header-text .md-subhead {
  color: white;
  background: #757d7e;
}
#body #main div.skin-setting-bright #pageContent article md-content md-card md-card-title md-card-title-text .md-headline {
  color: #004854;
}
#body #main div.skin-setting-bright #pageContent article md-content md-card md-card-title md-card-title-text .md-subhead {
  color: #004854;
}
#body #main div.skin-setting-bright #pageContent article md-content md-card md-card-actions button {
  color: white;
}
#body #main div.skin-setting-bright #pageContent article md-content md-card .md-button {
  color: white;
  background: #757d7e;
}
#body #main div.skin-setting-bright #pageContent article md-content md-card md-card-content p, #body #main div.skin-setting-bright #pageContent article md-content md-card md-card-content div, #body #main div.skin-setting-bright #pageContent article md-content md-card md-card-content span {
  color: #004854;
}
#body #main div.skin-setting-bright #pageContent article md-content md-card mat-grid-tile {
  display: inline-flex;
  align-items: center;
}
#body #main div.skin-setting-bright #pageContent article md-content md-card md-toolbar {
  color: #d1d3d3;
  background: #757d7e;
  border-radius: 11px 11px 0px 0px;
  display: inline-flex;
}

body.modal-on {
  overflow: hidden;
}
body.modal-off {
  overflow: inherit;
}
body #main #pageContent article .scan-station-results .pagination-top {
  display: flex;
  align-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
body #main #pageContent article .scan-station-results .flex-container {
  display: flex;
  align-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
body #main #pageContent article .scan-station-results .flex-item {
  display: flex;
  width: 100%;
  flex: 1 1 auto;
  flex-direction: row;
  align-items: center;
  margin: 0px auto;
  flex-wrap: wrap;
}
body #main #pageContent article .scan-station-results .flex-item select {
  width: 39%;
  margin: 0px 20px 0px 0px;
}
body #main #pageContent article .scan-station-results .flex-item button {
  padding: 10px;
}
body #main #pageContent article .scan-station-results .pagination-bottom {
  display: flex;
  align-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
body #main #pageContent article .scan-station-results .pagination-bottom .flex-item select {
  width: 230px;
}
body #main #pageContent article .scan-station-results .pagination-bottom label {
  margin-top: 10px;
}
body #main #pageContent article .scan-station-front-end .one-third-block {
  margin: 0px 1.5% 30px 0px;
}
body #main #pageContent article .scan-station-front-end .two-thirds-block {
  margin: 0px;
  width: 67%;
}
body #main #pageContent article .invisible-wrap .bloc-setting-panel .box-title {
  margin: 0px 0px 15px 0px;
  padding: 0px;
}
body #main #pageContent article .invisible-wrap .update-number-off-fields .one-third-block, body #main #pageContent article .invisible-wrap .update-number-off-fields .invisible-wrap {
  margin: 15px 0px;
}
body #main #pageContent article #dialogContainer {
  display: inline-block;
  margin: 10px auto;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  padding: 10px 20px;
  width: 100%;
}
body #main #pageContent article #dialogContainer p, body #main #pageContent article #dialogContainer input {
  display: inline-block;
}
body #main #pageContent article #dialogContainer p {
  margin: 8px auto;
}
body #main #pageContent article #dialogContainer .alert-container {
  margin-top: 2px;
  margin-bottom: 1px;
}
body #main #pageContent article #dialogContainer .export-filtered-data {
  margin-top: 4px;
  float: left;
  border: none;
  padding: 10px 20px;
}
body #main #pageContent article #dialogError, body #main #pageContent article #dialogSuccess {
  height: 70px;
  clear: both;
  position: relative;
  margin: 0px 20px;
  top: 20px;
}
body #main #pageContent article #showDeleteAllData button {
  float: left;
  margin: 0px 0px 0px 20px;
  border: none;
  padding: 10px 20px;
}
body #main #pageContent article #showDeleteAllData button:hover {
  background-color: #ee3124;
  font-weight: bold;
}
body #main #pageContent article #close-container {
  width: 40px;
  display: inline-block;
  float: right;
  right: 20px;
  position: relative;
  cursor: pointer;
}
body #main #pageContent article #close-container #close-button {
  display: inline-block;
  font-size: 30px;
  color: white;
  width: 40px;
}
body #main #pageContent article .field-parameter-information {
  position: fixed;
  width: 90% !important;
  left: 3% !important;
  top: 7%;
  z-index: 1;
  height: auto;
  overflow: auto;
  background: #374244;
  max-height: 90% !important;
  border-radius: 11px;
}
body #main #pageContent article #background {
  background: black;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)"; /* IE 8 */
  filter: alpha(opacity=80); /* IE 5-7 */
  -moz-opacity: 0.8; /* Netscape */
  -khtml-opacity: 0.8; /* Safari 1.x */
  opacity: 0.8;
  width: 100%;
  position: fixed;
  left: 1px;
  top: 1px;
  height: 100vh;
  z-index: 2;
  display: none;
}
body #main #pageContent article #background.on {
  display: inline-block;
}
body #main #pageContent article #background.off {
  display: none;
}
body #main #pageContent article .edit-new-field-container.accordian {
  border: 1px dashed;
  float: left;
  box-sizing: border-box;
  padding: 15px;
  border-radius: 10px;
  position: relative;
  margin-bottom: 20px;
  margin-top: 20px;
  height: auto;
  margin-top: 0px !important;
  width: 100%;
}
body #main #pageContent article .bloc-setting-panel button {
  border: none;
  font-size: 14px;
  min-height: 50px;
}
body #main #pageContent article .bloc-editor-update-complete {
  -webkit-transition: all 1s ease 0s;
  -moz-transition: all 1s ease 0s;
  -ms-transition: all 1s ease 0s;
  -o-transition: all 1s ease 0s;
  transition: all 1s ease 0s;
  position: fixed;
  bottom: 0px;
  right: 0px;
  width: 278px;
  text-align: center;
  background: green;
  box-sizing: border-box;
  padding: 20px 33px;
  border-radius: 9px 0px 0px 0px;
  -webkit-transform: translateY(80px);
  -moz-transform: translateY(80px);
  -ms-transform: translateY(80px);
  -o-transform: translateY(80px);
  transform: translateY(80px);
  opacity: 0;
  z-index: 9999999999;
}
body #main #pageContent article .bloc-editor-update-complete.show {
  -webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  -ms-transform: translateY(0px);
  -o-transform: translateY(0px);
  transform: translateY(0px);
  opacity: 1;
}
body #main #pageContent article .bloc-editor-error {
  -webkit-transition: all 1s ease 0s;
  -moz-transition: all 1s ease 0s;
  -ms-transition: all 1s ease 0s;
  -o-transition: all 1s ease 0s;
  transition: all 1s ease 0s;
  position: fixed;
  bottom: 0px;
  right: 0px;
  width: 100%;
  text-align: center;
  background: #812121;
  box-sizing: border-box;
  padding: 20px 33px;
  border-radius: 9px 0px 0px 0px;
  -webkit-transform: translateY(80px);
  -moz-transform: translateY(80px);
  -ms-transform: translateY(80px);
  -o-transform: translateY(80px);
  transform: translateY(80px);
  opacity: 0;
  z-index: 9999999999;
}
body #main #pageContent article .bloc-editor-error.show {
  -webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  -ms-transform: translateY(0px);
  -o-transform: translateY(0px);
  transform: translateY(0px);
  opacity: 1;
}
@media only screen and (max-width: 1150px) {
  body #main #pageContent article body #main #pageContent article .scan-station-results .delete-scan-station-data button,
  #body #main #pageContent article .scan-station-results .delete-scan-station-data button {
    clear: none;
  }
}
@media only screen and (max-width: 976px) {
  body #main #pageContent article .invisible-wrap.two-thirds-block, body #main #pageContent article .invisible-wrap.one-third-block, #body #main #pageContent article .invisible-wrap.two-thirds-block, #body #main #pageContent article .invisible-wrap.one-third-block {
    width: 100% !important;
    padding: 0px !important;
  }
}
body #main #pageContent article blocworx-authentication {
  height: 100%;
  box-sizing: border-box;
  position: relative;
  width: 100%;
}
body #main #pageContent article blocworx-authentication .template {
  height: 100%;
  display: flex;
  align-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
  justify-content: center;
}
body #main #pageContent article blocworx-authentication .loading-view {
  display: flex;
  font-size: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
}
body #main #pageContent article blocworx-authentication p, body #main #pageContent article blocworx-authentication img {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}
body #main #pageContent article blocworx-authentication p {
  flex: 1;
  font-size: 30px;
  margin-bottom: 20px;
}
body #main #pageContent article blocworx-authentication img {
  flex: 1;
  height: auto;
  justify-content: space-evenly;
  display: inline-flex;
  width: 100px;
  max-height: 100px;
}
body #main div.skin-setting-dark #pageContent article blocworx-authentication .template {
  background: #232d2f;
}
body #main div.skin-setting-dark #pageContent article blocworx-authentication p, body #main div.skin-setting-dark #pageContent article blocworx-authentication img {
  color: #cedade;
}
body #main div.skin-setting-bright #pageContent article blocworx-authentication .template {
  background: #ffffff;
}
body #main div.skin-setting-bright #pageContent article blocworx-authentication p, body #main div.skin-setting-bright #pageContent article blocworx-authentication img {
  color: #004854;
}

body #main #pageContent article blocworx-forbidden {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  width: 100%;
  flex: 1 1 auto;
}
body #main #pageContent article blocworx-forbidden .forbidden-access {
  width: 100%;
  text-align: center;
  font-size: 30px;
  display: inline-flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  flex: 1 1 auto;
}
body #main #pageContent article blocworx-forbidden .forbidden-access .button {
  text-decoration: none;
  float: none;
  margin: 0px 10px 0px 0px;
}
body #main #pageContent article blocworx-forbidden .forbidden-access p {
  margin: 0px 0px 0px 10px;
}
body #main #pageContent header blocworx-logo .logo {
  margin: 0px 22px;
  display: flex;
  width: 210px;
  height: 100%;
  align-items: center;
  justify-content: center;
  align-content: center;
}
body #main #pageContent header blocworx-logo .logo-container {
  display: flex;
}
body #main #pageContent header blocworx-logo .logo-container img {
  margin: 0px;
  padding: 2px;
  max-height: 86px;
  max-width: 210px;
}
body #main #pageContent blocworx-copyright {
  display: inline-flex;
  width: 100%;
  justify-content: space-evenly;
  margin: 10px 0px;
}
body #main #pageContent blocworx-copyright .copyright-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-content: center;
  min-height: 30px;
  flex-direction: row;
  flex-wrap: wrap;
}
body #main #pageContent blocworx-copyright .copyright-container .reload .reload-version {
  cursor: pointer;
  display: inline-flex;
  height: 100%;
}
body #main #pageContent blocworx-copyright .copyright-container .reload .reload-version span {
  top: 2px;
  position: relative;
  color: #418995;
  font-size: 15px;
  margin-top: 4px;
}
body #main #pageContent blocworx-copyright .copyright-container .images, body #main #pageContent blocworx-copyright .copyright-container .reload {
  height: 100%;
  display: inline-flex;
  align-items: flex-start;
}
body #main div.skin-setting-dark #pageContent blocworx-copyright .copyright-container .reload .reload-version {
  color: #f0f4f5;
}
body #main div.skin-setting-dark #pageContent blocworx-copyright .copyright-container .light {
  display: none;
}
body #main div.skin-setting-bright #pageContent blocworx-copyright .copyright-container .reload .reload-version {
  color: #004854;
}
body #main div.skin-setting-bright #pageContent blocworx-copyright .copyright-container .dark {
  display: none;
}

body #main header navbar-toggle {
  margin-right: 18px;
}
body #main #pageContent blocworx-navbar-mobile {
  display: none;
}
body #main #pageContent .mobile .mobile-menu-click {
  float: right;
  width: 44px;
  margin-top: -3px;
}
body #main #pageContent .mobile .mobile-menu-click {
  float: right;
  width: 44px;
  margin-top: 20px;
  cursor: pointer;
  position: absolute;
  right: 4%;
  top: 8px;
}
body #main #pageContent .mobile .mobile-menu-click span {
  float: left;
  width: 37px;
  height: 4px;
  background: #fff;
  margin-bottom: 6px;
  border-radius: 7px;
}
body #main #pageContent article .customer-logo {
  width: 100%;
  height: auto;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  margin-bottom: 15px;
  float: none;
  background: #ffffff;
}
body #main #pageContent article .customer-logo button {
  font-size: 13px;
  border: none;
  padding: 5px 16px;
  position: absolute;
  bottom: 0px;
  right: 0px;
  opacity: 0;
}
body #main #pageContent article blocworx-navbar #blocworxNavbar {
  display: flex;
  height: 100%;
  position: relative;
  flex-direction: column;
}
body #main #pageContent article blocworx-navbar .sidebar .sidebar-item.active span,
body #main #pageContent article blocworx-navbar .sidebar .sidebar-item.active svg path {
  color: #fff;
  fill: #fff;
}
body #main #pageContent article blocworx-navbar .sidebar .menu-item-wrapper, body #main #pageContent article blocworx-navbar .sidebar .menu-item-wrapper-logout {
  display: flex;
}
body #main #pageContent article blocworx-navbar .sidebar .menu-item-wrapper h3, body #main #pageContent article blocworx-navbar .sidebar .menu-item-wrapper-logout h3 {
  display: flex;
  padding: 0px 0px;
  margin: 0px;
  font-size: 13.75px;
  font-weight: normal;
}
body #main #pageContent article blocworx-navbar .sidebar .menu-item-wrapper h3 span, body #main #pageContent article blocworx-navbar .sidebar .menu-item-wrapper-logout h3 span {
  margin: 0px;
  padding: 0px;
  float: none;
}
body #main #pageContent article blocworx-navbar .sidebar .menu-item-wrapper h3 span:nth-child(1), body #main #pageContent article blocworx-navbar .sidebar .menu-item-wrapper-logout h3 span:nth-child(1) {
  display: flex;
  margin-right: 6px;
  flex-basis: 18%;
  align-items: center;
  justify-content: center;
}
body #main #pageContent article blocworx-navbar .sidebar .menu-item-wrapper h3 span:nth-child(2), body #main #pageContent article blocworx-navbar .sidebar .menu-item-wrapper-logout h3 span:nth-child(2) {
  display: flex;
  flex-basis: 80%;
  justify-content: flex-start;
  align-items: center;
}
body #main #pageContent article blocworx-navbar .sidebar .menu-item-wrapper-logout {
  float: left;
  width: 100%;
}
body #main #pageContent article blocworx-navbar .sidebar .sidebar-item {
  width: 100%;
  box-sizing: border-box;
  padding: 8px;
  margin-bottom: 3px;
  position: relative;
  z-index: 11;
  height: 45px;
}
body #main #pageContent article blocworx-navbar .sidebar .sidebar-item i, body #main #pageContent article blocworx-navbar .sidebar .sidebar-item svg {
  font-size: 22px;
  opacity: 1;
  border: none;
  width: 28px;
}
body #main #pageContent article blocworx-navbar .sidebar .sidebar-item svg {
  margin-top: 0px;
}
body #main #pageContent article blocworx-navbar .sidebar .sidebar-item #NAV_Logout {
  width: 25px;
}
body #main #pageContent article blocworx-navbar .sidebar .sidebar-item .sidebar-centered {
  margin-top: 6px;
}
body #main #pageContent article blocworx-navbar .sidebar .username-text {
  display: inline-flex;
  clear: both;
  font-size: 16px;
  margin: 0px 0px 15px 0px;
  width: 100%;
  min-height: 50px;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  align-content: stretch;
}
body #main #pageContent article blocworx-navbar .sidebar .mobile-menu-spacing {
  min-height: 70px;
}
body #main #pageContent article blocworx-navbar .sidebar .main-menu > div:last-child .sidebar-item {
  margin-bottom: 0px;
}
body #main #pageContent article blocworx-navbar navbar-switch {
  display: flex;
  position: absolute;
  top: 0px;
  z-index: 999;
  flex: 1 1 auto;
}
body #main #pageContent article blocworx-navbar navbar-switch.open {
  justify-content: flex-start;
  padding-left: 16px;
  padding-top: 10px;
}
body #main #pageContent article blocworx-navbar navbar-switch:hover {
  max-width: 233px;
  background: transparent;
}
body #main #pageContent article blocworx-navbar navbar-switch div {
  border-radius: 5px;
  padding: 0px;
  height: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  align-content: center;
  flex-direction: row;
}
body #main #pageContent article blocworx-navbar navbar-switch div:hover {
  cursor: pointer;
  display: flex;
  padding: 0px;
  min-width: 110px;
  height: 20px;
  align-items: center;
}
body #main #pageContent article blocworx-navbar navbar-switch div:hover .text-hover {
  display: flex;
}
body #main #pageContent article blocworx-navbar navbar-switch i {
  padding: 2px;
  font-size: 13px;
}
body #main #pageContent article blocworx-navbar navbar-switch p {
  font-size: 13px;
}
body #main #pageContent article blocworx-navbar navbar-switch .text-hover {
  display: none;
}
body #main div.skin-setting-dark #pageContent article blocworx-navbar {
  background: #232d2f;
  color: #f0f4f5;
}
body #main div.skin-setting-dark #pageContent article blocworx-navbar .sidebar {
  background: #172124;
  color: #004854;
}
body #main div.skin-setting-dark #pageContent article blocworx-navbar .sidebar .menu-item-wrapper.home {
  border-top: 4px solid #ee3124;
}
body #main div.skin-setting-dark #pageContent article blocworx-navbar .sidebar .sidebar-item {
  background: #d1d3d3;
  color: #004854;
}
body #main div.skin-setting-dark #pageContent article blocworx-navbar .sidebar .sidebar-item h3 {
  color: #004854;
}
body #main div.skin-setting-dark #pageContent article blocworx-navbar .sidebar .sidebar-item .username-text {
  color: #f0f4f5;
}
body #main div.skin-setting-dark #pageContent article blocworx-navbar .sidebar .sidebar-item a {
  color: #ee3124;
}
body #main div.skin-setting-dark #pageContent article blocworx-navbar .sidebar .sidebar-item.active {
  background: #004854;
}
body #main div.skin-setting-dark #pageContent article blocworx-navbar .sidebar .sidebar-item i, body #main div.skin-setting-dark #pageContent article blocworx-navbar .sidebar .sidebar-item svg {
  color: #ee3124;
  fill: #ee3124;
}
body #main div.skin-setting-dark #pageContent article blocworx-navbar .sidebar .sidebar-item:hover {
  background: #004854;
  color: #fff;
}
body #main div.skin-setting-dark #pageContent article blocworx-navbar .sidebar .sidebar-item:hover i, body #main div.skin-setting-dark #pageContent article blocworx-navbar .sidebar .sidebar-item:hover svg, body #main div.skin-setting-dark #pageContent article blocworx-navbar .sidebar .sidebar-item:hover path {
  color: #fff;
  fill: #fff;
}
body #main div.skin-setting-dark #pageContent article blocworx-navbar .sidebar .sidebar-item:hover h3 {
  color: #fff;
  fill: #fff;
}
body #main div.skin-setting-dark #pageContent article blocworx-navbar .sidebar .refresh-version {
  color: #004854;
}
body #main div.skin-setting-dark #pageContent article blocworx-navbar .sidebar .refresh-version h3 {
  color: #418995;
}
body #main div.skin-setting-dark #pageContent article navbar-switch {
  background: transparent;
}
body #main div.skin-setting-dark #pageContent article navbar-switch div {
  border: 1px dashed #cedade;
}
body #main div.skin-setting-dark #pageContent article navbar-switch.open {
  background: #172124;
}
body #main div.skin-setting-bright #pageContent .sidebar .mobile-menu-click span {
  color: #004854 !important;
  background: #004854 !important;
}
body #main div.skin-setting-bright #pageContent article blocworx-navbar {
  background: #fff;
  color: #004854;
}
body #main div.skin-setting-bright #pageContent article blocworx-navbar .sidebar {
  background: #cedade;
  color: #004854;
}
body #main div.skin-setting-bright #pageContent article blocworx-navbar .sidebar .menu-item-wrapper.home {
  border-top: 4px solid #ee3124;
}
body #main div.skin-setting-bright #pageContent article blocworx-navbar .sidebar .sidebar-item {
  background: #fff;
  color: #004854;
}
body #main div.skin-setting-bright #pageContent article blocworx-navbar .sidebar .sidebar-item h3 {
  color: #004854;
}
body #main div.skin-setting-bright #pageContent article blocworx-navbar .sidebar .sidebar-item .username-text {
  color: #004854;
}
body #main div.skin-setting-bright #pageContent article blocworx-navbar .sidebar .sidebar-item a {
  color: #ee3124;
}
body #main div.skin-setting-bright #pageContent article blocworx-navbar .sidebar .sidebar-item.active {
  background: #004854;
}
body #main div.skin-setting-bright #pageContent article blocworx-navbar .sidebar .sidebar-item i, body #main div.skin-setting-bright #pageContent article blocworx-navbar .sidebar .sidebar-item svg {
  color: #ee3124;
  fill: #ee3124;
}
body #main div.skin-setting-bright #pageContent article blocworx-navbar .sidebar .sidebar-item:hover {
  background: #004854;
  color: #fff;
}
body #main div.skin-setting-bright #pageContent article blocworx-navbar .sidebar .sidebar-item:hover i, body #main div.skin-setting-bright #pageContent article blocworx-navbar .sidebar .sidebar-item:hover svg, body #main div.skin-setting-bright #pageContent article blocworx-navbar .sidebar .sidebar-item:hover path {
  color: #fff;
  fill: #fff;
}
body #main div.skin-setting-bright #pageContent article blocworx-navbar .sidebar .sidebar-item:hover h3 {
  color: #fff;
  fill: #fff;
}
body #main div.skin-setting-bright #pageContent article blocworx-navbar .sidebar .refresh-version {
  color: #004854;
}
body #main div.skin-setting-bright #pageContent article blocworx-navbar .sidebar .refresh-version h3 {
  color: #004854;
}
body #main div.skin-setting-bright #pageContent article navbar-switch div {
  border: 1px dashed #004854;
}
body #main div.skin-setting-bright #pageContent article navbar-switch.open {
  background: #cedade;
}

@media only screen and (max-width: 979px) {
  body #main #pageContent .mobile.mobile-menu-click {
    float: right;
    width: 44px;
    margin-top: 20px;
    cursor: pointer;
    position: absolute;
    right: 4%;
    top: 8px;
  }
  body #main #pageContent .mobile-menu-click span {
    float: left;
    width: 37px;
    height: 4px;
    background: #fff;
    margin-bottom: 6px;
    border-radius: 7px;
  }
  body #main #pageContent .mobile-menu-click.in-sidebar span:nth-child(1) {
    transform: rotate(45deg) translate(2px, 10px);
    -webkit-transform: rotate(45deg) translate(0px, 8px);
    -moz-transform: rotate(45deg) translate(0px, 7px);
    -o-transform: rotate(45deg) translate(2px, 10px);
  }
  body #main #pageContent .mobile-menu-click.in-sidebar span:nth-child(2) {
    transform: rotate(-45deg) translate(0px, -8px);
    -webkit-transform: rotate(-45deg) translate(0px, -8px);
    -moz-transform: rotate(-45deg) translate(0px, -8px);
    -o-transform: rotate(-45deg) translate(0px, -8px);
  }
  body #main #pageContent .mobile-menu-click.in-sidebar span:nth-child(3) {
    opacity: 0;
  }
  body #main #pageContent article blocworx-navbar {
    display: none;
  }
  body #main #pageContent article blocworx-navbar.open {
    display: inline-flex;
  }
  body #main #pageContent article blocworx-navbar .sidebar {
    top: 0px;
    right: 0px;
    position: fixed;
    overflow: auto;
  }
  body #main #pageContent article navbar-switch {
    display: none !important;
  }
  body #main #pageContent blocworx-navbar-mobile {
    display: inline-flex;
  }
}
body #main #pageContent article .scan-station-results .delete-scan-station-data {
  padding: 0px;
  margin: 15px 0px;
}
body #main #pageContent article .scan-station-results .delete-scan-station-data > button {
  margin-left: 20px;
}
body #main #pageContent article table-blocworx {
  margin-bottom: 20px;
}
body #main #pageContent article table-blocworx .customer-list {
  margin-top: 20px;
}
body #main #pageContent article form-blocworx .standard-box {
  margin-top: 30px;
}
body #main #pageContent article blocworx-message {
  display: inline-flex;
  width: 100%;
}
body #main #pageContent article blocworx-message .message-box {
  width: 100%;
}
body #main #pageContent article blocworx-message .message-container {
  padding: 12px;
  margin: 10px 0px;
  color: #333;
  background: #fafafa;
  border: 1px solid #ccc;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: space-between;
}
body #main #pageContent article blocworx-message .message-container .message-header {
  flex-grow: 16;
  flex-shrink: 6;
  font-size: 20px;
  height: 40px;
  display: flex;
  align-items: center;
}
body #main #pageContent article blocworx-message .message-container .close {
  flex-grow: 0;
  flex-shrink: 4;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  min-width: 40px;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  height: 40px;
}
body #main #pageContent article blocworx-message .message-container .close:hover {
  cursor: pointer;
  background: rgba(0, 0, 0, 0.4);
  color: white;
}
body #main #pageContent article blocworx-message .message-container .message {
  flex: 1 1 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
body #main #pageContent article blocworx-message .message-container .message .ico {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  max-width: 30px;
  width: 30px;
  align-content: center;
  font-size: 22px;
}
body #main #pageContent article blocworx-message .message-container.info {
  color: #204a8e;
  background: #c9ddff;
  border: 1px solid #4c699b;
}
body #main #pageContent article blocworx-message .message-container.success {
  color: #2b7515;
  background: #ecffd6;
  border: 1px solid #617c42;
}
body #main #pageContent article blocworx-message .message-container.warning {
  color: #756e15;
  background: #fffbd1;
  border: 1px solid #87803e;
}
body #main #pageContent article blocworx-message .message-container.error {
  color: #ba3939;
  background: #ffe0e0;
  border: 1px solid #a33a3a;
}
body #main #blocworx-messages {
  width: 100%;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  flex: 1 1 auto;
  z-index: 9999999999;
  margin-bottom: 0px;
  align-content: space-around;
  flex-wrap: wrap;
  float: left;
  position: fixed;
  bottom: 0px;
}
body #main #blocworx-messages .floating-message-line {
  text-align: center;
  box-sizing: border-box;
  z-index: 9999999999;
  display: flex;
  flex-direction: column-reverse;
  align-items: stretch;
  flex-wrap: wrap;
  width: 100%;
}
body #main #blocworx-messages .floating-message-line .message-container {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  margin-top: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: column;
}
body #main #blocworx-messages .floating-message-line .message-container.success {
  background: green;
}
body #main #blocworx-messages .floating-message-line .message-container.error {
  background: #812121;
}
body #main #blocworx-messages .floating-message-line .message-container.info {
  background: #204a8e;
}
body #main #blocworx-messages .floating-message-line .message-container.warning {
  background: #756e15;
}
body #main #blocworx-messages .floating-message-line .message-container .close {
  float: right;
  font-size: 1.5em;
  font-weight: 700;
  line-height: 1;
  color: #000;
  opacity: 0.5;
  height: 100%;
  top: 30px;
  position: fixed;
  right: 10px;
}
body #main #blocworx-messages .floating-message-line .message-container .close:hover {
  color: #fff;
  text-decoration: none;
}
body #main #blocworx-messages .floating-message-line .message-container.hide {
  -webkit-transform: translateY(80px);
  -moz-transform: translateY(80px);
  -ms-transform: translateY(80px);
  -o-transform: translateY(80px);
  transform: translateY(80px);
  opacity: 0;
}
body #main #blocworx-messages .floating-message-line .message-container.show {
  -webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  -ms-transform: translateY(0px);
  -o-transform: translateY(0px);
  transform: translateY(0px);
  opacity: 1;
}
body #main #pageContent lookup .noResultsFound {
  display: inline-block;
  margin: 20px;
}
body #main #pageContent lookup .for-data-look-up {
  padding: 0px;
  box-sizing: border-box;
}
body #main #pageContent lookup .for-data-look-up button {
  float: left;
  padding: 7px 17px;
  font-size: 13px;
}
body #main #pageContent lookup .for-data-look-up td, body #main #pageContent lookup .for-data-look-up th {
  word-wrap: break-word;
  border: none;
  vertical-align: middle;
}
body #main #pageContent lookup .for-data-look-up .stations-result:nth-child(even) {
  margin-top: 20px;
}
body #main #pageContent lookup .for-data-look-up .stations-result .results-title {
  width: 100%;
}
body #main #pageContent lookup .standard-box {
  margin: 10px auto;
}
body #main #pageContent lookup .please-wait {
  display: inline-block;
  margin: 20px;
}
body #main div.skin-setting-dark #pageContent lookup .refine-search {
  color: #004854;
}
body #main div.skin-setting-dark #pageContent lookup h1, body #main div.skin-setting-dark #pageContent lookup h2, body #main div.skin-setting-dark #pageContent lookup h3, body #main div.skin-setting-dark #pageContent lookup h4, body #main div.skin-setting-dark #pageContent lookup p, body #main div.skin-setting-dark #pageContent lookup span {
  color: white;
}
body #main div.skin-setting-bright #pageContent lookup .refine-search {
  color: #004854;
}
body #main div.skin-setting-bright #pageContent lookup h1, body #main div.skin-setting-bright #pageContent lookup h2, body #main div.skin-setting-bright #pageContent lookup h3, body #main div.skin-setting-bright #pageContent lookup h4, body #main div.skin-setting-bright #pageContent lookup p, body #main div.skin-setting-bright #pageContent lookup span {
  color: #004854;
}

body.dark .embedded-close-container, body.dark .embedded-submit-container {
  border-color: #fff;
}
body.bright .embedded-close-container, body.bright .embedded-submit-container {
  border-color: #004854;
}
body #grid-form-station .embedded-close-container {
  border-top: 2px dashed;
  padding-top: 6px !important;
  height: 27px;
  width: 100%;
  margin-bottom: -11px;
  z-index: 1;
  position: relative;
}
body #grid-form-station .embedded-submit-container {
  border-bottom: 2px dashed;
  height: 66px;
  width: 100%;
  margin-bottom: 14px;
  z-index: 1;
  position: relative;
}
body #grid-form-station .not-usable {
  pointer-events: none;
  user-select: none;
  tabindex: -1;
  opacity: 0.4;
}
body #grid-form-station .close-embed-row i {
  position: absolute;
  right: 20px;
  font-size: 30px;
  cursor: pointer;
}
body #grid-form-station .grid-popup {
  position: fixed;
  max-height: 600px;
  z-index: 99999999;
  top: 10%;
  left: 10%;
  width: 80% !important;
  overflow: auto;
  padding-bottom: 20px;
  height: 80%;
}
body #grid-form-station .grid-popup .main {
  padding-bottom: 30px;
  height: 100%;
  overflow-x: hidden;
}
body #grid-form-station .grid-popup .box-title i {
  position: absolute;
  right: 20px;
  font-size: 30px;
  cursor: pointer;
}
body #grid-form-station .grid-pop-up-background {
  position: fixed;
  height: 100%;
  z-index: 111;
  background: rgba(0, 0, 0, 0.6);
  width: 100%;
  left: 0px;
  top: 0px;
  background-opacity: 0.5;
}
body #grid-form-station .row {
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0px;
  background: none;
  margin-left: 0px;
  margin-right: 0px;
}
body #grid-form-station .scanStationForm {
  width: 100%;
  height: auto !important;
}
body .scan-form-edit #grid-form-station .resize-grid-popup {
  position: absolute;
  z-index: 1000;
  width: 180px;
  padding: 10px;
  background: #455052;
  border: 1px solid #ddd;
  right: 0px;
  top: 36px;
}
body .scan-form-edit #grid-form-station .row {
  border-bottom: 1px dashed #7a7a7a;
  border-left: 1px dashed #7a7a7a;
  height: 100%;
  width: 100%;
}
body .scan-form-edit #grid-form-station .container .row > div {
  border-right: 1px dashed #7a7a7a;
}
body .scan-form-edit #grid-form-station .container-fluid:first-child .row {
  border-top: 1px dashed #7a7a7a;
}
body .scan-form-edit #grid-form-station .scan-station-edit-add .row > div {
  border-right: 1px dashed #7a7a7a;
}
body .show-element-borders-1 #grid-form-station .row {
  border-right: 1px dashed #7a7a7a;
  border-bottom: 1px dashed #7a7a7a;
  border-left: 1px dashed #7a7a7a;
}
body .show-element-borders-1 #grid-form-station .container .row > div {
  border-right: 1px dashed #7a7a7a;
}
body .show-element-borders-1 #grid-form-station .container-fluid:first-child .row {
  border-top: 1px dashed #7a7a7a;
}
body .standard-box.scan-form .container-fluid > .row > .col-md-12 {
  padding: 0px;
}
body .scan-form-edit .container-fluid .row {
  min-height: 20px;
}
body .add-new-item.ng-scope {
  background: #eaeaea;
  border: 1px solid rgba(69, 80, 82, 0.5215686275);
  width: 180px;
  padding: 10px;
}
body .skin-setting-dark .scan-form-edit #grid-form-station .container .row .add-new-item.ng-scope {
  background: #374244;
  border: 1px solid #ddd;
}
@media only screen and (max-width: 976px) {
  body .add-new-item.ng-scope {
    width: 100%;
    position: relative;
    left: 0 !important;
  }
}
body .add-new-item .select-width {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-content: center;
  flex: 1 1 auto;
}
body .add-new-item .select-width label {
  font-size: 13px;
  display: flex;
  margin: 0;
  padding: 0;
  float: none;
  width: 90%;
  word-break: break-word;
}
body .add-new-item .select-width .close-rules {
  font-size: 18px;
  display: flex;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 10px;
  top: 4px;
}
body .child-element-add-field, body .child-element-add-item {
  font-size: 12px;
  padding: 5px 12px;
  margin-bottom: 10px;
}
body .child-element:hover .delete.child-element-add-item, body .full-row-delete-reorder:hover .delete.child-element-add-item {
  opacity: 1;
}
body .new-item-plus {
  min-height: 84px;
}
body .child-element-add-field {
  position: absolute;
  right: 0px;
  bottom: 0px;
  top: auto;
  margin-bottom: 0px;
  z-index: 998;
  max-width: 85%;
  padding: 2px 0px 2px 10px;
}
body .grid-fields {
  display: inline-block;
  width: 100%;
  margin: 0px 0px 2px 0px;
}
body .scan-form-edit .container-fluid .row {
  min-height: 84px;
}
body .customised-layout-0 .new-item-plus {
  max-width: 16.6% !important;
  flex: 0 0 23.333%;
}
body .customised-layout-0 .add-new-item {
  width: 50% !important;
}
body .child-id {
  position: absolute;
  right: 0px;
}
body .customised-layout-0 .full-row-delete-reorder.col-md-11 {
  flex: 0 0 77.667%;
}
body .new-item-plus .fa.fa-plus {
  border: none;
  padding: 9px !important;
  cursor: pointer;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  text-align: center;
  padding-top: 30px;
}
body .new-item-plus .fa.fa-plus::before {
  position: absolute;
  top: 50%;
  margin-top: -10px;
  left: 50%;
  margin-left: -6px;
}
body .full-row-delete-reorder {
  position: absolute;
  right: 0px;
  width: 65px;
  background: #374244;
  box-sizing: border-box;
  padding: 10px;
  border-radius: 10px;
  opacity: 0;
  border: none;
  max-width: none !important;
  border: none !important;
  height: 100%;
}
body .full-row-delete-reorder {
  display: flex;
  align-items: stretch;
  flex-wrap: nowrap;
  flex-direction: column;
  align-content: stretch;
  justify-content: center;
  right: 0px;
  bottom: 0px;
  height: 40px;
  width: 110px;
}
body .reorder-grid-row {
  float: left;
  width: 100%;
  text-align: center;
  top: 15px;
  position: absolute;
  width: 110px;
  left: 0px;
}
body .reorder-grid-row i {
  float: left;
}
body .reorder-grid-row i + i {
  float: right;
}
body .full-row-delete-reorder .delete.child-element-add-item {
  float: left;
  border: none;
  padding: 5px;
  margin-top: 6px;
  font-size: 9px;
  box-sizing: border-box;
  width: 70% !important;
  z-index: 998;
}
body .row:hover .full-row-delete-reorder {
  opacity: 1;
}
body .grid-add-new-row {
  margin: 20px 12px 10px 10px;
}
body .child-element:hover .resize-element, body .child-element:hover .child-element-add-field, body .row-blocworx:hover .resize-element, body .row-blocworx:hover .child-element-add-field {
  opacity: 1;
}
body .child-element-add-field:hover {
  left: 0px;
  margin: 0px;
  display: inline-block;
}

@media only screen and (max-width: 976px) {
  .outer-container-edit-field:hover .edit-field-popup-on-hover {
    left: 0px;
    top: 4px;
    width: 100%;
    height: auto;
    padding: 10px;
    margin: 0px;
    display: inline-flex;
    z-index: 999;
    float: none;
    position: relative;
  }
  .outer-container-edit-field:hover .edit-field-popup-on-hover .invisible-wrap {
    width: 100%;
  }
}
.child-element-add-field:hover {
  font-weight: bold;
}
@media only screen and (max-width: 976px) {
  .child-element-add-field:hover {
    font-weight: bold;
    float: none;
    left: -10px;
    width: 100%;
  }
}

body #main #pageContent article .parent .content .row:hover grid-delete-row .delete-row-container {
  display: flex;
  flex-direction: row;
  position: absolute;
  bottom: 0;
  right: 0;
  min-width: 30px;
  max-width: 130px;
  flex-wrap: wrap;
  align-content: stretch;
  justify-content: center;
  align-items: center;
  height: 50px;
}
body #main #pageContent article .parent .content .row:hover grid-delete-row .delete-row-left {
  flex: 1 1 80%;
  display: flex;
  width: 110px;
  align-items: center;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  height: 30px;
}
body #main #pageContent article .parent .content .row:hover grid-delete-row .delete-row-right {
  flex: 1 1 20%;
  display: flex;
  flex-direction: column;
  width: 20px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
body #main #pageContent article .parent .content .row:hover grid-delete-row .delete-row-right i {
  cursor: pointer;
}
body #main #pageContent article .parent .content .row:hover grid-delete-row button {
  width: 100px;
  display: flex;
  font-size: 11px;
  align-items: center;
  align-content: center;
  flex-direction: row;
  justify-content: center;
  flex-wrap: nowrap;
  margin: 0;
}
body #main #pageContent article .parent .content grid-delete-row .delete-row-container {
  display: none;
}
body #main #pageContent article .parent .content grid-delete-row .delete-row-left {
  display: none;
}
body #main #pageContent article .parent .content grid-delete-row .delete-row-right {
  display: none;
}
body #main #pageContent article .parent .content grid-delete-row button {
  display: none;
}
#grid-semaphore {
  float: right;
  color: #004854;
}
#grid-semaphore span {
  float: left;
  margin-top: 0px;
  font-size: 19px;
  font-weight: 500;
}
#grid-semaphore .grid-semaphore-button {
  width: 53px;
  height: 26px;
  border: 1px solid #fff;
  float: right;
  margin-left: 10px;
  border-radius: 9px;
  position: relative;
  box-sizing: border-box;
  padding: 2px;
  cursor: pointer;
  background: #4d7c84;
}
#grid-semaphore .grid-semaphore-button div {
  float: left;
  width: 46%;
  height: 100%;
  border-radius: 15px;
}
#grid-semaphore .grid-semaphore-button > .gridOn {
  background: #faa21a;
  -webkit-transition: all 0.25s linear 0s;
  -moz-transition: all 0.25s linear 0s;
  -ms-transition: all 0.25s linear 0s;
  -o-transition: all 0.25s linear 0s;
  transition: all 0.25s linear 0s;
}
#grid-semaphore .grid-semaphore-button > .gridOff {
  background: #ee3124;
  -webkit-transform: translateX(119%);
  -moz-transform: translateX(119%);
  -ms-transform: translateX(119%);
  -o-transform: translateX(119%);
  transform: translateX(119%);
}

body #main #pageContent article #scanStationHeader {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 11px 11px 0px 0px;
  display: flex;
  height: 50px;
  margin-bottom: 30px;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: stretch;
  align-items: center;
  justify-content: space-around;
}
body #main #pageContent article #scanStationHeader .header-button {
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  height: 100%;
  align-items: center;
  padding: 0px;
  flex: 1 1 auto;
}
body #main #pageContent article #scanStationHeader .header-title {
  font-size: 14px;
  font-weight: bold;
  display: flex;
  flex: 5 5 auto;
  height: 100%;
  align-items: center;
  padding: 0px 20px;
  justify-content: flex-start;
}
body #main #pageContent article #scanStationHeader .scan-station-header-close {
  cursor: pointer;
  z-index: 1;
  font-size: 30px;
  background: none;
  position: absolute;
  right: 0px;
  height: 50px;
  padding: 0px 20px;
  font-weight: bold;
  vertical-align: middle;
  line-height: 50px;
}
body #main #pageContent article #scanStationHeader .viewMode, body #main #pageContent article #scanStationHeader .editMode {
  order: 1;
}
body #main #pageContent article #scanStationHeader .scan-station-header-edit-data {
  display: flex;
  order: 2;
  flex: 1 1 auto;
  border-radius: 0px;
  background-color: rgba(247, 147, 54, 0.7);
}
body #main #pageContent article #scanStationHeader .scan-station-header-cancel-viewing, body #main #pageContent article #scanStationHeader .scan-station-header-cancel-edit {
  border-radius: 0px 11px 0px 0px;
  background-color: rgba(128, 34, 34, 0.6);
  display: flex;
  order: 3;
  flex: 1 1 auto;
}
body #main #pageContent article #scanStationHeader .viewMode, body #main #pageContent article #scanStationHeader .editMode, body #main #pageContent article #scanStationHeader .scan-station-header-edit-data,
body #main #pageContent article #scanStationHeader .scan-station-header-cancel-edit, body #main #pageContent article #scanStationHeader .scan-station-header-cancel-viewing {
  justify-content: center;
  color: #fff !important;
}
body #main #pageContent article #scanStationHeader .viewMode, body #main #pageContent article #scanStationHeader .editMode, body #main #pageContent article #scanStationHeader .defaultMode {
  border-radius: 11px 0px 0px 0px;
}
body #main #pageContent article .show-more-mode-true #scanStationHeader {
  background-color: rgba(124, 178, 197, 0.7);
}
body #main #pageContent article .edit-mode-true #scanStationHeader {
  background-color: rgba(124, 178, 197, 0.7);
}
body #main #pageContent article .inner-standard-box-wrapper fieldset:disabled input, body #main #pageContent article .inner-standard-box-wrapper fieldset:disabled textarea, body #main #pageContent article .inner-standard-box-wrapper fieldset:disabled button, body #main #pageContent article .inner-standard-box-wrapper fieldset:disabled select {
  color: #fff !important;
  opacity: 1 !important;
  background: #707070 !important;
  cursor: default;
}
body #main #pageContent article .inner-standard-box-wrapper fieldset:disabled button.selected {
  border-color: #303030 !important;
  background: #565656 !important;
}
body #main div.skin-setting-dark #pageContent article #scanStationHeader .scan-station-header-close {
  color: #ffffff;
}
body #main div.skin-setting-dark #pageContent article .inner-standard-box-wrapper fieldset:disabled button:not(.selected):hover {
  border-color: #455052 !important;
}
body #main div.skin-setting-bright #pageContent article #scanStationHeader .scan-station-header-close {
  color: #004854;
}
body #main div.skin-setting-bright #pageContent article .inner-standard-box-wrapper fieldset:disabled button:not(.selected):hover {
  border-color: #343a40 !important;
}

@media (min-width: 1921px) and (max-width: 2570px) {
  body #main #pageContent article .parent, #body #main #pageContent article .parent {
    max-width: 2531px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  body.skin-setting-bright .customised-layout-0 #scanStationHeader .scan-station-header-edit-data, body.skin-setting-bright .customised-layout-0 #scanStationHeader .scan-station-header-cancel-viewing, body.skin-setting-dark .customised-layout-0 #scanStationHeader .scan-station-header-edit-data, body.skin-setting-dark .customised-layout-0 #scanStationHeader .scan-station-header-cancel-viewing {
    width: 50%;
    padding: 0 8px;
    font-size: 12px;
  }
  body.skin-setting-bright .customised-layout-0 #scanStationHeader .scan-station-header-cancel-edit, body.skin-setting-dark .customised-layout-0 #scanStationHeader .scan-station-header-cancel-edit {
    font-size: 12px;
  }
  body.skin-setting-bright .customised-layout-0 #scanStationHeader .box-title .editMode, body.skin-setting-bright .customised-layout-0 #scanStationHeader .box-title .header-title, body.skin-setting-dark .customised-layout-0 #scanStationHeader .box-title .editMode, body.skin-setting-dark .customised-layout-0 #scanStationHeader .box-title .header-title {
    display: none;
  }
}
@media (min-width: 481px) and (max-width: 767px) {
  body #main #pageContent article #scanStationHeader .scan-station-header-edit-data, body #main #pageContent article #scanStationHeader .scan-station-header-cancel-viewing, body #main #pageContent article #scanStationHeader .header-title {
    font-size: 14px;
    padding: 0px 20px;
  }
}
@media (min-width: 320px) and (max-width: 480px) {
  body #main #pageContent article #scanStationHeader .header-title {
    font-size: 12px;
    padding: 0px 5px;
  }
  body #main #pageContent article #scanStationHeader .defaultMode {
    font-size: 14px;
    padding: 0px 20px;
  }
}
@media (max-width: 979px) {
  body #main #pageContent .customised-layout-1 .error-box.top-box {
    display: none;
  }
}
#body #main #pageContent article .table-container {
  margin: 1em auto;
  width: 100%;
  border-collapse: collapse;
  display: inline-block;
  overflow: auto;
}
#body #main #pageContent article .table-container td {
  padding: 0.5em;
}
#body #main #pageContent article .table-container th {
  padding: 0.5em;
}
#body #main #pageContent article .table-container .flex-show-more {
  text-align: center;
  max-width: 0.1%;
}
#body #main #pageContent article .table-container .edit-data-link {
  text-align: center;
  max-width: 0.1%;
}
#body #main #pageContent article .table-container .export-column-header {
  text-align: center;
  max-width: 0.2%;
}
#body #main #pageContent article .table-container .edit-header {
  text-align: center;
  max-width: 0.1%;
}
#body #main #pageContent article .table-container .edit {
  text-align: center;
  max-width: 0.1%;
}
#body #main #pageContent article .table-container .export-column-data {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  align-content: space-between;
}
#body #main #pageContent article .table-container .export-column-data .invisible-wrap {
  margin-bottom: 0px;
}
#body #main #pageContent article .table-container .export-column-data in-data-export-button, #body #main #pageContent article .table-container .export-column-data template-exporter {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
  flex-direction: row;
}
#body #main #pageContent article .table-container tbody tr {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
}
#body #main #pageContent article .flex-row {
  width: 1%;
}
#body #main #pageContent article .flex-row,
#body #main #pageContent article .flex-cell {
  text-align: left;
}
@media only screen and (max-width: 767px) {
  #body #main #pageContent article .flex-row {
    width: 100%;
  }
  #body #main #pageContent article .table-container th,
  #body #main #pageContent article .table-container td {
    border: 0;
  }
}
#body #main #pageContent article .is-in-iframe.compact-view .form-results-section.table td, #body #main #pageContent article .is-in-iframe.compact-view .form-results-section.table th {
  padding: 2px;
  font-size: 12px;
  font-weight: 400;
}
#body #main #pageContent article .form-results-section {
  margin-top: 20px;
}
#body #main #pageContent article .form-results-section td, #body #main #pageContent article .form-results-section th {
  vertical-align: middle;
}
#body #main #pageContent article .form-results-section .graph {
  text-align: center;
  width: 200px;
}
#body #main #pageContent article .form-results-section .actions {
  text-align: center;
  max-width: 100px;
  width: 100px;
  padding: 0;
}
#body #main #pageContent article .form-results-section .actions a {
  cursor: pointer;
}
#body #main #pageContent article .form-results-section.compact-view-1 {
  overflow-x: scroll;
}
#body #main #pageContent article .form-results-section.compact-view-1 td, #body #main #pageContent article .form-results-section.compact-view-1 th {
  padding: 2px;
  font-size: 12px;
  font-weight: 400;
}
#body #main #pageContent article .form-results-section.custom-full {
  overflow-x: scroll;
  max-width: 1574px;
}
#body #main #pageContent article .form-results-section.word-break-true td, #body #main #pageContent article .form-results-section.word-break-true th {
  word-break: break-all;
}
#body #main #pageContent article .scan-results {
  margin-bottom: 15px;
}
#body #main #pageContent article #scan-station-data-field-edit .manage-field-results-order {
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  align-content: space-around;
  flex-wrap: wrap;
  justify-content: center;
  border: 1px dashed rgba(0, 0, 0, 0.168627451);
}
#body #main #pageContent article #scan-station-data-field-edit .manage-field-results-order div {
  display: flex;
  justify-content: center;
  text-align: center;
}
#body #main #pageContent article #scan-station-data-field-edit .manage-field-results-order div label {
  margin: 0px;
}
#body #main #pageContent article #scan-station-data-field-edit .manage-field-results-order .scan-station-data-elements {
  width: 100%;
  min-height: 70px;
}
#body #main #pageContent article #scan-station-data-field-edit .manage-field-results-order .scan-station-data-elements div {
  float: none;
}
#body #main #pageContent article #scan-station-data-field-edit .manage-field-results-order .scan-station-data-elements select {
  margin: 0px;
}
#body #main #pageContent article .accordian .inner-standard-box-wrapper .button-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
}
#body #main #pageContent article .accordian .inner-standard-box-wrapper .button-container input {
  margin-bottom: 20px;
}
#body #main #pageContent article .accordian .inner-standard-box-wrapper .button-container button {
  min-height: 50px;
}
#body #main div.skin-setting-dark #pageContent article .form-results-section::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
#body #main div.skin-setting-dark #pageContent article .form-results-section::-webkit-scrollbar-thumb {
  background: #142124;
}
#body #main div.skin-setting-dark #pageContent article .form-results-section::-webkit-scrollbar-track {
  background: #455052;
}
#body #main div.skin-setting-dark #pageContent article .form-results-section {
  scrollbar-face-color: #142124;
  scrollbar-track-color: #455052;
}
#body #main div.skin-setting-dark #pageContent article #scan-station-data-field-edit .manage-field-results-order .field-order-header {
  background-color: #455052;
}
#body #main div.skin-setting-bright #pageContent article .form-results-section::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
#body #main div.skin-setting-bright #pageContent article .form-results-section::-webkit-scrollbar-thumb {
  background: #343a40;
}
#body #main div.skin-setting-bright #pageContent article .form-results-section::-webkit-scrollbar-track {
  background: #eaeaea;
}
#body #main div.skin-setting-bright #pageContent article .form-results-section {
  scrollbar-face-color: #343a40;
  scrollbar-track-color: #eaeaea;
}
#body #main div.skin-setting-bright #pageContent article #scan-station-data-field-edit .manage-field-results-order .field-order-header {
  background-color: #eaeaea;
}

@media only screen and (max-width: 367px) {
  #body #main #pageContent .selected-icon-display {
    margin-top: 20px;
  }
  #body #main #pageContent .customised-layout-1 input {
    margin-bottom: 10px;
  }
  #body #main #pageContent article #grid-form-station #grid-add-new-row {
    width: 100%;
    margin: 10px auto;
    float: none;
  }
  #body #main #pageContent article .accordian button {
    width: 100%;
    margin: 10px auto;
    float: none;
    min-height: 50px;
  }
  #body #main #pageContent article .form-results-section.custom-compact {
    max-width: 310px;
  }
  #body #main #pageContent article .form-results-section.custom-full {
    overflow-x: scroll;
    max-width: 280px;
  }
  #body #main #pageContent article #scan-station-data-field-edit .manage-field-results-order .field-order-header, #body #main #pageContent article #scan-station-data-field-edit .manage-field-results-order .scan-station-data-elements {
    flex-direction: column;
    padding: 6px;
  }
  #body #main #pageContent article #scan-station-data-field-edit .manage-field-results-order .field-order-header div {
    padding: 2px;
  }
  #body #main #pageContent article #scan-station-data-field-edit .manage-field-results-order .scan-station-data-elements div {
    padding: 2px;
  }
  #body #main #pageContent article #scan-station-data-field-edit .manage-field-results-order .scan-station-data-elements table-order {
    flex-direction: column;
    display: flex;
    width: 100%;
  }
  #body #main #pageContent article #scan-station-data-field-edit .manage-field-results-order .scan-station-data-elements .single-checkbox-parameter {
    padding: 0px;
  }
  #body #main div.skin-setting-dark #pageContent article #scan-station-data-field-edit .manage-field-results-order .scan-station-data-elements:nth-child(odd) {
    background-color: #394244;
  }
  #body #main div.skin-setting-dark #pageContent article #scan-station-data-field-edit .manage-field-results-order .scan-station-data-elements:nth-child(even) {
    background-color: #515e60;
  }
  #body #main div.skin-setting-bright #pageContent article #scan-station-data-field-edit .manage-field-results-order .scan-station-data-elements:nth-child(odd) {
    background-color: #dddddd;
  }
  #body #main div.skin-setting-bright #pageContent article #scan-station-data-field-edit .manage-field-results-order .scan-station-data-elements:nth-child(even) {
    background-color: #f7f7f7;
  }
}
@media only screen and (min-width: 367px) and (max-width: 767px) {
  #body #main #pageContent .selected-icon-display {
    margin-top: 20px;
  }
  #body #main #pageContent .customised-layout-1 input {
    margin-bottom: 10px;
  }
  #body #main #pageContent article #grid-form-station #grid-add-new-row {
    width: 100%;
    margin: 10px auto;
    float: none;
  }
  #body #main #pageContent article .accordian button {
    width: 100%;
    margin: 10px auto;
    float: none;
    min-height: 50px;
  }
  #body #main #pageContent article .form-results-section.custom-compact {
    max-width: 705px;
  }
  #body #main #pageContent article .form-results-section.custom-full {
    overflow-x: scroll;
    max-width: 660px;
  }
  #body #main #pageContent article #scan-station-data-field-edit .manage-field-results-order .field-order-header, #body #main #pageContent article #scan-station-data-field-edit .manage-field-results-order .scan-station-data-elements {
    flex-direction: column;
    padding: 6px;
  }
  #body #main #pageContent article #scan-station-data-field-edit .manage-field-results-order .field-order-header {
    margin-bottom: 0px;
  }
  #body #main #pageContent article #scan-station-data-field-edit .manage-field-results-order .field-order-header div {
    padding: 2px;
  }
  #body #main #pageContent article #scan-station-data-field-edit .manage-field-results-order .scan-station-data-elements div {
    padding: 2px;
  }
  #body #main #pageContent article #scan-station-data-field-edit .manage-field-results-order .scan-station-data-elements table-order {
    flex-direction: column;
    display: flex;
    width: 100%;
  }
  #body #main #pageContent article #scan-station-data-field-edit .manage-field-results-order .scan-station-data-elements .single-checkbox-parameter {
    padding: 0px;
  }
  #body #main div.skin-setting-dark #pageContent article #scan-station-data-field-edit .manage-field-results-order .scan-station-data-elements:nth-child(odd) {
    background-color: #394244;
  }
  #body #main div.skin-setting-dark #pageContent article #scan-station-data-field-edit .manage-field-results-order .scan-station-data-elements:nth-child(even) {
    background-color: #515e60;
  }
  #body #main div.skin-setting-bright #pageContent article #scan-station-data-field-edit .manage-field-results-order .scan-station-data-elements:nth-child(odd) {
    background-color: #dddddd;
  }
  #body #main div.skin-setting-bright #pageContent article #scan-station-data-field-edit .manage-field-results-order .scan-station-data-elements:nth-child(even) {
    background-color: #f7f7f7;
  }
}
@media only screen and (min-width: 768px) and (max-width: 979px) {
  #body #main #pageContent article .form-results-section.custom-compact {
    max-width: 870px;
  }
  #body #main #pageContent article .form-results-section.custom-full {
    overflow-x: scroll;
    max-width: 1784px;
  }
  #body #main #pageContent article .accordian .inner-standard-box-wrapper .one-half-block.first {
    margin-right: 11px !important;
  }
  #body #main #pageContent article .accordian .inner-standard-box-wrapper .one-half-block.last {
    margin-left: 11px !important;
  }
  #body #main #pageContent article .accordian .inner-standard-box-wrapper .bloc-setting-panel button {
    width: 100%;
    margin: 5px auto;
  }
  #body #main #pageContent article .accordian .data-view-results-to-appear {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: stretch;
  }
  #body #main #pageContent article .accordian .data-view-results-to-appear .one-quarter-block {
    padding: 10px;
    margin: 7px !important;
  }
}
@media (min-width: 979px) and (max-width: 1025px) {
  #body #main #pageContent article .form-results-section.custom-compact {
    max-width: 496px;
  }
  #body #main #pageContent article .form-results-section.custom-full {
    overflow-x: scroll;
    max-width: 1784px;
  }
}
@media (min-width: 1025px) and (max-width: 1340px) {
  #body #main #pageContent article .form-results-section.custom-compact {
    max-width: 708px;
    max-width: 1017px;
  }
  #body #main #pageContent article .form-results-section.custom-full {
    overflow-x: scroll;
    max-width: 1574px;
  }
}
@media (min-width: 1341px) and (max-width: 1920px) {
  #body #main #pageContent article .form-results-section.custom-full {
    overflow-x: scroll;
    max-width: 1585px;
  }
}
@media (min-width: 1921px) {
  #body #main #pageContent article .form-results-section.custom-compact {
    max-width: 708px;
    max-width: 1017px;
  }
  #body #main #pageContent article .form-results-section.custom-full {
    overflow-x: scroll;
    max-width: 100%;
  }
}
#body #main #pageContent article scan-station-results .field-count-box * {
  overflow-wrap: anywhere;
}
#body #main #pageContent article scan-station-results .field-count-box h4 {
  font-size: 22px;
}
@media only screen and (max-width: 367px) {
  #body #main #pageContent article scan-station-results .field-count-box h4 {
    font-size: 18px;
  }
}
@media only screen and (min-width: 367px) and (max-width: 767px) {
  #body #main #pageContent article scan-station-results .field-count-box h4 {
    font-size: 18px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 979px) {
  #body #main #pageContent article scan-station-results .field-count-box h4 {
    font-size: 22px;
  }
}
@media (min-width: 979px) and (max-width: 1024px) {
  #body #main #pageContent article scan-station-results .field-count-box h4 {
    font-size: 22px;
  }
}
@media (min-width: 1025px) and (max-width: 1340px) {
  #body #main #pageContent article scan-station-results .field-count-box h4 {
    font-size: 22px;
  }
}
#scanStationForm .mainFieldset {
  display: inline-block;
  width: 100%;
}

body #main .close-popup {
  font-size: 28px;
  color: #fff;
  position: absolute;
  right: 20px;
  top: 20px;
  line-height: 1;
  cursor: pointer;
}
body #main .field-rules-close-popup {
  margin-top: -40px;
  width: 25px !important;
  font-size: 28px;
  position: sticky;
  right: 20px;
  left: 96%;
  top: -23px;
  z-index: 1;
  line-height: 1;
  cursor: pointer;
}
body #main .close-popup:hover {
  color: #f65d5d;
}
body #main #pageContent article .new-edit-field-parameters {
  background: #374244;
  float: left;
  width: 100%;
  border-radius: 11px 11px 0px 0px;
}
body #main #pageContent article .new-edit-field-parameters .checkbox-text-right {
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  width: 100%;
  justify-content: flex-start;
}
body #main #pageContent article .new-edit-field-parameters .checkbox-text-right input {
  float: none;
  clear: none;
  margin: 0px 10px 0px 0px;
  flex: 1 1 auto;
}
body #main #pageContent article .new-edit-field-parameters .checkbox-text-right span {
  flex: 1 1 100%;
}
body #main #pageContent article .new-edit-field-parameters .inner-standard-box-wrapper {
  margin-left: 0px;
  padding-left: 0px;
  margin-top: 6px;
}
body #main #pageContent article .new-edit-field-parameters .box-title {
  padding: 0px;
}
body #main #pageContent article .new-edit-field-parameters > *:not(.box-title) {
  padding: 0px 20px;
}
body #main #pageContent article .standard-box {
  float: left;
  background: #eaeaea;
  width: 100%;
  box-sizing: border-box;
  border-radius: 11px;
}
body #main #pageContent article .standard-box .box-title {
  color: #fff;
  width: 100%;
  float: left;
  box-sizing: border-box;
  padding: 10px 20px;
  /*margin: 10px 0px 10px 0px;*/
  font-size: 20.5px;
  font-weight: 600;
  border-radius: 11px 11px 0px 0px;
}
body #main #pageContent article .standard-box.my-active-jobs {
  padding-bottom: 18px;
}
body #main #pageContent article .standard-box-show-more {
  height: 100%;
  top: 10px;
  padding-bottom: 10px !important;
}
body #main #pageContent article .margin-box {
  margin: 10px 0px;
}
body #main #pageContent article .data-tracking-fields {
  position: fixed;
  z-index: 999999;
  top: 2%;
  left: 2%;
  width: 95%;
  height: 95%;
  border-radius: 11px 11px 0px 0px;
}
body #main #pageContent article .data-tracking-fields .close-rules-scan-station-edit {
  position: absolute;
}
body #main #pageContent article .inner-standard-box-wrapper {
  float: left;
  width: 100%;
  box-sizing: border-box;
  padding: 14px;
}
body #main #pageContent article .invisible-wrap.first {
  margin-left: 0px !important;
}
body #main #pageContent article .invisible-wrap.last {
  margin-left: 0px !important;
  margin-right: 0px !important;
}
body #main #pageContent article .invisible-wrap.two-thirds-block {
  width: 67.5%;
}
body #main #pageContent article .invisible-wrap.one-quarter-block {
  width: 22%;
}
body #main #pageContent article .invisible-wrap.one-fifth-block {
  width: 20%;
}
body #main #pageContent article .invisible-wrap.two-fifths-block {
  width: 40%;
}
body #main #pageContent article .invisible-wrap.three-fifths-block {
  width: 60%;
}
body #main #pageContent article .invisible-wrap.four-fifths-block {
  width: 80%;
}
body #main #pageContent article .invisible-wrap.three-quarters-block {
  width: 75%;
}
body #main #pageContent article .invisible-wrap.one-half-block {
  width: 48.4%;
}
body #main #pageContent article .invisible-wrap.one-third-block {
  width: 31%;
}
body #main #pageContent article .invisible-wrap.one-forth-block {
  width: 25%;
}
body #main #pageContent article .invisible-wrap.last + .invisible-wrap.first {
  clear: both;
  margin-top: 28px;
}
body #main #pageContent article .invisible-wrap.full-size-block-alert {
  position: fixed;
  z-index: 99999999;
  left: 5%;
  width: 90%;
  height: 90%;
  box-sizing: border-box;
  top: 1%;
  padding-bottom: 0px;
  overflow: hidden;
  margin: 0px auto;
  float: left;
}
body #main #pageContent article .invisible-wrap.full-size-block {
  margin: 10px 0px;
  float: left;
  width: 100%;
}
body #main #pageContent article .invisible-wrap.full-size-block.job-list-container {
  margin-bottom: 31px;
}
body #main #pageContent article .invisible-wrap .loading-view {
  width: 100%;
  float: left;
  margin: 0px 0px 30px 0px;
  font-size: 18px;
  position: absolute;
  height: 100%;
  background: rgba(255, 255, 255, 0.3);
}
body #main #pageContent article .invisible-wrap .loading-view img {
  margin-top: 30px;
}
body #main #pageContent article .overflow-container {
  overflow-y: auto;
  height: 100%;
  width: 100%;
}
body #main #pageContent article .left-float {
  float: left;
}
body #main #pageContent .modal-scrollable {
  overflow-y: auto;
  max-height: 89.7vh;
}
body #main #pageContent .data-tracking-fields.edit-mode-true > div > div > div.close-popup {
  right: 40px;
  width: 30px;
  margin-top: -50px;
  z-index: 1;
  position: absolute;
  top: 58px;
}
body #main #pageContent .data-tracking-fields.edit-mode-true > div > div > div.close-popup {
  right: 40px;
  width: 30px;
  margin-top: -50px;
  z-index: 1;
  position: absolute;
  top: 58px;
}
body #main #pageContent .tick-to-show-hidden-fields {
  float: right;
  margin: 0px 16px 20px 0px;
  width: 232px;
}
body #main div.skin-setting-dark #pageContent article .standard-box {
  background: #455052;
  padding: 0px;
}
body #main div.skin-setting-dark #pageContent article .standard-box .box-title {
  color: white;
  background-color: rgba(52, 58, 64, 0.8117647059);
}
body #main div.skin-setting-dark #pageContent article .standard-box .close-popup {
  background-color: rgba(52, 58, 64, 0.8117647059);
}
body #main div.skin-setting-dark #pageContent article .standard-box .bloc-setting-panel .box-title {
  color: #f0f4f5;
  background: inherit;
}
body #main div.skin-setting-dark #pageContent article .inner-standard-box-wrapper h4 {
  color: #f0f4f5;
}
body #main div.skin-setting-dark #pageContent article .standard-box::-webkit-scrollbar, body #main div.skin-setting-dark #pageContent article .invisible-wrap::-webkit-scrollbar, body #main div.skin-setting-dark #pageContent article .overflow-container::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
body #main div.skin-setting-dark #pageContent article .standard-box::-webkit-scrollbar-thumb, body #main div.skin-setting-dark #pageContent article .invisible-wrap::-webkit-scrollbar-thumb, body #main div.skin-setting-dark #pageContent article .overflow-container::-webkit-scrollbar-thumb {
  background: #455052;
}
body #main div.skin-setting-dark #pageContent article .standard-box::-webkit-scrollbar-track, body #main div.skin-setting-dark #pageContent article .invisible-wrap::-webkit-scrollbar-track, body #main div.skin-setting-dark #pageContent article .overflow-container::-webkit-scrollbar-track {
  background: #232d2f;
}
body #main div.skin-setting-dark #pageContent article .standard-box, body #main div.skin-setting-dark #pageContent article .invisible-wrap, body #main div.skin-setting-dark #pageContent article .overflow-container {
  scrollbar-face-color: #455052;
  scrollbar-track-color: #232d2f;
}
body #main div.skin-setting-dark #pageContent article .modal-scrollable::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
body #main div.skin-setting-dark #pageContent article .modal-scrollable::-webkit-scrollbar-thumb {
  background: #455052;
}
body #main div.skin-setting-dark #pageContent article .modal-scrollable::-webkit-scrollbar-track {
  background: #232d2f;
}
body #main div.skin-setting-dark #pageContent article .modal-scrollable {
  scrollbar-face-color: #455052;
  scrollbar-track-color: #232d2f;
}
body #main div.skin-setting-bright #pageContent article .standard-box {
  background: #eaeaea;
}
body #main div.skin-setting-bright #pageContent article .standard-box .box-title {
  color: white;
  background-color: rgba(69, 80, 82, 0.7098039216);
}
body #main div.skin-setting-bright #pageContent article .standard-box .bloc-setting-panel .box-title {
  color: #004854;
  background: inherit;
}
body #main div.skin-setting-bright #pageContent article .standard-box .bloc-setting-panel .close-popup {
  background-color: rgba(69, 80, 82, 0.7098039216);
}
body #main div.skin-setting-bright #pageContent article .inner-standard-box-wrapper h4 {
  color: #004854;
}
body #main div.skin-setting-bright #pageContent article .standard-box::-webkit-scrollbar, body #main div.skin-setting-bright #pageContent article .invisible-wrap::-webkit-scrollbar, body #main div.skin-setting-bright #pageContent article .overflow-container::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
body #main div.skin-setting-bright #pageContent article .standard-box::-webkit-scrollbar-thumb, body #main div.skin-setting-bright #pageContent article .invisible-wrap::-webkit-scrollbar-thumb, body #main div.skin-setting-bright #pageContent article .overflow-container::-webkit-scrollbar-thumb {
  background: #343a40;
}
body #main div.skin-setting-bright #pageContent article .standard-box::-webkit-scrollbar-track, body #main div.skin-setting-bright #pageContent article .invisible-wrap::-webkit-scrollbar-track, body #main div.skin-setting-bright #pageContent article .overflow-container::-webkit-scrollbar-track {
  background: #eaeaea;
}
body #main div.skin-setting-bright #pageContent article .standard-box, body #main div.skin-setting-bright #pageContent article .invisible-wrap, body #main div.skin-setting-bright #pageContent article .overflow-container {
  scrollbar-face-color: #343a40;
  scrollbar-track-color: #eaeaea;
}
body #main div.skin-setting-bright #pageContent article .modal-scrollable::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
body #main div.skin-setting-bright #pageContent article .modal-scrollable::-webkit-scrollbar-thumb {
  background: #343a40;
}
body #main div.skin-setting-bright #pageContent article .modal-scrollable::-webkit-scrollbar-track {
  background: #eaeaea;
}
body #main div.skin-setting-bright #pageContent article .modal-scrollable {
  scrollbar-face-color: #343a40;
  scrollbar-track-color: #eaeaea;
}

@media only screen and (max-width: 367px) {
  body #main #pageContent article .user-buttons button, #body #main #pageContent article .user-buttons button {
    margin: 0px;
  }
  body #main #pageContent article .form-results-section, #body #main #pageContent article .form-results-section {
    overflow: auto;
    display: inline-block;
  }
  body #main #pageContent article .inner-standard-box-wrapper .invisible-wrap.two-thirds-block, #body #main #pageContent article .inner-standard-box-wrapper .invisible-wrap.two-thirds-block {
    width: 100%;
  }
  body #main #pageContent article .inner-standard-box-wrapper .invisible-wrap.one-quarter-block, #body #main #pageContent article .inner-standard-box-wrapper .invisible-wrap.one-quarter-block {
    width: 100%;
  }
  body #main #pageContent article .inner-standard-box-wrapper .invisible-wrap.one-fifth-block, #body #main #pageContent article .inner-standard-box-wrapper .invisible-wrap.one-fifth-block {
    width: 100%;
  }
  body #main #pageContent article .inner-standard-box-wrapper .invisible-wrap.two-fifths-block, #body #main #pageContent article .inner-standard-box-wrapper .invisible-wrap.two-fifths-block {
    width: 100%;
  }
  body #main #pageContent article .inner-standard-box-wrapper .invisible-wrap.three-fifths-block, #body #main #pageContent article .inner-standard-box-wrapper .invisible-wrap.three-fifths-block {
    width: 100%;
  }
  body #main #pageContent article .inner-standard-box-wrapper .invisible-wrap.four-fifths-block, #body #main #pageContent article .inner-standard-box-wrapper .invisible-wrap.four-fifths-block {
    width: 100%;
  }
  body #main #pageContent article .inner-standard-box-wrapper .invisible-wrap.three-quarters-block, #body #main #pageContent article .inner-standard-box-wrapper .invisible-wrap.three-quarters-block {
    width: 100%;
  }
  body #main #pageContent article .inner-standard-box-wrapper .invisible-wrap.one-half-block, #body #main #pageContent article .inner-standard-box-wrapper .invisible-wrap.one-half-block {
    width: 100%;
  }
  body #main #pageContent article .inner-standard-box-wrapper .invisible-wrap.one-third-block, #body #main #pageContent article .inner-standard-box-wrapper .invisible-wrap.one-third-block {
    width: 100%;
  }
  body #main #pageContent article .inner-standard-box-wrapper .invisible-wrap.one-forth-block, #body #main #pageContent article .inner-standard-box-wrapper .invisible-wrap.one-forth-block {
    width: 100%;
  }
  body #main #pageContent article .inner-standard-box-wrapper .invisible-wrap.full-size-block-alert, #body #main #pageContent article .inner-standard-box-wrapper .invisible-wrap.full-size-block-alert {
    width: 100%;
    height: 100%;
  }
  body #main #pageContent article .inner-standard-box-wrapper .invisible-wrap.full-size-block, #body #main #pageContent article .inner-standard-box-wrapper .invisible-wrap.full-size-block {
    width: 100%;
  }
  body #main #pageContent article .inner-standard-box-wrapper .invisible-wrap .loading-view, #body #main #pageContent article .inner-standard-box-wrapper .invisible-wrap .loading-view {
    width: 100%;
    height: 100%;
  }
  body #main #pageContent article .inner-standard-box-wrapper table, #body #main #pageContent article .inner-standard-box-wrapper table {
    overflow-x: scroll;
    max-width: 400px;
    width: 100%;
    display: inline-block;
  }
}
@media only screen and (min-width: 367px) and (max-width: 767px) {
  body #main #pageContent article .user-buttons button, #body #main #pageContent article .user-buttons button {
    margin: 0px;
  }
  body #main #pageContent article .form-results-section, #body #main #pageContent article .form-results-section {
    overflow: auto;
    display: inline-block;
  }
  body #main #pageContent article .inner-standard-box-wrapper .invisible-wrap.two-thirds-block, #body #main #pageContent article .inner-standard-box-wrapper .invisible-wrap.two-thirds-block {
    width: 100%;
  }
  body #main #pageContent article .inner-standard-box-wrapper .invisible-wrap.one-quarter-block, #body #main #pageContent article .inner-standard-box-wrapper .invisible-wrap.one-quarter-block {
    width: 100%;
  }
  body #main #pageContent article .inner-standard-box-wrapper .invisible-wrap.one-fifth-block, #body #main #pageContent article .inner-standard-box-wrapper .invisible-wrap.one-fifth-block {
    width: 100%;
  }
  body #main #pageContent article .inner-standard-box-wrapper .invisible-wrap.two-fifths-block, #body #main #pageContent article .inner-standard-box-wrapper .invisible-wrap.two-fifths-block {
    width: 100%;
  }
  body #main #pageContent article .inner-standard-box-wrapper .invisible-wrap.three-fifths-block, #body #main #pageContent article .inner-standard-box-wrapper .invisible-wrap.three-fifths-block {
    width: 100%;
  }
  body #main #pageContent article .inner-standard-box-wrapper .invisible-wrap.four-fifths-block, #body #main #pageContent article .inner-standard-box-wrapper .invisible-wrap.four-fifths-block {
    width: 100%;
  }
  body #main #pageContent article .inner-standard-box-wrapper .invisible-wrap.three-quarters-block, #body #main #pageContent article .inner-standard-box-wrapper .invisible-wrap.three-quarters-block {
    width: 100%;
  }
  body #main #pageContent article .inner-standard-box-wrapper .invisible-wrap.one-half-block, #body #main #pageContent article .inner-standard-box-wrapper .invisible-wrap.one-half-block {
    width: 100%;
  }
  body #main #pageContent article .inner-standard-box-wrapper .invisible-wrap.one-third-block, #body #main #pageContent article .inner-standard-box-wrapper .invisible-wrap.one-third-block {
    width: 100%;
  }
  body #main #pageContent article .inner-standard-box-wrapper .invisible-wrap.one-forth-block, #body #main #pageContent article .inner-standard-box-wrapper .invisible-wrap.one-forth-block {
    width: 100%;
  }
  body #main #pageContent article .inner-standard-box-wrapper .invisible-wrap.full-size-block-alert, #body #main #pageContent article .inner-standard-box-wrapper .invisible-wrap.full-size-block-alert {
    width: 100%;
    height: 100%;
  }
  body #main #pageContent article .inner-standard-box-wrapper .invisible-wrap.full-size-block, #body #main #pageContent article .inner-standard-box-wrapper .invisible-wrap.full-size-block {
    width: 100%;
  }
  body #main #pageContent article .inner-standard-box-wrapper .invisible-wrap .loading-view, #body #main #pageContent article .inner-standard-box-wrapper .invisible-wrap .loading-view {
    width: 100%;
    height: 100%;
  }
  body #main #pageContent article .inner-standard-box-wrapper table, #body #main #pageContent article .inner-standard-box-wrapper table {
    overflow-x: scroll;
    max-width: 400px;
    width: 100%;
  }
}
.angularjs-datetime-picker {
  z-index: 9;
}

#body #main #pageContent article #addedFieldRules .rule-name, #body #main #pageContent article #addedFieldRules .rule-order {
  margin-bottom: 4px;
}
#body #main #pageContent article #addedFieldRules .rule-details p {
  margin-bottom: 4px;
  font-size: 16px;
}
#body #main #pageContent article .populate-other-fields-from-response .show-false {
  display: none;
}
#body #main #pageContent article .existing-populating-rule-responses {
  float: left;
  width: 100%;
}
#body #main #pageContent article .rule-details custom_response_print .print span {
  float: left;
  width: 100%;
}
#body #main #pageContent article .edit-rule, #body #main #pageContent article update-rule {
  float: right;
  margin-right: 5px;
  cursor: pointer;
  color: rgba(247, 147, 54, 0.7019607843);
  position: absolute;
  right: 22px;
  top: 3px;
}
#body #main #pageContent article .edit-rule .fa-edit, #body #main #pageContent article update-rule .fa-edit {
  border: none;
}
#body #main #pageContent article .delete-rule, #body #main #pageContent article .cancel-edit-rule {
  float: right;
  margin-right: 5px;
  cursor: pointer;
  color: red;
  position: absolute;
  right: 0px;
  top: 3px;
}
#body #main #pageContent article #superUserDebug {
  border: 10px solid #ff4500;
  padding: 5px 0px 5px 10px;
  margin: 25px 0px 25px 0px;
}
#body #main #pageContent article custom_response_print .custom-response-container {
  padding: 6px 0px 2px 0px;
}
#body #main #pageContent article custom_response_print .custom-response-container .print {
  font-size: 16px;
}
#body #main #pageContent article custom_response_print .custom-response-container .custom-response p {
  margin-top: 8px;
}
#body #main #pageContent article field-rule .print {
  margin: 4px 0px;
  width: 70%;
}
#body #main #pageContent article field-rule .options {
  width: 29%;
}
#body #main #pageContent article field-rule .options .remove {
  width: 80px;
  font-size: 13px;
  padding: 10px;
  background: #8b0000 !important;
  display: inline-block;
}
#body #main #pageContent article field-rule .print, #body #main #pageContent article field-rule .options {
  display: inline-block;
  vertical-align: middle;
  line-height: 24px;
}
#body #main #pageContent article .newrule-form .error-box {
  padding: 11px;
  font-size: 13px;
  margin-top: 20px;
  text-align: left;
}
#body #main #pageContent article .newrule-form .rule-order-input {
  display: none;
}
#body #main #pageContent article .manage-conditional-status .title {
  margin-bottom: 10px;
}
#body #main #pageContent article .manage-conditional-status .last {
  margin-bottom: 0px;
}
@media only screen and (max-width: 479px) {
  #body #main .mobile-off {
    display: none !important;
  }
}
@media only screen and (min-width: 480px) and (max-width: 768px) {
  #body #main .tablet-off {
    display: none !important;
  }
}
@media only screen and (min-width: 769px) and (max-width: 979px) {
  #body #main .tablet-off {
    display: none !important;
  }
}
body #main #pageContent article .parent .content .outer-container-edit-field .edit-field-popup-on-hover {
  position: absolute;
  overflow: hidden;
  display: none;
}
body #main #pageContent article .parent .content .outer-container-edit-field:hover .edit-field-popup-on-hover {
  left: -5px;
  top: 0px;
  width: 100%;
  height: auto;
  padding: 10px 10px;
  margin: 0px;
  display: inline-flex;
  z-index: 999;
  flex-direction: row;
  flex-wrap: wrap;
  float: left;
  max-width: 270px;
  min-width: 156px;
}
body #main #pageContent article .parent .content .outer-container-edit-field:hover .edit-field-popup-on-hover .title, body #main #pageContent article .parent .content .outer-container-edit-field:hover .edit-field-popup-on-hover .elements {
  width: 100%;
}
body #main #pageContent article .parent .content .outer-container-edit-field:hover .edit-field-popup-on-hover .title {
  display: flex;
  flex: 1 1 auto;
  padding: 4px;
  border-radius: 10px;
  margin-bottom: 5px;
  margin-left: 5px;
}
body #main #pageContent article .parent .content .outer-container-edit-field:hover .edit-field-popup-on-hover .title label {
  font-size: 15px;
  font-weight: bold;
  margin: 0px;
  padding: 2px;
}
body #main #pageContent article .parent .content .outer-container-edit-field:hover .edit-field-popup-on-hover .elements {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}
body #main #pageContent article .parent .content .outer-container-edit-field:hover .edit-field-popup-on-hover .elements .arrows {
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  align-content: center;
  justify-content: space-evenly;
}
body #main #pageContent article .parent .content .outer-container-edit-field:hover .edit-field-popup-on-hover .elements .arrows .reorder-field-arrows {
  cursor: pointer;
  position: relative;
  margin: 5px;
}
body #main #pageContent article .parent .content .outer-container-edit-field:hover .edit-field-popup-on-hover .elements .buttons {
  display: flex;
  flex: 1 1 70%;
  flex-direction: column;
}
body #main #pageContent article .parent .content .outer-container-edit-field table.for-preview {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex: 1 1 auto;
  flex-wrap: wrap;
  margin: 0px;
  min-height: 265px;
}
body #main #pageContent article .parent .content .outer-container-edit-field table.for-preview thead, body #main #pageContent article .parent .content .outer-container-edit-field table.for-preview tbody {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1 1 auto;
  flex-wrap: wrap;
  align-items: stretch;
  align-content: stretch;
}
body #main #pageContent article .parent .content .outer-container-edit-field table.for-preview tr {
  display: flex;
  justify-content: space-around;
}
body #main div.skin-setting-dark #pageContent article .parent .content .outer-container-edit-field .edit-field-popup-on-hover .title {
  background-color: rgba(0, 0, 0, 0.0666666667);
}
body #main div.skin-setting-bright #pageContent article .parent .content .outer-container-edit-field .edit-field-popup-on-hover .title {
  background-color: rgba(255, 255, 255, 0.2666666667);
}
.pie-chart-container canvas {
  display: none;
}
.pie-chart-container canvas.chartjs-render-monitor {
  display: inline-block;
}
.pie-chart-container label .pie-chart-semaphore {
  color: inherit;
}
.pie-chart-container label .pie-chart-semaphore .pie-chart-text {
  color: inherit;
}
.pie-chart-container .pie-chart-semaphore {
  float: right;
  color: #004854;
}
.pie-chart-container .pie-chart-semaphore span {
  float: left;
  margin-top: 0px;
  font-size: 19px;
  font-weight: 500;
}
.pie-chart-container .pie-chart-semaphore .pie-chart-semaphore-button {
  width: 53px;
  height: 26px;
  border: 1px solid #fff;
  float: right;
  margin-left: 10px;
  border-radius: 9px;
  position: relative;
  box-sizing: border-box;
  padding: 2px;
  cursor: pointer;
  background: #4d7c84;
}
.pie-chart-container .pie-chart-semaphore .pie-chart-semaphore-button div {
  float: left;
  width: 46%;
  height: 100%;
  border-radius: 15px;
}
.pie-chart-container .pie-chart-semaphore .pie-chart-semaphore-button > .pieChartOn {
  background: #faa21a;
  -webkit-transition: all 0.25s linear 0s;
  -moz-transition: all 0.25s linear 0s;
  -ms-transition: all 0.25s linear 0s;
  -o-transition: all 0.25s linear 0s;
  transition: all 0.25s linear 0s;
}
.pie-chart-container .pie-chart-semaphore .pie-chart-semaphore-button > .pieChartOff {
  background: #ee3124;
  -webkit-transform: translateX(119%);
  -moz-transform: translateX(119%);
  -ms-transform: translateX(119%);
  -o-transform: translateX(119%);
  transform: translateX(119%);
}

.pie-chart-container-print {
  max-width: 200px !important;
  width: 200px !important;
}
body #main .front-page-side .icon-link.for-pages a:not(.delete-page-link)::after {
  position: absolute;
  width: 9px;
  height: 9px;
  border: 2px solid #ffffff;
  right: 25px;
  content: "";
  bottom: 8px;
  opacity: 0.3;
}
body #main .front-page-side .icon-link.for-pages a:not(.delete-page-link) {
  position: relative;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  float: left;
}
body #main .front-page-side .icon-link.for-pages.link-type-url a:not(.delete-page-link)::after {
  position: absolute;
  width: 9px;
  height: 9px;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-right: none;
  border-left: 6px solid #fff;
  right: 25px;
  content: "";
  bottom: 7px;
  opacity: 0.3;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
body #main .front-page-side .icon-link.for-pages.link-type-url a:not(.delete-page-link) {
  position: relative;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  float: left;
}
body #main .front-page-side .icon-link.for-pages.link-type-module a::after {
  display: none;
}
body #main .edit-page-side .icon-link-wrapper.for-pages a:not(.delete-page-link)::after {
  position: absolute;
  width: 9px;
  height: 9px;
  border: 2px solid #ffffff;
  right: 25px;
  content: "";
  bottom: 8px;
  opacity: 0.3;
}
body #main .edit-page-side .icon-link-wrapper.for-pages a:not(.delete-page-link) {
  position: relative;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  float: left;
}
body #main .edit-page-side .icon-link-wrapper.for-pages.link-type-url a:not(.delete-page-link)::after {
  position: absolute;
  width: 9px;
  height: 9px;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-right: none;
  border-left: 6px solid #fff;
  right: 25px;
  content: "";
  bottom: 7px;
  opacity: 0.3;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
body #main .edit-page-side .icon-link-wrapper.for-pages.link-type-url a:not(.delete-page-link) {
  position: relative;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  float: left;
}
body #main .edit-page-side .icon-link-wrapper.for-pages.link-type-module a::after {
  display: none;
}
body #main .single-page-in-list button {
  font-size: 13px;
  padding: 7px 12px;
}
body #main button i {
  border: none;
}
body #main .manage-page-pop-up {
  position: absolute;
  right: 50px;
  width: 357px;
  z-index: 1;
  margin: 10px auto 20px auto;
}
body #main .manage-page-pop-up button {
  float: left;
  margin-top: 10px;
  padding-left: 18px;
  margin-left: 0px;
  font-size: 14px;
  clear: both !important;
  padding-right: 18px;
}
body #main .manage-page-pop-up button.delete {
  float: left;
  margin-top: 10px;
  padding-left: 18px;
  margin-left: 0px;
  font-size: 14px;
  clear: both !important;
  padding-right: 18px;
}
body #main .manage-page-pop-up .invisible-wrap.full-size-block {
  margin-bottom: 0px;
}
body #main .manage-page-pop-up a {
  font-size: 17px;
  font-weight: normal;
  float: left;
  width: 100%;
  margin-top: 8px;
}
body #main .manage-page-pop-up div {
  font-size: 16px;
  float: left;
  color: #fff;
  font-size: 16px;
  font-weight: normal;
}
body #main #pageContent article .edit-pages-template .manage-page-pop-up {
  right: 50px;
  width: 357px;
  z-index: 1;
  float: right;
}
body #main #pageContent article .edit-pages-template .standard-box .titleAndClose {
  display: flex;
  width: 100%;
  align-items: center;
}
body #main #pageContent article .edit-pages-template .standard-box .titleAndClose .box-title {
  border-radius: 0px;
}
body #main #pageContent article .edit-pages-template .standard-box .titleAndClose .close-popup {
  border-radius: 0px 11px 0px 0px;
  font-size: 28px;
  color: #fff;
  cursor: pointer;
  position: unset;
  vertical-align: middle;
}
body #main #pageContent article .edit-pages-template .standard-box .titleAndClose .box-title, body #main #pageContent article .edit-pages-template .standard-box .titleAndClose .close-popup {
  height: 90px;
  max-height: 100px;
  padding: 20px 10px;
}
body #main #pageContent article .edit-pages-template .section-bar {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  margin-left: 30px;
}
body #main #pageContent article .edit-pages-template .section-bar h2 {
  width: 90%;
  height: 40px;
}
body #main #pageContent article .edit-pages-template .section-bar button {
  width: 80px;
  height: 40px;
  max-width: 80px;
  margin: 0px;
}
body #main #pageContent article .module-manage-section .sub-header-wrapper h2 {
  width: 98%;
  padding-left: 19px;
  padding-bottom: 20px;
}
body #main .box-title.for-popup {
  padding-left: 10px;
  padding-bottom: 0px;
}
body #main .delete-page-link.delete {
  height: 28px;
  line-height: 0px;
  position: absolute;
  right: 0px;
  bottom: 0px;
  height: 44px !important;
  position: absolute;
  right: 0px;
  bottom: -11px;
  font-size: 19px;
  background: #d03939 !important;
  border-radius: 29px;
  padding-top: 11px;
  width: 44px;
  text-align: center;
  padding: 0px !important;
  cursor: pointer;
}
body #main .delete-page-link.delete i {
  position: absolute;
  margin-left: -11px;
  top: 9px;
}
body #main .delete-page-link.delete:hover {
  background: #a60f0f !important;
}
body #main .page-url-select {
  float: left;
  width: 100%;
  font-size: 11px;
  margin-bottom: 7px;
  font-weight: bold;
  cursor: pointer;
}
body #main .invisible-wrap.standard-box.edit-page-details {
  width: 100%;
  padding-top: 22px;
  margin: 10px 0px;
}
body #main .add-section-1st {
  float: left;
  box-sizing: border-box;
  font-size: 22px;
  font-weight: bold;
  padding-top: 42px;
  padding-bottom: 42px;
  width: 21%;
  text-align: center;
}
body #main .add-section-2nd {
  float: left;
  padding-left: 3%;
  width: 79%;
  background: #455052;
}
body #main .add-section-2nd input {
  float: left;
  margin-bottom: 0px;
  width: 81%;
  margin-bottom: 36px;
  margin-top: 38px;
}
body #main .add-section-2nd button {
  float: left;
  margin-top: 36px;
  margin-left: 3.3%;
  width: 12%;
  text-align: center;
  border: none;
}
body #main .edit-page-details .invisible-wrap.one-third-block.first {
  margin-top: 0px;
}
body #main .invisible-wrap.full-size-block.add-section-element {
  background: #384547;
  border-radius: 14px !important;
  overflow: hidden;
}
body #main .edit-page-details-link {
  color: #faa21a !important;
  font-size: 16px;
  font-weight: lighter;
  margin-left: 20px;
  cursor: pointer;
}
body #main .edit-pages-template .section-block {
  border: 3px dotted #0e505b !important;
  margin-bottom: 20px;
  float: left;
  width: 100%;
  padding: 19px;
  border-radius: 10px;
}
body #main div.skin-setting-bright #pageContent .edit-pages-template .section-block {
  border: 3px dotted #7d8687 !important;
}
body #main div.skin-setting-bright #pageContent .add-section-2nd {
  background: #eaeaea;
}
body #main div.skin-setting-bright #pageContent .add-section-2nd input {
  background: #c7cbcc !important;
}
body #main div.skin-setting-bright #pageContent .add-section-1st {
  background: #cfdade;
}
body #main div.skin-setting-bright #pageContent .invisible-wrap.full-size-block.add-section-element {
  background: #eaeaea;
}
body #main div.skin-setting-bright #pageContent .edit-page-details input {
  background: #c7cbcc !important;
}
body #main div.skin-setting-bright #pageContent .edit-page-details select {
  background: #c7cbcc !important;
}
body #main div.skin-setting-bright #pageContent .edit-page-details textarea {
  background: #c7cbcc !important;
}

@media only screen and (max-width: 367px) {
  #body #main #pageContent article .edit-pages-template .manage-page-pop-up {
    width: 100%;
  }
  #body #main #pageContent article .edit-pages-template .add-section-element {
    display: flex;
    align-items: stretch;
    flex-direction: row;
    flex-wrap: nowrap;
  }
  #body #main #pageContent article .edit-pages-template .add-section-element .add-section-1st {
    width: 40%;
  }
  #body #main #pageContent article .edit-pages-template .add-section-element .add-section-2nd {
    width: 60%;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  #body #main #pageContent article .edit-pages-template .add-section-element .add-section-2nd button {
    width: 170px;
    margin: 10px 0px 0px 0px;
  }
  #body #main #pageContent article .edit-pages-template .add-section-element .add-section-2nd input {
    margin: 0px;
    padding: 10px;
    width: 170px;
  }
  #body #main #pageContent article .edit-pages-template .standard-box .box-title, #body #main #pageContent article .edit-pages-template .standard-box .close-popup {
    height: 90px;
    font-size: 19px;
    max-height: 100px;
  }
  #body #main #pageContent article .edit-page-side .icon-link-wrapper {
    width: 100%;
  }
}
@media only screen and (min-width: 367px) and (max-width: 767px) {
  #body #main #pageContent article .edit-pages-template .manage-page-pop-up {
    width: 100%;
  }
  #body #main #pageContent article .edit-pages-template .add-section-element {
    display: flex;
    align-items: stretch;
    flex-direction: row;
    flex-wrap: nowrap;
  }
  #body #main #pageContent article .edit-pages-template .add-section-element .add-section-1st {
    width: 40%;
  }
  #body #main #pageContent article .edit-pages-template .add-section-element .add-section-2nd {
    width: 60%;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  #body #main #pageContent article .edit-pages-template .add-section-element .add-section-2nd button {
    width: 170px;
    margin: 10px 0px 0px 0px;
  }
  #body #main #pageContent article .edit-pages-template .add-section-element .add-section-2nd input {
    margin: 0px;
    padding: 10px;
    width: 170px;
  }
  #body #main #pageContent article .edit-pages-template .standard-box .box-title, #body #main #pageContent article .edit-pages-template .standard-box .close-popup {
    height: 90px;
    font-size: 19px;
    max-height: 100px;
  }
  #body #main #pageContent article .edit-page-side .icon-link-wrapper {
    width: 100%;
  }
}
@media only screen and (min-width: 367px) and (max-width: 767px) and (orientation: landscape) {
  #body #main #pageContent article .edit-pages-template .standard-box .box-title, #body #main #pageContent article .edit-pages-template .standard-box .close-popup {
    height: 60px;
    font-size: 19px;
    max-height: 70px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 979px) {
  #body #main #pageContent article .edit-pages-template .standard-box .box-title, #body #main #pageContent article .edit-pages-template .standard-box .close-popup {
    height: 90px;
    max-height: 100px;
  }
}
@media (min-width: 1340px) and (max-width: 1919px) {
  #body #main #pageContent article .edit-pages-template .standard-box .box-title, #body #main #pageContent article .edit-pages-template .standard-box .close-popup {
    height: 90px;
    max-height: 100px;
  }
}
@media (min-width: 1920px) {
  #body #main #pageContent article .edit-pages-template .standard-box .box-title, #body #main #pageContent article .edit-pages-template .standard-box .close-popup {
    height: 90px;
    max-height: 100px;
  }
}
.select-icon-box .link-icon i {
  border: none;
  font-size: 39px;
}

.name-of-icon {
  font-size: 10px;
  float: left;
  width: 100%;
  text-align: center;
}

.select-icon-box .link-icon {
  width: 100%;
  height: 90px;
}

.select-icon-box .icon-link:nth-child(9n) {
  margin-right: 0px !important;
}

.select-icon-box .icon-link svg {
  width: 50px;
}

.select-icon-box .icon-link {
  width: 8.43% !important;
  clear: none;
  cursor: pointer;
  background: none;
  margin-right: 3%;
  height: auto !important;
}

.skin-setting-bright .select-icon-box .icon-link {
  color: #004854;
}

.icon-link span {
  line-height: initial;
  display: inline-block;
  vertical-align: middle;
}

.select-icon-box .section-block .icon-link:hover {
  opacity: 0.7;
  background: none;
}

.select-icon-box .invisible-wrap.full-size-block.section-block:nth-child(2n) .icon-link {
  background: none;
  color: #faa21a;
}

.select-icon-box .invisible-wrap.full-size-block.section-block:nth-child(3n) .icon-link {
  background: none;
  color: #ee3124;
}

#blocworx .content .invisible-wrap icon-form .inner-standard-box-wrapper p.icon-line, #blocworx .content .invisible-wrap icon-form .inner-standard-box-wrapper p.icon-line-right, #blocworx .content .invisible-wrap icon-form .inner-standard-box-wrapper p.icon-first-line {
  margin: 10px auto;
}
#blocworx .content .invisible-wrap icon-form .inner-standard-box-wrapper p.icon-first-line {
  margin-top: 0px;
}
#blocworx .content .invisible-wrap icon-form .inner-standard-box-wrapper p.icon-line-right {
  margin-left: 10px;
  display: inline-block;
}
#blocworx .content .invisible-wrap icon-form .inner-standard-box-wrapper p.icon-line-right button {
  float: left;
  vertical-align: middle;
  line-height: 30px;
  margin: 1px auto;
}

@media only screen and (max-width: 1999px) {
  body #main #pageContent article .sub-header-wrapper + div > div:nth-child(3n) .icon-link, #body #main #pageContent article .sub-header-wrapper + div > div:nth-child(3n) .icon-link {
    margin-right: 0px !important;
  }
  body #main #pageContent article .icon-link:nth-child(3n), #body #main #pageContent article .icon-link:nth-child(3n) {
    margin-right: 0px;
  }
}
.single-page-in-list button {
  font-size: 13px;
  padding: 7px 12px;
}

button i {
  border: none;
}

.box-title.for-popup {
  padding-left: 10px;
  padding-bottom: 0px;
}

.selected-icon-display i {
  border: none;
}

#blocworx.skin-setting-dark #full-calendar .fc-button {
  border-color: var(--fc-button-border-color, #2C3E50);
}
#blocworx.skin-setting-dark #full-calendar .fc-button-primary {
  color: var(--fc-button-text-color, #fff);
  background: none #2C3E50;
}
#blocworx.skin-setting-dark #full-calendar th {
  background-color: white;
  border: 1px solid var(--fc-border-color, #ddd);
}
#blocworx.skin-setting-dark #full-calendar tbody tr {
  background-color: white;
}
#blocworx.skin-setting-dark #full-calendar a {
  color: #444;
}
#blocworx.skin-setting-dark #full-calendar a p {
  color: #111;
}
#blocworx.skin-setting-dark #full-calendar .fc-toolbar-title {
  color: #CCC;
}
#blocworx.skin-setting-dark #full-calendar .fc-daygrid-event {
  display: block;
  border: 1px solid #3788d8;
  border: 1px solid var(--fc-event-border-color, #3788d8);
  background-color: #3788d8;
  background-color: var(--fc-event-bg-color, #3788d8);
}
#blocworx.skin-setting-dark #full-calendar .fc-daygrid-event p {
  padding: 0px 10px 0px 10px;
  color: white;
}
#blocworx.skin-setting-dark #full-calendar .fc-daygrid-event p:hover {
  color: #111;
}
#blocworx.skin-setting-light #full-calendar .fc-button {
  border-color: var(--fc-button-border-color, #2C3E50);
}
#blocworx.skin-setting-light #full-calendar .fc-button-primary {
  color: var(--fc-button-text-color, #fff);
  background: none #2C3E50;
}
#blocworx.skin-setting-light #full-calendar th {
  background-color: white;
  border: 1px solid var(--fc-border-color, #ddd);
}
#blocworx.skin-setting-light #full-calendar tbody tr {
  background-color: white;
}
#blocworx.skin-setting-light #full-calendar a {
  color: #444;
}
#blocworx.skin-setting-light #full-calendar a p {
  color: #111;
}
#blocworx.skin-setting-light #full-calendar .fc-toolbar-title {
  color: #CCC;
}
#blocworx.skin-setting-light #full-calendar .fc-daygrid-event {
  display: block;
  border: 1px solid #3788d8;
  border: 1px solid var(--fc-event-border-color, #3788d8);
  background-color: #3788d8;
  background-color: var(--fc-event-bg-color, #3788d8);
}
#blocworx.skin-setting-light #full-calendar .fc-daygrid-event p {
  padding: 0px 10px 0px 10px;
  color: white;
}
#blocworx.skin-setting-light #full-calendar .fc-daygrid-event p:hover {
  color: #111;
}

#blocworx #full-calendar {
  height: auto;
  width: 100%;
  overflow: hidden;
}
#blocworx #full-calendar .fc .fc-col-header-cell-cushion {
  display: inline-block;
  padding: 2px 4px;
}
#blocworx #full-calendar table tbody td {
  cursor: pointer;
}
#blocworx #full-calendar .fc-daygrid-event-harness a:hover {
  cursor: default;
  background: transparent;
}

page-bar h1 + h2 {
  float: left;
  clear: both;
  font-size: 19px;
  font-weight: normal;
  margin-bottom: 20px;
}

page-bar h1 + h2 a, page-bar .edit-station {
  color: #ee3124;
  text-decoration: none;
}

#blocworx .page-bar {
  width: 100%;
  height: auto;
  box-sizing: border-box;
  padding-top: 20px;
  margin-bottom: 0px;
  margin-left: 0px;
  position: relative;
  color: #fff;
  display: inline-block;
  vertical-align: top;
}
#blocworx .page-bar img {
  width: 40px;
  display: inline-block;
  vertical-align: top;
  margin-left: 40px;
  margin-right: 13px;
  position: absolute;
  right: 0px;
}
#blocworx .page-bar .page-description img {
  width: auto;
  display: initial;
  vertical-align: initial;
  margin-left: auto;
  margin-right: auto;
  position: static;
}
#blocworx .page-bar h1 {
  font-size: 27.5px;
  color: #f0f4f5;
  float: left;
  font-weight: 600;
  margin: 10px 0px 10px;
  vertical-align: top;
  display: inline-block;
}
#blocworx .page-bar h3 {
  clear: both;
  font-weight: lighter;
  margin-top: 6px;
  float: left;
  display: inline-block;
  vertical-align: top;
}
#blocworx .page-bar .links {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  clear: both;
  padding-bottom: 23px;
  padding-left: 13px;
  padding-top: 14px;
}
#blocworx .page-bar .links a {
  color: #06c492;
  text-decoration: navajowhite;
  border: 2px solid #06c492;
  padding: 6px 23px 8px 23px;
  text-align: center;
  display: inline-block;
  vertical-align: top;
  margin-left: 25px;
}
#blocworx .page-bar .links a.active {
  cursor: default;
}
#blocworx .page-bar .links a.active + #blocworx .page-bar .links a:hover {
  color: #0b3c4c;
  background: #06c492;
}
#blocworx .skin-setting-dark .page-bar h1 {
  color: #f0f4f5;
}
#blocworx .content.job-page .page-bar img {
  margin-top: 5px;
}

@media (min-width: 979px) and (max-width: 1340px) {
  #blocworx .page-bar .links a {
    padding: 6px 16px 8px 16px;
    margin-left: 20px;
    font-size: 13px;
    margin-top: 3px;
    float: left;
  }
}
@media (max-width: 979px) {
  #blocworx .page-bar {
    width: 100%;
    height: auto;
    padding-bottom: 0px;
    margin-left: auto;
  }
  #blocworx .page-bar img {
    width: 28px;
  }
  #blocworx .page-bar .page-description img {
    width: auto;
  }
  #blocworx .page-bar .links {
    width: 100%;
    float: left;
    padding-top: 17px;
    padding-left: 29px;
    background: #eaeef0;
    border-top: 23px solid #0b3c4c;
  }
  #blocworx .page-bar .links a {
    padding: 4px 16px 6px 23px;
    float: left;
    margin-left: 12px;
    margin-bottom: 12px;
    font-size: 12px;
  }
  #blocworx .page-bar .links a.active {
    color: #fff;
  }
  #blocworx .compliance-content .page-bar {
    width: 90% !important;
    margin-left: 5% !important;
  }
}
template-document-manager #template-scan-station-edit .template-condition-wrapper {
  border: 1px solid #3c4749;
  border-radius: 22px;
  box-sizing: border-box;
  padding: 20px;
}

.skin-setting-bright .template-box p, .skin-setting-bright .template-box .pdf-or-word {
  color: #004854 !important;
}

#blocworx .inner-box-wrapper .template-list-single {
  margin: 10px auto;
  width: 100%;
}
#blocworx .inner-box-wrapper .template-list-single a {
  display: inline-block;
}
#blocworx .inner-box-wrapper .template-list-single:first-child {
  margin-top: 0px;
}
#blocworx .inner-box-wrapper .template-list-single:last-child {
  margin-bottom: 0px;
}
#blocworx .delete-template {
  cursor: pointer;
  color: red;
  float: right;
  position: relative;
}
#blocworx #downloadTemplate {
  top: 30px;
  z-index: 1;
}
#blocworx #downloadTemplate .template-box {
  margin-bottom: 30px;
}

#downloadPdfBtn {
  display: none;
}

.export-pdf-button img {
  width: 60px;
  margin-bottom: 20px;
}

.download-wrapper {
  clear: left;
}

#body #main #pageContent article .job-setup .module-options > .invisible-wrap {
  border-radius: 10px;
  margin: 10px auto;
}
#body #main #pageContent article .job-setup .module-options > .invisible-wrap.dashed {
  border: 1px dashed;
  position: relative;
}
#body #main #pageContent article .job-setup .module-options > .invisible-wrap .show-field {
  display: inline-flex !important;
}
#body #main #pageContent article .job-setup .module-options > .invisible-wrap .disabled-export-button {
  cursor: not-allowed;
  opacity: 0.5;
  pointer-events: none;
}
#body #main #pageContent article .job-setup .module-options > .invisible-wrap .export-message {
  font-size: 0.8em;
  color: #999;
  margin-top: 0.5em;
}
#body #main #pageContent article .job-setup .module-options > .invisible-wrap .fas:not(.fa-trash) {
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
  font-size: 30px;
}
#body #main #pageContent article .job-setup .module-options > .invisible-wrap .fas:not(.fa-trash).fa-minus {
  font-color: #d1d3d3;
}
#body #main #pageContent article .job-setup .module-options > .invisible-wrap .fas:not(.fa-trash).fa-plus {
  font-color: #152124;
  margin-right: 25px;
}
#body #main #pageContent article .job-setup .module-options > .invisible-wrap .standard-box {
  display: flex;
  flex-flow: row nowrap;
  justify-content: left;
  align-items: stretch;
  padding: 0px;
  border: 0px;
  margin-bottom: 30px;
  background: none;
}
#body #main #pageContent article .job-setup .module-options > .invisible-wrap .standard-box .title-container, #body #main #pageContent article .job-setup .module-options > .invisible-wrap .standard-box .fields-container {
  display: flex;
}
#body #main #pageContent article .job-setup .module-options > .invisible-wrap .standard-box .title-container {
  align-items: center;
  text-align: center;
  justify-content: center;
  font-size: 20px;
  font-weight: bold;
  border-top-left-radius: 11px;
  border-bottom-left-radius: 11px;
  flex-basis: 20%;
  background-color: #344547;
}
#body #main #pageContent article .job-setup .module-options > .invisible-wrap .standard-box .fields-container {
  border-top-right-radius: 11px;
  border-bottom-right-radius: 11px;
  background-color: #435052;
  flex-basis: 80%;
  padding: 30px 40px;
  justify-content: space-between;
}
#body #main #pageContent article .job-setup .module-options > .invisible-wrap .standard-box .module-button {
  color: #fff;
  font-size: 17.5px;
  padding: 8px 28px;
  display: block;
  margin: 0 auto;
  cursor: pointer;
  float: right;
  text-decoration: none;
  filter: none;
  font-weight: 500;
  border-radius: 10px;
  background: #004952;
  border: 3px solid #004952;
  width: 130px;
}
#body #main #pageContent article .job-page .module-options advanced-reports > .invisible-wrap {
  border-radius: 10px;
  margin: 10px auto;
}
#body #main #pageContent article .job-page .module-options advanced-reports > .invisible-wrap.dashed {
  border: 1px dashed;
  position: relative;
}
#body #main #pageContent article .job-page .module-options advanced-reports > .invisible-wrap .show-field {
  display: inline-flex !important;
}
#body #main #pageContent article .job-page .module-options advanced-reports > .invisible-wrap .disabled-export-button {
  cursor: not-allowed;
  opacity: 0.5;
  pointer-events: none;
}
#body #main #pageContent article .job-page .module-options advanced-reports > .invisible-wrap .export-message {
  font-size: 0.8em;
  color: #999;
  margin-top: 0.5em;
}
#body #main #pageContent article .job-page .module-options advanced-reports > .invisible-wrap .fas:not(.fa-trash) {
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
  font-size: 30px;
}
#body #main #pageContent article .job-page .module-options advanced-reports > .invisible-wrap .fas:not(.fa-trash).fa-minus {
  font-color: #d1d3d3;
}
#body #main #pageContent article .job-page .module-options advanced-reports > .invisible-wrap .fas:not(.fa-trash).fa-plus {
  font-color: #152124;
  margin-right: 25px;
}
#body #main #pageContent article .job-page .module-options advanced-reports > .invisible-wrap .standard-box {
  display: flex;
  flex-flow: row nowrap;
  justify-content: left;
  align-items: stretch;
  padding: 0px;
  border: 0px;
  margin-bottom: 30px;
  background: none;
}
#body #main #pageContent article .job-page .module-options advanced-reports > .invisible-wrap .standard-box .title-container, #body #main #pageContent article .job-page .module-options advanced-reports > .invisible-wrap .standard-box .fields-container {
  display: flex;
}
#body #main #pageContent article .job-page .module-options advanced-reports > .invisible-wrap .standard-box .title-container {
  align-items: center;
  text-align: center;
  justify-content: center;
  font-size: 20px;
  font-weight: bold;
  border-top-left-radius: 11px;
  border-bottom-left-radius: 11px;
  flex-basis: 20%;
  background-color: #344547;
}
#body #main #pageContent article .job-page .module-options advanced-reports > .invisible-wrap .standard-box .fields-container {
  border-top-right-radius: 11px;
  border-bottom-right-radius: 11px;
  background-color: #435052;
  flex-basis: 80%;
  padding: 30px 40px;
  justify-content: space-between;
}
#body #main #pageContent article .job-page .module-options advanced-reports > .invisible-wrap .standard-box .module-button {
  color: #fff;
  font-size: 17.5px;
  padding: 8px 28px;
  display: block;
  margin: 0 auto;
  cursor: pointer;
  float: right;
  text-decoration: none;
  filter: none;
  font-weight: 500;
  border-radius: 10px;
  background: #004952;
  border: 3px solid #004952;
  width: 130px;
}
#body #main #pageContent article .scan-station-front-end .module-options advanced-reports > .invisible-wrap {
  border-radius: 10px;
  margin: 10px auto;
}
#body #main #pageContent article .scan-station-front-end .module-options advanced-reports > .invisible-wrap.dashed {
  border: 1px dashed;
  position: relative;
}
#body #main #pageContent article .scan-station-front-end .module-options advanced-reports > .invisible-wrap .show-field {
  display: inline-flex !important;
}
#body #main #pageContent article .scan-station-front-end .module-options advanced-reports > .invisible-wrap .disabled-export-button {
  cursor: not-allowed;
  opacity: 0.5;
  pointer-events: none;
}
#body #main #pageContent article .scan-station-front-end .module-options advanced-reports > .invisible-wrap .export-message {
  font-size: 0.8em;
  color: #999;
  margin-top: 0.5em;
}
#body #main #pageContent article .scan-station-front-end .module-options advanced-reports > .invisible-wrap .fas:not(.fa-trash) {
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
  font-size: 30px;
}
#body #main #pageContent article .scan-station-front-end .module-options advanced-reports > .invisible-wrap .fas:not(.fa-trash).fa-minus {
  font-color: #d1d3d3;
}
#body #main #pageContent article .scan-station-front-end .module-options advanced-reports > .invisible-wrap .fas:not(.fa-trash).fa-plus {
  font-color: #152124;
  margin-right: 25px;
}
#body #main #pageContent article .scan-station-front-end .module-options advanced-reports > .invisible-wrap .standard-box {
  display: flex;
  flex-flow: row nowrap;
  justify-content: left;
  align-items: stretch;
  padding: 0px;
  border: 0px;
  margin-bottom: 30px;
  background: none;
}
#body #main #pageContent article .scan-station-front-end .module-options advanced-reports > .invisible-wrap .standard-box .title-container, #body #main #pageContent article .scan-station-front-end .module-options advanced-reports > .invisible-wrap .standard-box .fields-container {
  display: flex;
}
#body #main #pageContent article .scan-station-front-end .module-options advanced-reports > .invisible-wrap .standard-box .title-container {
  align-items: center;
  text-align: center;
  justify-content: center;
  font-size: 20px;
  font-weight: bold;
  border-top-left-radius: 11px;
  border-bottom-left-radius: 11px;
  flex-basis: 20%;
  background-color: #344547;
}
#body #main #pageContent article .scan-station-front-end .module-options advanced-reports > .invisible-wrap .standard-box .fields-container {
  border-top-right-radius: 11px;
  border-bottom-right-radius: 11px;
  background-color: #435052;
  flex-basis: 80%;
  padding: 30px 40px;
  justify-content: space-between;
}
#body #main #pageContent article .scan-station-front-end .module-options advanced-reports > .invisible-wrap .standard-box .module-button {
  color: #fff;
  font-size: 17.5px;
  padding: 8px 28px;
  display: block;
  margin: 0 auto;
  cursor: pointer;
  float: right;
  text-decoration: none;
  filter: none;
  font-weight: 500;
  border-radius: 10px;
  background: #004952;
  border: 3px solid #004952;
  width: 130px;
}
#body #main #pageContent article .icon-link-wrapper .delete-page-link.delete {
  right: -17px;
  z-index: 992;
  opacity: 0;
}
#body #main #pageContent article .icon-link-wrapper:hover .update-order {
  opacity: 1;
}
#body #main #pageContent article .icon-link-wrapper:hover .delete-page-link.delete {
  opacity: 1;
}
#body #main #pageContent article .update-order {
  position: absolute;
  transform: none !important;
  padding: 0px 8px;
  background: none;
  border-radius: 9px;
  background: #389138;
  border: none;
  top: -1px;
  opacity: 0;
  width: 26px;
  height: 26px;
}
#body #main #pageContent article .update-order:hover {
  background: #189119;
  background: #2B842B !important;
}
#body #main #pageContent article .update-order.left {
  left: 0px;
}
#body #main #pageContent article .update-order.right {
  right: -1px;
  left: auto;
}
#body #main #pageContent article .module-options > .dashed > .box-title {
  font-size: 22px;
  font-weight: bold;
  position: relative;
  top: 0px;
  left: 28px;
}
#body #main #pageContent article .module-options .dashed > .hide-field + .actions-container {
  cursor: pointer !important;
  left: 0px;
  position: absolute;
  width: 100%;
  top: 0px;
  height: 100%;
}
#body #main #pageContent article .module-options .dashed > .hide-field + .actions-container .fas.fa-plus {
  margin-right: 23px;
}
#body #main #pageContent article .module-options .invisible-wrap.module-grey-item .show-field {
  display: block !important;
}
#body #main #pageContent article .for-section .full-size-block {
  width: 100%;
}
#body #main #pageContent article .for-section .full-size-block input {
  width: 80%;
  width: 80%;
  margin-bottom: 0px;
  padding-top: 11px;
  padding-bottom: 12px;
  padding-left: 18px;
}
#body #main #pageContent article .actions-container .fas.fa-minus {
  cursor: pointer;
}
#body #main #pageContent article .actions-container .fas {
  transition: none !important;
}
#body #main #pageContent article .for-station .half-size-block {
  width: 48.4%;
}
#body #main #pageContent article .select-icon-click {
  margin-left: 20px;
}
#body #main #pageContent article .select-icon-click:hover {
  cursor: pointer;
}
#body #main #pageContent article .invisible-wrap.full-size-block.module-grey-item {
  background: #455052;
  position: relative;
}
#body #main #pageContent article .invisible-wrap.full-size-block.module-grey-item .fa-plus {
  position: absolute;
  cursor: pointer;
  color: #172124;
  right: 0px;
  top: 10px;
}
#body #main #pageContent article .invisible-wrap.full-size-block.module-grey-item .fa-minus {
  margin-right: 0px;
}
#body #main #pageContent article .invisible-wrap.full-size-block.module-grey-item > .standard-box {
  margin-bottom: 0px !important;
}
#body #main #pageContent article .module-grey-item .standard-box {
  display: block !important;
}
#body #main #pageContent article .module-grey-item .fas.fa-minus {
  position: absolute;
  color: #172124;
  right: 25px;
  top: 10px;
}
#body #main #pageContent article .edit-section-name {
  border: none;
  font-size: 14px;
}
#body #main #pageContent article .section-name-field .update-data {
  border: none;
  margin-top: -14px;
  font-size: 14px;
}
#body #main #pageContent article .position-edit-section-name {
  display: inline-block;
  position: absolute;
  right: 10px;
}
#body #main #pageContent article .section-name {
  display: inline-block;
}
#body #main #pageContent article .sub-header-wrapper .section-name-field input {
  width: 86%;
}
#body #main #pageContent article .sub-header-wrapper .section-name-field button {
  position: relative;
  top: 15px;
  line-height: 30px;
  vertical-align: middle;
}
#body #main #pageContent article .sub-header-wrapper .section-name-field input {
  line-height: 30px;
  vertical-align: middle;
}
#body #main #pageContent article .module-component .invisible-wrap {
  padding: 20px;
}
#body #main #pageContent article .module-component .invisible-wrap .expand-retract {
  margin-right: 24px;
  margin-top: 18px;
}
#body #main #pageContent article .module-component .invisible-wrap .box-title {
  background-color: inherit;
}
#body #main #pageContent article .module-component .invisible-wrap .fa-plus {
  right: 0px;
}
#body #main #pageContent article .module-component .invisible-wrap .fa-minus {
  margin-right: 0px;
}
#body #main div.skin-setting-dark #pageContent article .content .for-data-look-up .standard-box {
  background: #232d2f;
}
#body #main div.skin-setting-bright #pageContent article .content .for-data-look-up .standard-box {
  background: #e0e0e0;
}
#body #main div.skin-setting-bright #pageContent article .invisible-wrap.full-size-block.module-grey-item {
  background: #eaeaea;
}
#body #main div.skin-setting-bright #pageContent article .module-options > .invisible-wrap .standard-box .box-title {
  color: #004854;
}
#body #main div.skin-setting-bright #pageContent article .module-options > .invisible-wrap .standard-box .fields-container {
  background-color: #eaeaea !important;
}
#body #main div.skin-setting-bright #pageContent article .module-options > .invisible-wrap .standard-box .title-container {
  background-color: #cfdade !important;
}

@media only screen and (max-width: 367px) {
  #body #main #pageContent article scan-station-results .field-count-box h4 {
    font-size: 22px;
  }
}
@media only screen and (min-width: 367px) and (max-width: 767px) {
  #body #main #pageContent article scan-station-results .field-count-box h4 {
    font-size: 22px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 979px) {
  #body #main #pageContent article scan-station-results .field-count-box h4 {
    font-size: 22px;
  }
}
@media (min-width: 979px) and (max-width: 1024px) {
  #body #main #pageContent article scan-station-results .field-count-box h4 {
    font-size: 22px;
  }
}
@media (min-width: 1025px) and (max-width: 1340px) {
  #body #main #pageContent article scan-station-results .field-count-box h4 {
    font-size: 22px;
  }
}
body #main #pageContent article edit-data .editLinkButton {
  cursor: pointer;
}
body #main #pageContent article edit-data .box-title .edit-switch {
  display: flex;
}
body #main #pageContent article edit-data .box-title input {
  display: inline-flex;
}
body #main #pageContent article edit-data .box-title label {
  display: inline-flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  font-size: 16px;
}
body #main #pageContent article td[edit-data-link] .editLinkButton {
  margin: 0 auto;
  text-align: center;
}
body #main #pageContent article th.edit-data-link, body #main #pageContent article td[edit-data-link] {
  display: table-cell;
  text-align: center;
}
#blocworx .form-results-section tbody tr td {
  border: none;
}
#blocworx .form-results-section tbody tr td .fa-search-plus {
  color: #333333;
}
#blocworx .form-results-section tbody tr td[show-more-data=link], #blocworx .form-results-section tbody tr td[edit-data-link] {
  cursor: pointer;
}
#blocworx .form-results-section tbody tr td[show-more-data=link]:hover, #blocworx .form-results-section tbody tr td[edit-data-link]:hover {
  background-color: #aaa;
  filter: alpha(opacity=60);
}
#blocworx .form-results-section tbody tr td[show-more-data=link]:hover .fas, #blocworx .form-results-section tbody tr td[edit-data-link]:hover .fas {
  color: #ee3124;
}

#blocworx search-data {
  float: left;
  width: 100%;
  margin: 15px auto;
}

#blocworx in-data-export-button .invisible-wrap.full-size-block {
  margin-bottom: 0px;
}
#blocworx in-data-export-button .export {
  padding: 4px 7px;
  border-radius: 9px;
  color: #fff;
  cursor: pointer;
}
#blocworx in-data-export-button .export.pdf {
  background: #c04343;
  color: #fff;
}
#blocworx in-data-export-button .export.doc {
  background: #5555c4;
  color: #fff;
}
#blocworx in-data-export-button .generated-pdf-button {
  display: none;
}
#blocworx in-data-export-button .template-box {
  margin-top: 20px;
}
#blocworx in-data-export-button .standard-box {
  text-align: left;
}
#blocworx in-data-export-button i {
  float: left;
}
#blocworx in-data-export-button download {
  position: relative;
  left: -80px;
  width: 200px;
  max-width: none;
}

body #main #pageContent article .radio-button-from-station-field-type,
body #main #pageContent article .checkbox-button-from-station-field-type {
  float: left;
  width: 100%;
  margin-bottom: 6px;
  font-size: 17px;
}
body #main #pageContent article checkbox-from-station .checkbox-label {
  width: auto;
  margin-right: 10px;
  vertical-align: middle;
  margin-bottom: 0px;
  display: inline-block;
  float: none;
}
body #main #pageContent article checkbox-from-station .submit-button {
  font-size: 13px;
  padding: 6px;
  position: relative;
  vertical-align: middle;
  margin-bottom: 0px;
  height: 40px;
  display: inline-block;
  float: none;
  background: #008000;
}
body #main #pageContent article checkbox-from-station .clean-all {
  background: #ee3124;
}
body #main #pageContent article checkbox-from-station .list-all-values-selected {
  padding: 0px;
  margin: 0px;
  color: rgba(79, 177, 79, 0.6);
}
body #main #pageContent article checkbox-from-station .list-all-values {
  opacity: 0.7;
  padding: 0px;
  margin: 6px auto;
}
#blocworx qr-code i {
  font-size: 30px;
}
#blocworx qr-code label {
  text-transform: capitalize;
}
#blocworx qr-code button {
  border-radius: 10px;
  float: left;
}
#blocworx qr-code .btn-text {
  padding: 8px 28px;
}
#blocworx qr-code .btn-qrcode {
  float: left;
  padding: 5px;
}
#blocworx qr-code canvas {
  width: 100%;
}
#blocworx qr-code .close-qr-scanner {
  background-color: rgba(0, 0, 0, 0.3);
  display: inline-block;
  width: 100%;
  border-radius: 11px 11px 0px 0px;
  line-height: 50px;
  height: 50px;
  min-height: 50px;
}
#blocworx qr-code .close-qr-scanner i {
  float: right;
  position: relative;
  right: 5px;
  padding: 4px 1px;
}
#blocworx qr-code .canvas-qr-scanner {
  margin-top: 0px;
  position: relative;
  top: -10px;
}
#blocworx qr-code .qr-results {
  width: 60%;
  display: inline-block;
  font-size: 12px;
  margin-left: 6px;
}

body #main #pageContent article radio-button .field-options {
  display: inline-flex;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  height: 30px;
  margin: 0px 0px 20px 0px;
}
body #main #pageContent article radio-button .field-options p {
  display: inline-flex;
  align-items: center;
  margin: 0px;
}
body #main #pageContent article radio-button .field-options p label {
  float: none;
  margin: 0px;
  padding: 0px;
}
body #main #pageContent article radio-button .radio-button-input {
  position: relative;
  top: 0;
  margin-right: 10px;
  margin-bottom: 10px;
}
body #main #pageContent article radio-button .clean-radio-action {
  cursor: pointer;
  padding: 0px;
  font-size: 15px;
}
body #main #pageContent article radio-button a {
  text-decoration: none;
}
body #main #pageContent article radio-button .loadingData {
  display: block;
  margin-top: 20px;
  margin-left: 0px;
}
#blocworx mindee .file-upload-container input {
  width: 35%;
}
#blocworx mindee .file-upload-container button {
  width: 47%;
  padding-top: 9px;
  padding-bottom: 11px;
  height: 100%;
  box-sizing: border-box;
  float: left;
  margin-top: -3px;
}
#blocworx mindee img, #blocworx mindee iframe {
  width: 100% !important;
  margin-top: 20px;
  margin-bottom: 20px;
}
#blocworx mindee iframe {
  height: 100vh;
}
#blocworx mindee .show-preview {
  position: relative;
  clear: both;
}
#blocworx mindee .show-preview i {
  cursor: pointer;
  position: absolute;
  bottom: 33px;
  right: 16px;
  background: white;
  font-size: 25px;
  background: none;
  color: #000;
}
#blocworx mindee .show-preview.full-screen i {
  position: fixed;
  top: 8%;
  right: 7%;
  z-index: 9999;
  border-radius: 5px;
  color: #000;
  bottom: auto;
}
#blocworx mindee .show-preview.full-screen {
  position: fixed;
  z-index: 999;
  top: 5%;
  width: 90%;
  left: 5%;
  height: 90%;
  overflow: auto;
}

#blocworx submit-button-field .submit-button.hide {
  display: block;
}
#blocworx submit-button-field .scan-station-buttons {
  padding: 0px;
}
#blocworx submit-button-field button {
  width: 100%;
}
#blocworx submit-button-field .delete {
  display: none !important;
}

#blocworx mail-alert .file-upload-container input {
  width: 35%;
}
#blocworx mail-alert .file-upload-container button {
  width: 47%;
  padding-top: 9px;
  padding-bottom: 11px;
  height: 100%;
  box-sizing: border-box;
  float: left;
  margin-top: -3px;
}
#blocworx mail-alert img, #blocworx mail-alert iframe {
  width: 100% !important;
  margin-top: 20px;
  margin-bottom: 20px;
}
#blocworx mail-alert iframe {
  height: 100vh;
}
#blocworx mail-alert .show-preview {
  position: relative;
  clear: both;
}
#blocworx mail-alert .show-preview i {
  cursor: pointer;
  position: absolute;
  bottom: 33px;
  right: 16px;
  background: white;
  font-size: 25px;
  background: none;
  color: #000;
}
#blocworx mail-alert .show-preview.full-screen i {
  position: fixed;
  top: 8%;
  right: 7%;
  z-index: 9999;
  border-radius: 5px;
  color: #000;
  bottom: auto;
}
#blocworx mail-alert .show-preview.full-screen {
  position: fixed;
  z-index: 999;
  top: 5%;
  width: 90%;
  left: 5%;
  height: 90%;
  overflow: auto;
}
#blocworx mail-alert .fixed-value {
  font-weight: bold;
  font-style: italic;
}
#blocworx mail-alert .delete-button {
  color: red;
  cursor: pointer;
}
#blocworx mail-alert .small-margin {
  margin-right: 25px;
}

#body #main #pageContent article file-builder .loading-image {
  margin-top: 11px;
  text-align: right;
  float: right;
}
#body #main #pageContent article file-builder .file-builder-scan-station-data {
  display: inline-flex;
  align-items: center;
  justify-content: space-around;
}
#body #main #pageContent article file-builder .file-builder-modal {
  position: fixed;
  display: inline-flex;
  flex-direction: row;
  width: 100%;
  top: 0;
  left: 0;
  height: 100vh;
  z-index: 99999999999999;
  flex-wrap: wrap;
  align-content: stretch;
  justify-content: center;
  background: rgba(0, 0, 0, 0.8);
  padding: 30px;
}
#body #main #pageContent article file-builder .file-builder-modal .file-builder-modal-header {
  flex-direction: row;
  align-items: flex-end;
  flex-wrap: wrap;
  display: inline-flex;
  color: white;
  width: 100%;
  height: 50px;
}
#body #main #pageContent article file-builder .file-builder-modal .file-builder-modal-header h2 {
  display: inline-flex;
  align-items: center;
  align-content: center;
  flex: 1 1 auto;
  height: 50px;
  margin-left: 10px;
}
#body #main #pageContent article file-builder .file-builder-modal .file-builder-modal-header .file-builder-modal-header-button {
  font-size: 30px;
  display: flex;
  align-content: space-around;
  align-items: center;
  margin-right: 10px;
}
#body #main #pageContent article file-builder .file-builder-modal .file-builder-modal-container {
  width: 100%;
  display: inline-flex;
  height: 100%;
  align-items: flex-start;
}
#body #main #pageContent article file-builder .file-builder-modal .file-builder-modal-container table {
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}
#body #main #pageContent article file-builder .file-builder-modal .file-builder-modal-container td .align-left {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  align-content: center;
  overflow-wrap: break-word;
  white-space: normal;
}
#body #main #pageContent article file-builder .file-builder-modal .file-builder-modal-buttons {
  width: 100%;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  display: inline-flex;
  color: white;
  flex: 1 1 100%;
  justify-content: flex-start;
}
#body #main #pageContent article file-builder .file-builder-modal .file-builder-modal-buttons h2 {
  font-size: 20px;
  padding: 20px;
}
#body #main #pageContent article file-builder .file-builder-modal .file-builder-modal-buttons .modal-button-left {
  display: flex;
  align-content: center;
  align-items: center;
}
#body #main #pageContent article file-builder .file-builder-modal .file-builder-modal-buttons .modal-button-right {
  display: flex;
  align-content: center;
  align-items: center;
}
#body #main div.skin-setting-dark #pageContent article file-builder .file-builder-scan-station-data .file-builder-modal .file-builder-modal-header {
  background-color: #343a40;
}
#body #main div.skin-setting-dark #pageContent article file-builder .file-builder-scan-station-data .file-builder-modal .file-builder-modal-buttons {
  background-color: #455052;
}
#body #main div.skin-setting-dark #pageContent article file-builder .file-builder-scan-station-data .file-builder-modal .file-builder-modal-container {
  background-color: #455052;
}
#body #main div.skin-setting-bright #pageContent article file-builder .file-builder-scan-station-data .file-builder-modal .file-builder-modal-header {
  background-color: #757d7e;
}
#body #main div.skin-setting-bright #pageContent article file-builder .file-builder-scan-station-data .file-builder-modal .file-builder-modal-buttons {
  background-color: #eaeaea;
}
#body #main div.skin-setting-bright #pageContent article file-builder .file-builder-scan-station-data .file-builder-modal .file-builder-modal-container {
  background-color: #eaeaea;
}

body #main #pageContent article source-bloc {
  margin: 0px 0px 20px 0px;
  display: inline-block;
  width: 100%;
}
body #main #pageContent article source-bloc .flex-container input {
  margin: 0px;
}
body #main #pageContent article matching-data {
  margin: 0px 0px 20px 0px;
  display: inline-block;
  width: 100%;
}
body #main #pageContent article matching-data .flex-container {
  display: inline-flex;
  align-content: center;
  align-items: stretch;
}
body #main #pageContent article matching-data .flex-child-button {
  margin: 0px 0px 0px 10px;
}
body #main #pageContent article matching-data .spaced {
  align-items: flex-end;
  justify-content: flex-end;
}
body #main #pageContent article exported-fields {
  margin: 0px 0px 20px 0px;
  display: inline-block;
  width: 100%;
}
body #main #pageContent article exported-fields .flex-child-button, body #main #pageContent article exported-fields .flex-child-select {
  margin: 10px 0px;
}
body #main #pageContent article exported-fields .flex-child-select {
  margin-right: 10px;
}
body #main #pageContent article exported-fields .flex-child-button, body #main #pageContent article exported-fields .flex-child-x {
  margin-left: 10px;
}
body #main #pageContent .field-digital-signature-pad {
  /* 300px is the width set by the library so this is always a set width */
}
body #main #pageContent .field-digital-signature-pad .signature-pad {
  float: left;
}
body #main #pageContent .field-digital-signature-pad button:not(.delete) {
  float: left;
  clear: both;
}
body #main #pageContent .field-digital-signature-pad .finished-sig {
  background: #bababa;
  width: 300px;
  box-sizing: border-box;
  clear: both;
}

#blocworx barcode-generator .field-inner-container img {
  margin-top: 20px;
}

.hide-in-form-sub-bloc-accordian label, .hide-in-form-sub-bloc-accordian .box-title {
  display: none;
}

.hide-in-form-sub-bloc-accordian .box-title + .accordian-sub-bloc-container {
  border: none !important;
}

.sub-bloc-manual-trigger-button {
  width: calc(100% - 50px);
  border: none;
  text-align: center;
  margin-top: 25px;
  margin-left: 25px;
  padding: 10px 10px 10px 10px;
}

.is-in-iframe #scanStationHeader .box-title .header-title {
  display: inline !important;
}

.is-in-iframe .content.manage-users {
  padding: 2% !important;
}

.hide-customer {
  display: none;
}

body #main .manage-users-data tr.disabled {
  opacity: 0.3;
}
body #main .manage-users-data + label {
  margin-top: 20px;
  cursor: pointer;
}
users .user-buttons {
  width: 100%;
  float: left;
  margin-top: 0px;
  padding-bottom: 30px;
}
users .overflow-container {
  padding: 15px;
}
users .standard-box {
  position: relative;
}
users .space-between-standard-box {
  margin-top: 15px;
  margin-bottom: 15px;
}

.invisible-wrap.full-size-block.show-profiles-pop-up {
  top: 1%;
  padding-bottom: 0px;
  overflow: hidden;
  margin: 0px auto;
}
.invisible-wrap.full-size-block.show-profiles-pop-up .standard-box {
  overflow-y: auto;
  left: 1%;
  width: 98%;
  height: 98%;
  top: 0%;
}

.show-profiles-pop-up .standard-box .profile-content {
  padding: 0px;
  margin: 0px;
}
.show-profiles-pop-up .standard-box .profile-content .inner-standard-box-wrapper {
  padding: 0px 28px;
  margin: 0px;
}
.show-profiles-pop-up .standard-box .inner-standard-box-wrapper {
  padding: 0px 20px;
}

#iframe .edit-switch {
  margin: 0px;
  padding: 0px;
  width: 97% !important;
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
}
#iframe .edit-switch input {
  padding: 0px;
  margin: 0px !important;
}
#iframe .for-checkbox {
  margin: 0px;
  padding: 0px;
  position: relative;
  left: 17px;
  width: 97%;
}

body #main #pageContent article .show-false {
  opacity: 0;
  height: 0px;
  display: none;
}
body #main #pageContent article roles-and-permissions > div {
  margin: 10px auto;
}
body #main #pageContent article roles-and-permissions .inner-standard-box-wrapper {
  position: relative;
}
body #main #pageContent article roles-and-permissions .inner-standard-box-wrapper.show-true {
  padding-bottom: 30px;
  background: inherit;
  overflow: hidden;
  width: 100%;
  z-index: 1;
}
body #main #pageContent article roles-and-permissions .show-visible {
  overflow: visible;
  opacity: 1;
  padding: 0px 25px 0px 30px;
  max-height: 100%;
  margin-top: 10px;
  padding-bottom: 30px !important;
  background: inherit;
  width: 100%;
  top: 41px;
  z-index: 1;
  position: absolute;
  float: left;
  box-sizing: border-box;
}
body #main #pageContent article roles-and-permissions .full-size-block {
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
}
@media only screen and (max-width: 976px) {
  body #main #pageContent article roles-and-permissions .full-size-block, #body #main #pageContent article roles-and-permissions .full-size-block {
    flex-direction: column;
  }
}
body #scheduleCustomerData h2, body #scheduleCustomerData p, body #scheduleCustomerData .flexcontainer {
  margin: 10px auto;
}
body #scheduleCustomerData .flexcontainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
}
body #scheduleCustomerData .flexcontainer button {
  float: none;
  margin: 0;
}
body #scheduleCustomerData .flexcontainer .message {
  width: 100%;
}
body #scheduleCustomerData .flexcontainer .success {
  color: green;
}
body #scheduleCustomerData .flexcontainer .fail {
  color: red;
}
body #scheduleCustomerData .oneline {
  width: 100%;
}
body #scheduleCustomerData .flexchild {
  flex: 1 1 auto;
}

body #main #pageContent article .content .reports .existing-automated-report {
  word-break: break-all;
}
body #main #pageContent article .content .reports .more-data-pop-up {
  overflow: unset;
}
body #main #pageContent article .content .reports .more-data-pop-up tobdy {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  table-layout: fixed;
}
body #main #pageContent article .content .reports .more-data-pop-up table tbody {
  display: block;
  max-height: 800px;
  overflow-y: scroll;
}
body #main #pageContent article .content .reports .more-data-pop-up table thead, body #main #pageContent article .content .reports .more-data-pop-up table tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}
body #main div.skin-setting-dark #pageContent article .content .reports .more-data-pop-up {
  background-color: #374244;
  -webkit-box-shadow: 0px 4px 20px 9px #232d2f;
  -moz-box-shadow: 0px 4px 20px 9px #232d2f;
  box-shadow: 0px 4px 20px 9px #232d2f;
}
body #main div.skin-setting-dark #pageContent article .content .reports .more-data-pop-up tbody::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
body #main div.skin-setting-dark #pageContent article .content .reports .more-data-pop-up tbody::-webkit-scrollbar-thumb {
  background: #455052;
}
body #main div.skin-setting-dark #pageContent article .content .reports .more-data-pop-up tbody::-webkit-scrollbar-track {
  background: #232d2f;
}
body #main div.skin-setting-dark #pageContent article .content .reports .more-data-pop-up tbody {
  scrollbar-face-color: #455052;
  scrollbar-track-color: #232d2f;
}
body #main div.skin-setting-bright #pageContent article .content .reports .more-data-pop-up {
  background: #f0f4f5;
  -webkit-box-shadow: 0px 4px 20px 9px #455052;
  -moz-box-shadow: 0px 4px 20px 9px #455052;
  box-shadow: 0px 4px 20px 9px #455052;
}
body #main div.skin-setting-bright #pageContent article .content .reports .more-data-pop-up tbody::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
body #main div.skin-setting-bright #pageContent article .content .reports .more-data-pop-up tbody::-webkit-scrollbar-thumb {
  background: #343a40;
}
body #main div.skin-setting-bright #pageContent article .content .reports .more-data-pop-up tbody::-webkit-scrollbar-track {
  background: #eaeaea;
}
body #main div.skin-setting-bright #pageContent article .content .reports .more-data-pop-up tbody {
  scrollbar-face-color: #343a40;
  scrollbar-track-color: #eaeaea;
}

@media only screen and (max-width: 976px) {
  body #main #pageContent article .content .reports .more-data-pop-up table tbody, #body #main #pageContent article .content .reports .more-data-pop-up table tbody {
    max-height: 850px;
  }
}
@media only screen and (max-width: 480px) {
  body #main #pageContent article .content .reports .more-data-pop-up table tbody, #body #main #pageContent article .content .reports .more-data-pop-up table tbody {
    max-height: 500px;
  }
}
@media only screen and (min-width: 2000px) {
  body #main #pageContent article .icon-link-wrapper, #body #main #pageContent article .icon-link-wrapper {
    width: 23%;
  }
  body #main #pageContent article .icon-link-wrapper .icon-link, #body #main #pageContent article .icon-link-wrapper .icon-link {
    width: 100%;
  }
  body #main #pageContent article .icon-link, #body #main #pageContent article .icon-link {
    width: 23.5%;
  }
  body #main #pageContent article .icon-link:nth-child(4n), #body #main #pageContent article .icon-link:nth-child(4n) {
    margin-right: 0px;
  }
  body #main #pageContent article .sub-header-wrapper + div > div:nth-child(4n) .icon-link, #body #main #pageContent article .sub-header-wrapper + div > div:nth-child(4n) .icon-link {
    margin-right: 0px !important;
  }
}
#blocworx detect-inactivity .standard-box.detect-inactivity-popup {
  position: fixed;
  top: 19%;
  left: 25%;
  width: 47% !important;
  height: 300px;
  overflow: auto;
  text-align: center;
  z-index: 9999;
}

@media only screen and (max-width: 979px) {
  #blocworx detect-inactivity .standard-box.detect-inactivity-popup {
    position: fixed;
    z-index: 999999;
    top: 5%;
    left: 5%;
    width: 90% !important;
    height: 90%;
    overflow: auto;
    text-align: center;
  }
}
body #main #pageContent article management-blocworx {
  width: 100%;
  height: 100%;
  display: flex;
}
#body #main #pageContent .results-box-table td input {
  background: none !important;
  border: 1px solid #989898 !important;
  padding: 5%;
}
#body #main #pageContent .results-box-table .results-box-edit-icons {
  min-width: 55px;
}
#body #main #pageContent .results-box-table .results-box-edit-icons .fa-check {
  color: green;
}
#body #main #pageContent article th.results-box-field {
  width: 0.1%;
  max-width: 0.1%;
  text-align: center;
}
#body #main #pageContent article td.results-box-field {
  width: 0.1%;
  max-width: 0.1%;
  text-align: center;
}
#body #main #pageContent article .simple-modal {
  --gutter: 14px;
  --modal-color: #153740;
  --soft-color: #fafafa;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 2000;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.9);
  padding-top: 2%;
}
#body #main #pageContent article .simple-modal__content {
  width: 95vw;
  height: 90vh;
  max-height: 900px;
  max-width: 1800px;
  position: relative;
  overflow: hidden;
  border-radius: 4px;
  margin: 0 auto;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
}
#body #main #pageContent article .simple-modal__content header {
  min-height: 60px;
  height: 60px;
  color: #ffffff;
  background-color: var(--modal-color);
  display: grid;
  align-items: center;
  grid-template-columns: auto 90px;
  justify-content: space-between;
}
#body #main #pageContent article .simple-modal__content header h4 {
  margin: 0;
  text-align: left;
  padding: var(--gutter);
}
#body #main #pageContent article .simple-modal__content header span {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  font-size: 20px;
  opacity: 0.8;
  cursor: pointer;
  padding: var(--gutter);
}
#body #main #pageContent article .simple-modal__content header span:hover {
  opacity: 1;
  background-color: rgba(4, 18, 20, 0.3215686275);
}
#body #main #pageContent article .simple-modal__content .modal-main {
  flex: 1;
  text-align: left;
  overflow: auto;
  padding: var(--gutter);
}
#body #main #pageContent article .simple-modal__content footer {
  height: auto;
  text-align: right;
  border-top: solid 1px #cccccc;
  padding: var(--gutter);
  background-color: var(--soft-color);
}
#body #main #pageContent article .simple-modal__content footer button, #body #main #pageContent article .simple-modal__content footer input {
  margin: 0;
  border: none;
}
#body #main #pageContent article .simple-modal__content footer button:not(:last-child), #body #main #pageContent article .simple-modal__content footer input:not(:last-child) {
  margin-right: var(--gutter);
}
#body #main #pageContent article .simple-modal__content footer button {
  display: none;
}
#body #main #pageContent article .modal-off {
  display: none;
}
#body #main #pageContent article .modal-on {
  display: flex;
}
#body #main #pageContent article .field-inner-container .autogenerate-field-value,
#body #main #pageContent article .field-inner-container .time-difference-field-value,
#body #main #pageContent article .field-inner-container .maths-field-value,
#body #main #pageContent article .field-inner-container .merge-builder-field-value,
#body #main #pageContent article .field-inner-container .checkbox-text-value {
  display: inline;
}
#body #main #pageContent article .field-inner-container .timepicker-seconds {
  display: none;
}
#body #main div.skin-setting-dark #pageContent article .results-box-field thead tr th {
  color: #fff;
}
#body #main div.skin-setting-dark #pageContent article .simple-modal {
  --modal-color: #232d2f;
  --soft-color: #fafafa;
}
#body #main div.skin-setting-dark #pageContent article .simple-modal__content {
  background-color: #ffffff;
}
#body #main div.skin-setting-dark #pageContent article .simple-modal__content .modal-main {
  background-color: #455052;
}
#body #main div.skin-setting-dark #pageContent article .simple-modal__content .modal-main::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
#body #main div.skin-setting-dark #pageContent article .simple-modal__content .modal-main::-webkit-scrollbar-thumb {
  background: #455052;
}
#body #main div.skin-setting-dark #pageContent article .simple-modal__content .modal-main::-webkit-scrollbar-track {
  background: #232d2f;
}
#body #main div.skin-setting-dark #pageContent article .simple-modal__content .modal-main {
  scrollbar-face-color: #455052;
  scrollbar-track-color: #232d2f;
}
#body #main div.skin-setting-dark #pageContent article .simple-modal__content header {
  background-color: var(--modal-color);
}
#body #main div.skin-setting-dark #pageContent article .simple-modal__content header h4, #body #main div.skin-setting-dark #pageContent article .simple-modal__content header span {
  color: #fff;
}
#body #main div.skin-setting-dark #pageContent article .simple-modal__content span:hover {
  background-color: rgba(4, 18, 20, 0.3215686275);
}
#body #main div.skin-setting-bright #pageContent article .results-box-field thead tr th {
  color: #004854;
}
#body #main div.skin-setting-bright #pageContent article .simple-modal {
  --modal-color: #343a40db;
  --soft-color: #fafafa;
}
#body #main div.skin-setting-bright #pageContent article .simple-modal__content {
  background-color: #ffffff;
}
#body #main div.skin-setting-bright #pageContent article .simple-modal__content .modal-main {
  background-color: rgba(223, 231, 233, 0.5411764706);
}
#body #main div.skin-setting-bright #pageContent article .simple-modal__content .modal-main::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
#body #main div.skin-setting-bright #pageContent article .simple-modal__content .modal-main::-webkit-scrollbar-thumb {
  background: #343a40;
}
#body #main div.skin-setting-bright #pageContent article .simple-modal__content .modal-main::-webkit-scrollbar-track {
  background: #eaeaea;
}
#body #main div.skin-setting-bright #pageContent article .simple-modal__content .modal-main {
  scrollbar-face-color: #343a40;
  scrollbar-track-color: #eaeaea;
}
#body #main div.skin-setting-bright #pageContent article .simple-modal__content header {
  background-color: var(--modal-color);
}
#body #main div.skin-setting-bright #pageContent article .simple-modal__content header h4, #body #main div.skin-setting-bright #pageContent article .simple-modal__content header span {
  color: #fff;
}
#body #main div.skin-setting-bright #pageContent article .simple-modal__content span:hover {
  background-color: rgba(4, 18, 20, 0.3215686275);
}

@media only screen and (max-width: 367px) {
  #body #main #pageContent article .simple-modal {
    padding-top: 8%;
  }
  #body #main #pageContent article .simple-modal__content {
    max-width: 340px;
  }
}
@media only screen and (min-width: 367px) and (max-width: 767px) {
  #body #main #pageContent article .simple-modal {
    padding-top: 8%;
  }
  #body #main #pageContent article .simple-modal__content {
    max-width: 740px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 979px) {
  #body #main #pageContent article .simple-modal {
    padding-top: 8%;
  }
  #body #main #pageContent article .simple-modal__content {
    max-width: 960px;
  }
}
@media (min-width: 979px) and (max-width: 1024px) {
  #body #main #pageContent article .simple-modal {
    padding-top: 3%;
  }
  #body #main #pageContent article .simple-modal__content {
    max-width: 900px;
  }
}
@media (min-width: 1025px) and (max-width: 1340px) {
  #body #main #pageContent article .simple-modal__content {
    max-width: 1240px;
  }
}
#body #main #pageContent article advanced-reports .unselectFields {
  background-color: tomato;
}
#body #main #pageContent article #table-order .manage-field-results-order .field-order-header {
  margin-bottom: 10px;
}
#body #main #pageContent article #table-order .manage-field-results-order .field-order-header div:nth-child(2) {
  width: 250px;
}
#body #main #pageContent article #table-order .manage-field-results-order .field-order-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
#body #main #pageContent article #table-order .manage-field-results-order .field-order-single-field {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#body #main #pageContent article #table-order .manage-field-results-order .field-order-single-field table-order select {
  width: 240px;
}
#body #main #pageContent article #table-order .manage-field-results-order .field-order-single-field div:nth-child(2) {
  width: 250px;
}
@media only screen and (max-width: 367px) {
  #body #main #pageContent article #table-order .manage-field-results-order .field-order-header div:nth-child(2) {
    width: 100%;
  }
  #body #main #pageContent article #table-order .manage-field-results-order .field-order-single-field table-order select {
    width: 100%;
  }
  #body #main #pageContent article #table-order .manage-field-results-order .field-order-single-field div:nth-child(2) {
    width: 100%;
  }
}
@media only screen and (min-width: 367px) and (max-width: 767px) {
  #body #main #pageContent article #table-order .manage-field-results-order .field-order-header div:nth-child(2) {
    width: 100%;
  }
  #body #main #pageContent article #table-order .manage-field-results-order .field-order-single-field table-order select {
    width: 100%;
  }
  #body #main #pageContent article #table-order .manage-field-results-order .field-order-single-field div:nth-child(2) {
    width: 100%;
  }
}
#body #main #pageContent article .edit-theme input[type=checkbox] {
  width: auto;
  float: none;
  clear: both;
  margin: auto;
  margin-top: 5px;
  margin-right: 5px;
}
#body #main #pageContent article .menu-logo-image .customer-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
  align-content: center;
  background: none;
}
#body #main #pageContent article .blocworx-content .preview-image {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  flex-direction: row;
  flex-wrap: nowrap;
  padding: 50px;
  background: rgba(255, 255, 255, 0.0784313725);
  width: 100%;
}
#body #main #pageContent article .blocworx-content .preview-header {
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: rgba(52, 58, 64, 0.8117647059);
}
#body #main div.skin-setting-dark #pageContent article .blocworx-content .preview-header {
  background-color: rgba(52, 58, 64, 0.8117647059);
}
#body #main div.skin-setting-bright #pageContent article .blocworx-content .preview-header {
  background-color: rgba(69, 80, 82, 0.7098039216);
}

#body #main #pageContent header .header {
  background-size: cover;
  width: 100%;
  max-width: 1900px;
  background: url("images/header-bg.png");
  background-repeat: no-repeat;
  border-bottom: 3px solid #ee3124;
  box-sizing: border-box;
  background-size: cover;
  min-height: 90px;
  height: 100%;
  background-position-x: center;
  background-position-y: center;
}
#body #main #pageContent header .header ul {
  float: right;
  margin: 28px 0px 0px 0px;
  padding: 0px;
}
#body #main #pageContent header .header ul li {
  float: left;
  margin-right: 29px;
  list-style: none;
}
#body #main #pageContent header .header ul li a {
  text-decoration: none;
}
#body #main #pageContent header .header ul li a span {
  font-size: 19px;
  color: #fff;
  position: relative;
}
#body #main #pageContent header .header ul li:hover a span {
  text-shadow: 0px 0px 0px #fff;
}
#body #main #pageContent header .header ul li:last-child {
  margin-right: 0px;
}
@media (min-width: 1340px) and (max-width: 2570px) {
  #body #main #pageContent header .header {
    max-width: 2570px;
  }
}
@media (min-width: 2570px) and (max-width: 3850px) {
  #body #main #pageContent header .header {
    max-width: 3850px;
  }
}
#body #main #pageContent .header-language {
  top: -10px;
}
#body #main #pageContent blocworx-languages {
  display: inline-block;
  position: relative;
  width: 100%;
}
#body #main #pageContent blocworx-languages .switch .fa-language {
  font-size: 40px;
}
#body #main #pageContent blocworx-languages .switch .language-list {
  border: 1px dashed;
  padding: 10px;
  display: inline-block;
  float: left;
  z-index: 9999;
  position: absolute;
  margin: 0px;
  top: 40px;
  right: -40px;
}
#body #main #pageContent blocworx-languages .switch .language-list li {
  padding: 5px;
  float: none;
  cursor: pointer;
  margin: 0px;
}
#body #main #pageContent blocworx-languages .select-options {
  margin: 10px 0px;
}
#body #main div.skin-setting-dark #pageContent blocworx-languages .fa-language {
  color: #fff;
}
#body #main div.skin-setting-dark #pageContent blocworx-languages .language-list {
  background-color: #232d2f;
}
#body #main div.skin-setting-dark #pageContent blocworx-languages .language-list li:hover {
  background-color: #0C3A42;
  color: #fff;
}
#body #main div.skin-setting-bright #pageContent blocworx-languages .fa-language {
  color: #fff;
}
#body #main div.skin-setting-bright #pageContent blocworx-languages .language-list {
  background-color: #fff;
}
#body #main div.skin-setting-bright #pageContent blocworx-languages .language-list li:hover {
  background-color: #0C3A42;
  color: #fff;
}

/*
  TODO The modal is outside the component, find a better way to handle this, things wont work when inside the above part
 */
.edit-field-actions {
  overflow-y: scroll;
  overflow-x: hidden;
}
.edit-field-actions tbody tr {
  background: none !important;
}

#blocworx external-bloc-data .full-size-block {
  width: 100%;
}

.template {
  height: 100%;
  display: flex;
  align-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
  justify-content: center;
}

.loading-view {
  display: flex;
  font-size: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.scheduler-wrapper {
  margin-top: 10px;
  margin-bottom: 10px;
}

text-field-in-form {
  width: 100%;
  float: left;
  position: relative;
}

#body.bright .blocworx-popup .mat-select-value {
  color: #004854;
}
#body.dark .blocworx-popup .mat-select-value {
  color: #f0f4f5;
}

.blocworx-popup .mat-input-element {
  padding: 9px;
}
.blocworx-popup .mat-datepicker-input {
  padding: 10px;
  width: 100%;
}
.blocworx-popup .row {
  width: 100%;
}
.blocworx-popup .mat-form-field {
  width: 100%;
}
.blocworx-popup .mat-datepicker-toggle-default-icon {
  transform: scale(2);
  position: relative;
  right: 20px;
  bottom: 13px;
}
.blocworx-popup .mat-form-field-appearance-fill .mat-form-field-flex {
  border-radius: 0px !important;
  padding: 0px;
  background: none;
}
.blocworx-popup select.mat-input-element {
  padding: 10px !important;
  margin-top: 19px;
}
.blocworx-popup .mat-form-field-appearance-legacy .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px);
}
.blocworx-popup .mat-calendar-table tr {
  background: none !important;
}
.blocworx-popup .mat-calendar-body-selected {
  background: none !important;
  color: rgba(0, 0, 0, 0.87);
}
.blocworx-popup .mat-calendar-body-cell:hover, .blocworx-popup .mat-calendar-body-cell-content:hover {
  background: #ddd !important;
  border-radius: 0px !important;
}
.blocworx-popup .mat-focus-indicator.mat-icon-button.mat-button-base {
  background: none !important;
}
.blocworx-popup .mat-focus-indicator.mat-calendar-period-button {
  background: none !important;
}

.second-date-picker-popup {
  background: white;
  left: auto !important;
  position: fixed;
}

.mat-form-field-type-mat-native-select .mat-form-field-infix:after {
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 7px solid;
  top: 55%;
  right: 6px;
}

calendar-resource-planning-in-form .responsive-calendar-view .week-controls, .calendar-resource-planning-field-popup .responsive-calendar-view .week-controls {
  width: 100% !important;
}
calendar-resource-planning-in-form .responsive-calendar-view .header-container, calendar-resource-planning-in-form .responsive-calendar-view .roster-container, .calendar-resource-planning-field-popup .responsive-calendar-view .header-container, .calendar-resource-planning-field-popup .responsive-calendar-view .roster-container {
  width: 100% !important;
}
calendar-resource-planning-in-form .responsive-calendar-view .weekdays li, calendar-resource-planning-in-form .responsive-calendar-view .days li, .calendar-resource-planning-field-popup .responsive-calendar-view .weekdays li, .calendar-resource-planning-field-popup .responsive-calendar-view .days li {
  width: 14.28% !important;
}
calendar-resource-planning-in-form .responsive-calendar-view span.entry-count-text, .calendar-resource-planning-field-popup .responsive-calendar-view span.entry-count-text {
  display: none !important;
}
calendar-resource-planning-in-form .responsive-calendar-view .count-data-element, .calendar-resource-planning-field-popup .responsive-calendar-view .count-data-element {
  width: 100%;
}
calendar-resource-planning-in-form .count-data-element span, .calendar-resource-planning-field-popup .count-data-element span {
  display: inline !important;
}
calendar-resource-planning-in-form .count-data-element, calendar-resource-planning-in-form .count-data-element span, .calendar-resource-planning-field-popup .count-data-element, .calendar-resource-planning-field-popup .count-data-element span {
  color: #fff !important;
  font-weight: bold !important;
}
calendar-resource-planning-in-form .zoomable-div, .calendar-resource-planning-field-popup .zoomable-div {
  touch-action: none; /* Disable browser default zoom */
  transform-origin: center;
  transition: transform 0.1s; /* Smooth transition for zoom effect */
}
calendar-resource-planning-in-form .refresh-calendar-button, .calendar-resource-planning-field-popup .refresh-calendar-button {
  float: left;
  margin-bottom: 20px;
}
calendar-resource-planning-in-form .full-screen-true, .calendar-resource-planning-field-popup .full-screen-true {
  position: fixed;
  position: fixed !important;
  z-index: 99999;
  height: 100%;
  top: 0px;
  left: 0px;
  overflow: auto;
  width: 100%;
}
calendar-resource-planning-in-form .fas.fa-window-close, .calendar-resource-planning-field-popup .fas.fa-window-close {
  font-size: 29px;
  position: fixed;
  right: 20px;
  top: 15px;
  color: #2c3e50;
  cursor: pointer;
}
calendar-resource-planning-in-form .fas.fa-expand, .calendar-resource-planning-field-popup .fas.fa-expand {
  color: #2c3e50;
  position: absolute;
  left: 108px;
  font-size: 29px;
  top: 8px;
  cursor: pointer;
}
calendar-resource-planning-in-form .calendar-resource-planning-field, .calendar-resource-planning-field-popup .calendar-resource-planning-field {
  background: #f5f6f8;
  border-top: 6px solid #fac863;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  width: 100%;
}
calendar-resource-planning-in-form .week-controls button, .calendar-resource-planning-field-popup .week-controls button {
  background: #2c3e50 !important;
  border: none;
  font-size: 18px;
  padding: 3px 16px;
  border-radius: 5px 0px 0px 5px;
  float: left;
}
calendar-resource-planning-in-form .week-controls button + button, .calendar-resource-planning-field-popup .week-controls button + button {
  border-radius: 0px 5px 5px 0px;
}
calendar-resource-planning-in-form .week-controls button:hover, .calendar-resource-planning-field-popup .week-controls button:hover {
  background-color: #001224 !important;
}
calendar-resource-planning-in-form .day-dragged-over, .calendar-resource-planning-field-popup .day-dragged-over {
  background: #9999ca;
}
calendar-resource-planning-in-form .month-calendar-view, calendar-resource-planning-in-form .resource-planning-view, calendar-resource-planning-in-form .single-day-with-hours, calendar-resource-planning-in-form .vertical-month-view, calendar-resource-planning-in-form .counted-calendar-data-popup, .calendar-resource-planning-field-popup .month-calendar-view, .calendar-resource-planning-field-popup .resource-planning-view, .calendar-resource-planning-field-popup .single-day-with-hours, .calendar-resource-planning-field-popup .vertical-month-view, .calendar-resource-planning-field-popup .counted-calendar-data-popup {
  position: relative;
}
calendar-resource-planning-in-form .month-calendar-view .calendar-item, calendar-resource-planning-in-form .resource-planning-view .calendar-item, calendar-resource-planning-in-form .single-day-with-hours .calendar-item, calendar-resource-planning-in-form .vertical-month-view .calendar-item, calendar-resource-planning-in-form .counted-calendar-data-popup .calendar-item, .calendar-resource-planning-field-popup .month-calendar-view .calendar-item, .calendar-resource-planning-field-popup .resource-planning-view .calendar-item, .calendar-resource-planning-field-popup .single-day-with-hours .calendar-item, .calendar-resource-planning-field-popup .vertical-month-view .calendar-item, .calendar-resource-planning-field-popup .counted-calendar-data-popup .calendar-item {
  overflow-y: hidden;
}
calendar-resource-planning-in-form .month-calendar-view .calendar-item.record-colour-red, calendar-resource-planning-in-form .resource-planning-view .calendar-item.record-colour-red, calendar-resource-planning-in-form .single-day-with-hours .calendar-item.record-colour-red, calendar-resource-planning-in-form .vertical-month-view .calendar-item.record-colour-red, calendar-resource-planning-in-form .counted-calendar-data-popup .calendar-item.record-colour-red, .calendar-resource-planning-field-popup .month-calendar-view .calendar-item.record-colour-red, .calendar-resource-planning-field-popup .resource-planning-view .calendar-item.record-colour-red, .calendar-resource-planning-field-popup .single-day-with-hours .calendar-item.record-colour-red, .calendar-resource-planning-field-popup .vertical-month-view .calendar-item.record-colour-red, .calendar-resource-planning-field-popup .counted-calendar-data-popup .calendar-item.record-colour-red {
  background: #ee3124;
  color: #fff;
}
calendar-resource-planning-in-form .month-calendar-view .calendar-item.record-colour-green, calendar-resource-planning-in-form .resource-planning-view .calendar-item.record-colour-green, calendar-resource-planning-in-form .single-day-with-hours .calendar-item.record-colour-green, calendar-resource-planning-in-form .vertical-month-view .calendar-item.record-colour-green, calendar-resource-planning-in-form .counted-calendar-data-popup .calendar-item.record-colour-green, .calendar-resource-planning-field-popup .month-calendar-view .calendar-item.record-colour-green, .calendar-resource-planning-field-popup .resource-planning-view .calendar-item.record-colour-green, .calendar-resource-planning-field-popup .single-day-with-hours .calendar-item.record-colour-green, .calendar-resource-planning-field-popup .vertical-month-view .calendar-item.record-colour-green, .calendar-resource-planning-field-popup .counted-calendar-data-popup .calendar-item.record-colour-green {
  background: #10b43e;
  color: #fff;
}
calendar-resource-planning-in-form .month-calendar-view .calendar-item.record-colour-yellow, calendar-resource-planning-in-form .resource-planning-view .calendar-item.record-colour-yellow, calendar-resource-planning-in-form .single-day-with-hours .calendar-item.record-colour-yellow, calendar-resource-planning-in-form .vertical-month-view .calendar-item.record-colour-yellow, calendar-resource-planning-in-form .counted-calendar-data-popup .calendar-item.record-colour-yellow, .calendar-resource-planning-field-popup .month-calendar-view .calendar-item.record-colour-yellow, .calendar-resource-planning-field-popup .resource-planning-view .calendar-item.record-colour-yellow, .calendar-resource-planning-field-popup .single-day-with-hours .calendar-item.record-colour-yellow, .calendar-resource-planning-field-popup .vertical-month-view .calendar-item.record-colour-yellow, .calendar-resource-planning-field-popup .counted-calendar-data-popup .calendar-item.record-colour-yellow {
  background: #d8d807;
  color: #fff;
}
calendar-resource-planning-in-form .month-calendar-view .calendar-item.record-colour-blue, calendar-resource-planning-in-form .resource-planning-view .calendar-item.record-colour-blue, calendar-resource-planning-in-form .single-day-with-hours .calendar-item.record-colour-blue, calendar-resource-planning-in-form .vertical-month-view .calendar-item.record-colour-blue, calendar-resource-planning-in-form .counted-calendar-data-popup .calendar-item.record-colour-blue, .calendar-resource-planning-field-popup .month-calendar-view .calendar-item.record-colour-blue, .calendar-resource-planning-field-popup .resource-planning-view .calendar-item.record-colour-blue, .calendar-resource-planning-field-popup .single-day-with-hours .calendar-item.record-colour-blue, .calendar-resource-planning-field-popup .vertical-month-view .calendar-item.record-colour-blue, .calendar-resource-planning-field-popup .counted-calendar-data-popup .calendar-item.record-colour-blue {
  background: #2761B1;
  color: #fff;
}
calendar-resource-planning-in-form .month-calendar-view .calendar-item.record-colour-orange, calendar-resource-planning-in-form .resource-planning-view .calendar-item.record-colour-orange, calendar-resource-planning-in-form .single-day-with-hours .calendar-item.record-colour-orange, calendar-resource-planning-in-form .vertical-month-view .calendar-item.record-colour-orange, calendar-resource-planning-in-form .counted-calendar-data-popup .calendar-item.record-colour-orange, .calendar-resource-planning-field-popup .month-calendar-view .calendar-item.record-colour-orange, .calendar-resource-planning-field-popup .resource-planning-view .calendar-item.record-colour-orange, .calendar-resource-planning-field-popup .single-day-with-hours .calendar-item.record-colour-orange, .calendar-resource-planning-field-popup .vertical-month-view .calendar-item.record-colour-orange, .calendar-resource-planning-field-popup .counted-calendar-data-popup .calendar-item.record-colour-orange {
  background: #faa21a;
  color: #fff;
}
calendar-resource-planning-in-form .month-calendar-view .calendar-item.record-colour-sherpa-blue, calendar-resource-planning-in-form .resource-planning-view .calendar-item.record-colour-sherpa-blue, calendar-resource-planning-in-form .single-day-with-hours .calendar-item.record-colour-sherpa-blue, calendar-resource-planning-in-form .vertical-month-view .calendar-item.record-colour-sherpa-blue, calendar-resource-planning-in-form .counted-calendar-data-popup .calendar-item.record-colour-sherpa-blue, .calendar-resource-planning-field-popup .month-calendar-view .calendar-item.record-colour-sherpa-blue, .calendar-resource-planning-field-popup .resource-planning-view .calendar-item.record-colour-sherpa-blue, .calendar-resource-planning-field-popup .single-day-with-hours .calendar-item.record-colour-sherpa-blue, .calendar-resource-planning-field-popup .vertical-month-view .calendar-item.record-colour-sherpa-blue, .calendar-resource-planning-field-popup .counted-calendar-data-popup .calendar-item.record-colour-sherpa-blue {
  background: #004854;
  color: #fff;
}
calendar-resource-planning-in-form .month-calendar-view .calendar-item.record-colour-cyan, calendar-resource-planning-in-form .resource-planning-view .calendar-item.record-colour-cyan, calendar-resource-planning-in-form .single-day-with-hours .calendar-item.record-colour-cyan, calendar-resource-planning-in-form .vertical-month-view .calendar-item.record-colour-cyan, calendar-resource-planning-in-form .counted-calendar-data-popup .calendar-item.record-colour-cyan, .calendar-resource-planning-field-popup .month-calendar-view .calendar-item.record-colour-cyan, .calendar-resource-planning-field-popup .resource-planning-view .calendar-item.record-colour-cyan, .calendar-resource-planning-field-popup .single-day-with-hours .calendar-item.record-colour-cyan, .calendar-resource-planning-field-popup .vertical-month-view .calendar-item.record-colour-cyan, .calendar-resource-planning-field-popup .counted-calendar-data-popup .calendar-item.record-colour-cyan {
  background: #45D2D7;
  color: #fff;
}
calendar-resource-planning-in-form .month-calendar-view .calendar-item.record-colour-purple, calendar-resource-planning-in-form .resource-planning-view .calendar-item.record-colour-purple, calendar-resource-planning-in-form .single-day-with-hours .calendar-item.record-colour-purple, calendar-resource-planning-in-form .vertical-month-view .calendar-item.record-colour-purple, calendar-resource-planning-in-form .counted-calendar-data-popup .calendar-item.record-colour-purple, .calendar-resource-planning-field-popup .month-calendar-view .calendar-item.record-colour-purple, .calendar-resource-planning-field-popup .resource-planning-view .calendar-item.record-colour-purple, .calendar-resource-planning-field-popup .single-day-with-hours .calendar-item.record-colour-purple, .calendar-resource-planning-field-popup .vertical-month-view .calendar-item.record-colour-purple, .calendar-resource-planning-field-popup .counted-calendar-data-popup .calendar-item.record-colour-purple {
  background: #9B34A1;
  color: #fff;
}
calendar-resource-planning-in-form .single-day-with-hours, calendar-resource-planning-in-form .vertical-month-view, calendar-resource-planning-in-form .counted-calendar-data-popup, .calendar-resource-planning-field-popup .single-day-with-hours, .calendar-resource-planning-field-popup .vertical-month-view, .calendar-resource-planning-field-popup .counted-calendar-data-popup {
  overflow: auto;
}
calendar-resource-planning-in-form .single-day-with-hours .header-container, calendar-resource-planning-in-form .vertical-month-view .header-container, calendar-resource-planning-in-form .counted-calendar-data-popup .header-container, .calendar-resource-planning-field-popup .single-day-with-hours .header-container, .calendar-resource-planning-field-popup .vertical-month-view .header-container, .calendar-resource-planning-field-popup .counted-calendar-data-popup .header-container {
  color: #2c3e50;
  font-size: 21px;
  position: absolute;
  top: 10px;
  left: 156px;
}
calendar-resource-planning-in-form .single-day-with-hours .week-controls, calendar-resource-planning-in-form .vertical-month-view .week-controls, calendar-resource-planning-in-form .counted-calendar-data-popup .week-controls, .calendar-resource-planning-field-popup .single-day-with-hours .week-controls, .calendar-resource-planning-field-popup .vertical-month-view .week-controls, .calendar-resource-planning-field-popup .counted-calendar-data-popup .week-controls {
  width: 100%;
}
calendar-resource-planning-in-form .single-day-with-hours .calendar-item, calendar-resource-planning-in-form .vertical-month-view .calendar-item, calendar-resource-planning-in-form .counted-calendar-data-popup .calendar-item, .calendar-resource-planning-field-popup .single-day-with-hours .calendar-item, .calendar-resource-planning-field-popup .vertical-month-view .calendar-item, .calendar-resource-planning-field-popup .counted-calendar-data-popup .calendar-item {
  background: #5d5db9;
  color: #fff;
  box-sizing: border-box;
  padding: 5px;
  margin-bottom: 2px;
  font-size: 12px;
  z-index: 1;
  width: 100%;
}
calendar-resource-planning-in-form .single-day-with-hours .calendar-item.day-dragged-over, calendar-resource-planning-in-form .vertical-month-view .calendar-item.day-dragged-over, calendar-resource-planning-in-form .counted-calendar-data-popup .calendar-item.day-dragged-over, .calendar-resource-planning-field-popup .single-day-with-hours .calendar-item.day-dragged-over, .calendar-resource-planning-field-popup .vertical-month-view .calendar-item.day-dragged-over, .calendar-resource-planning-field-popup .counted-calendar-data-popup .calendar-item.day-dragged-over {
  opacity: 0.5;
}
calendar-resource-planning-in-form .single-day-with-hours .hours, calendar-resource-planning-in-form .single-day-with-hours .days, calendar-resource-planning-in-form .vertical-month-view .hours, calendar-resource-planning-in-form .vertical-month-view .days, calendar-resource-planning-in-form .counted-calendar-data-popup .hours, calendar-resource-planning-in-form .counted-calendar-data-popup .days, .calendar-resource-planning-field-popup .single-day-with-hours .hours, .calendar-resource-planning-field-popup .single-day-with-hours .days, .calendar-resource-planning-field-popup .vertical-month-view .hours, .calendar-resource-planning-field-popup .vertical-month-view .days, .calendar-resource-planning-field-popup .counted-calendar-data-popup .hours, .calendar-resource-planning-field-popup .counted-calendar-data-popup .days {
  padding: 0px;
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
}
calendar-resource-planning-in-form .single-day-with-hours .hours li, calendar-resource-planning-in-form .single-day-with-hours .days li, calendar-resource-planning-in-form .vertical-month-view .hours li, calendar-resource-planning-in-form .vertical-month-view .days li, calendar-resource-planning-in-form .counted-calendar-data-popup .hours li, calendar-resource-planning-in-form .counted-calendar-data-popup .days li, .calendar-resource-planning-field-popup .single-day-with-hours .hours li, .calendar-resource-planning-field-popup .single-day-with-hours .days li, .calendar-resource-planning-field-popup .vertical-month-view .hours li, .calendar-resource-planning-field-popup .vertical-month-view .days li, .calendar-resource-planning-field-popup .counted-calendar-data-popup .hours li, .calendar-resource-planning-field-popup .counted-calendar-data-popup .days li {
  border: 1px solid #ddd;
  padding: 1.5%;
  box-sizing: border-box;
  width: 20%;
  flex: 1 0 25%;
}
calendar-resource-planning-in-form .single-day-with-hours .hours li span, calendar-resource-planning-in-form .single-day-with-hours .days li span, calendar-resource-planning-in-form .vertical-month-view .hours li span, calendar-resource-planning-in-form .vertical-month-view .days li span, calendar-resource-planning-in-form .counted-calendar-data-popup .hours li span, calendar-resource-planning-in-form .counted-calendar-data-popup .days li span, .calendar-resource-planning-field-popup .single-day-with-hours .hours li span, .calendar-resource-planning-field-popup .single-day-with-hours .days li span, .calendar-resource-planning-field-popup .vertical-month-view .hours li span, .calendar-resource-planning-field-popup .vertical-month-view .days li span, .calendar-resource-planning-field-popup .counted-calendar-data-popup .hours li span, .calendar-resource-planning-field-popup .counted-calendar-data-popup .days li span {
  color: #2c3e50;
  font-size: 15px;
  font-weight: normal;
  margin-bottom: 10px;
  width: 100%;
  display: block;
}
calendar-resource-planning-in-form .single-day-with-hours .days li, calendar-resource-planning-in-form .vertical-month-view .days li, calendar-resource-planning-in-form .counted-calendar-data-popup .days li, .calendar-resource-planning-field-popup .single-day-with-hours .days li, .calendar-resource-planning-field-popup .vertical-month-view .days li, .calendar-resource-planning-field-popup .counted-calendar-data-popup .days li {
  width: 100%;
  flex: 1 0 100%;
}
calendar-resource-planning-in-form .weekdays li, calendar-resource-planning-in-form .days li, .calendar-resource-planning-field-popup .weekdays li, .calendar-resource-planning-field-popup .days li {
  display: inline-block;
  width: 121px;
  height: 100%;
  box-sizing: border-box;
  color: #1f1f22;
  font-size: 13px;
  min-height: 30px;
  border-right: 1px solid #ddd;
}
calendar-resource-planning-in-form .week-controls, .calendar-resource-planning-field-popup .week-controls {
  padding: 10px;
  width: 1100px;
  clear: both;
  overflow: hidden;
}
calendar-resource-planning-in-form .month-and-year, .calendar-resource-planning-field-popup .month-and-year {
  color: #2c3e50;
  font-size: 17px;
  position: absolute;
  left: 169px;
  top: 13px;
}
calendar-resource-planning-in-form .month-calendar-view, .calendar-resource-planning-field-popup .month-calendar-view {
  width: 100%;
  text-align: left;
  overflow: auto;
}
calendar-resource-planning-in-form .month-calendar-view .header-container, calendar-resource-planning-in-form .month-calendar-view .roster-container, .calendar-resource-planning-field-popup .month-calendar-view .header-container, .calendar-resource-planning-field-popup .month-calendar-view .roster-container {
  width: 1197px;
}
calendar-resource-planning-in-form .month-calendar-view .weekdays > li, .calendar-resource-planning-field-popup .month-calendar-view .weekdays > li {
  text-align: center;
}
calendar-resource-planning-in-form .month-calendar-view ul.weekdays, .calendar-resource-planning-field-popup .month-calendar-view ul.weekdays {
  padding: 0px;
  margin-bottom: 0px;
  border-bottom: 4px solid #ddd;
}
calendar-resource-planning-in-form .month-calendar-view .roster-container, .calendar-resource-planning-field-popup .month-calendar-view .roster-container {
  overflow: hidden;
}
calendar-resource-planning-in-form .month-calendar-view .day-number, .calendar-resource-planning-field-popup .month-calendar-view .day-number {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  place-items: center;
  display: grid;
}
calendar-resource-planning-in-form .month-calendar-view ul.days, calendar-resource-planning-in-form .month-calendar-view ul.weekdays, .calendar-resource-planning-field-popup .month-calendar-view ul.days, .calendar-resource-planning-field-popup .month-calendar-view ul.weekdays {
  padding: 0px;
}
calendar-resource-planning-in-form .month-calendar-view .month-calendar-item-container, .calendar-resource-planning-field-popup .month-calendar-view .month-calendar-item-container {
  position: relative;
  display: block;
  /*height: 100%;*/
}
calendar-resource-planning-in-form .month-calendar-view .calendar-item, .calendar-resource-planning-field-popup .month-calendar-view .calendar-item {
  background: #5d5db9;
  color: #fff;
  box-sizing: border-box;
  padding: 5px;
  margin-bottom: 2px;
  font-size: 12px;
  position: absolute;
  z-index: 1;
}
calendar-resource-planning-in-form .month-calendar-view .calendar-item.day-dragged-over, .calendar-resource-planning-field-popup .month-calendar-view .calendar-item.day-dragged-over {
  opacity: 0.5;
}
calendar-resource-planning-in-form .month-calendar-view .calendar-item .handle, .calendar-resource-planning-field-popup .month-calendar-view .calendar-item .handle {
  height: 20px;
  width: 10px;
  float: right;
  cursor: ew-resize;
  position: absolute;
  right: 0px;
  top: 0px;
  height: 100%;
}
calendar-resource-planning-in-form .month-calendar-view .month, .calendar-resource-planning-field-popup .month-calendar-view .month {
  padding: 20px;
  background: #1abc9c;
  color: white;
}
calendar-resource-planning-in-form .month-calendar-view .month button, .calendar-resource-planning-field-popup .month-calendar-view .month button {
  color: white;
  background: none;
  border: none;
  font-size: 18px;
}
calendar-resource-planning-in-form .month-calendar-view .weekdays li,
calendar-resource-planning-in-form .month-calendar-view .days li, .calendar-resource-planning-field-popup .month-calendar-view .weekdays li,
.calendar-resource-planning-field-popup .month-calendar-view .days li {
  display: inline-block;
  width: 171px;
}
calendar-resource-planning-in-form .month-calendar-view .days li, .calendar-resource-planning-field-popup .month-calendar-view .days li {
  position: relative;
  min-height: 83px;
  border-bottom: 1px solid #ddd;
  margin-top: 0px;
  padding-top: 0px;
  margin-top: 0px;
  vertical-align: top;
}
calendar-resource-planning-in-form .resource-planning-view, .calendar-resource-planning-field-popup .resource-planning-view {
  overflow: auto;
}
calendar-resource-planning-in-form .resource-planning-view .header-spacer, calendar-resource-planning-in-form .resource-planning-view .roster, .calendar-resource-planning-field-popup .resource-planning-view .header-spacer, .calendar-resource-planning-field-popup .resource-planning-view .roster {
  flex-basis: 150px;
}
calendar-resource-planning-in-form .resource-planning-view .calendar, .calendar-resource-planning-field-popup .resource-planning-view .calendar {
  width: 100%;
  text-align: left;
}
calendar-resource-planning-in-form .resource-planning-view .calendar-item, .calendar-resource-planning-field-popup .resource-planning-view .calendar-item {
  background: #5d5db9;
  color: #fff;
  box-sizing: border-box;
  padding: 5px;
  margin-bottom: 2px;
  font-size: 12px;
  position: absolute;
  z-index: 1;
  max-height: 30px;
  overflow: hidden;
}
calendar-resource-planning-in-form .resource-planning-view .weekdays, .calendar-resource-planning-field-popup .resource-planning-view .weekdays {
  margin: 0;
  padding: 10px 0;
  flex-basis: 87.5%;
}
calendar-resource-planning-in-form .resource-planning-view .weekdays, calendar-resource-planning-in-form .resource-planning-view .days, .calendar-resource-planning-field-popup .resource-planning-view .weekdays, .calendar-resource-planning-field-popup .resource-planning-view .days {
  position: relative;
}
calendar-resource-planning-in-form .resource-planning-view .weekdays li, .calendar-resource-planning-field-popup .resource-planning-view .weekdays li {
  text-align: center;
}
calendar-resource-planning-in-form .resource-planning-view .days li, .calendar-resource-planning-field-popup .resource-planning-view .days li {
  border-right: 1px solid #ddd;
}
calendar-resource-planning-in-form .resource-planning-view .days, .calendar-resource-planning-field-popup .resource-planning-view .days {
  padding: 0px 0;
  margin: 0;
  border-bottom: 1px solid #ddd;
}
calendar-resource-planning-in-form .resource-planning-view .header-container, .calendar-resource-planning-field-popup .resource-planning-view .header-container {
  display: flex;
  width: 1100px;
}
calendar-resource-planning-in-form .resource-planning-view .header-spacer, .calendar-resource-planning-field-popup .resource-planning-view .header-spacer {
  flex-basis: 12.5%; /* This should match the width of the left-side-data-item */
}
calendar-resource-planning-in-form .resource-planning-view .roster-container, .calendar-resource-planning-field-popup .resource-planning-view .roster-container {
  display: flex;
  width: 1100px;
}
calendar-resource-planning-in-form .resource-planning-view .roster, .calendar-resource-planning-field-popup .resource-planning-view .roster {
  display: flex;
  flex-direction: column;
  flex-basis: 12.5%;
}
calendar-resource-planning-in-form .resource-planning-view .roster-area, .calendar-resource-planning-field-popup .resource-planning-view .roster-area {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 87.5%;
  overflow: hidden;
}
calendar-resource-planning-in-form .resource-planning-view .left-side-data-item, .calendar-resource-planning-field-popup .resource-planning-view .left-side-data-item {
  box-sizing: border-box;
  color: #1f1f22;
  font-size: 14px;
  border-bottom: 1px solid #ddd;
  min-height: 37.5px;
  padding-top: 7px;
  padding-left: 10px;
}
calendar-resource-planning-in-form .resource-planning-view .handle, .calendar-resource-planning-field-popup .resource-planning-view .handle {
  height: 20px;
  width: 10px;
  float: right;
  cursor: ew-resize;
}
calendar-resource-planning-in-form .resource-planning-view .handle, .calendar-resource-planning-field-popup .resource-planning-view .handle {
  float: unset;
  height: 100%;
  display: block;
  position: absolute;
  right: 0px;
  top: 0px;
}
calendar-resource-planning-in-form .resource-planning-view.with-hours .roster, calendar-resource-planning-in-form .resource-planning-view.with-hours .header-spacer, .calendar-resource-planning-field-popup .resource-planning-view.with-hours .roster, .calendar-resource-planning-field-popup .resource-planning-view.with-hours .header-spacer {
  flex-basis: 200px;
}
calendar-resource-planning-in-form .resource-planning-view.with-hours .roster-area, .calendar-resource-planning-field-popup .resource-planning-view.with-hours .roster-area {
  flex-grow: 0;
  flex-basis: unset;
}
calendar-resource-planning-in-form .resource-planning-view.with-hours .roster-container, calendar-resource-planning-in-form .resource-planning-view.with-hours .header-container, .calendar-resource-planning-field-popup .resource-planning-view.with-hours .roster-container, .calendar-resource-planning-field-popup .resource-planning-view.with-hours .header-container {
  width: 7000px;
}
calendar-resource-planning-in-form .resource-planning-view.with-hours.single-day .roster-container, calendar-resource-planning-in-form .resource-planning-view.with-hours.single-day .header-container, .calendar-resource-planning-field-popup .resource-planning-view.with-hours.single-day .roster-container, .calendar-resource-planning-field-popup .resource-planning-view.with-hours.single-day .header-container {
  width: 1180px;
}
calendar-resource-planning-in-form .resource-planning-view.with-hours.single-day .weekdays, .calendar-resource-planning-field-popup .resource-planning-view.with-hours.single-day .weekdays {
  flex-basis: 1000px;
}
calendar-resource-planning-in-form .resource-planning-view.with-hours .days, .calendar-resource-planning-field-popup .resource-planning-view.with-hours .days {
  min-height: 30px;
}
calendar-resource-planning-in-form .resource-planning-view.with-hours .weekdays, .calendar-resource-planning-field-popup .resource-planning-view.with-hours .weekdays {
  flex-basis: 6800px;
}
calendar-resource-planning-in-form .resource-planning-view.with-hours .weekdays .hours > li, .calendar-resource-planning-field-popup .resource-planning-view.with-hours .weekdays .hours > li {
  text-align: center;
}
calendar-resource-planning-in-form .resource-planning-view.with-hours .weekdays > li, .calendar-resource-planning-field-popup .resource-planning-view.with-hours .weekdays > li {
  text-align: left;
}
calendar-resource-planning-in-form .resource-planning-view.with-hours .weekdays > li, calendar-resource-planning-in-form .resource-planning-view.with-hours .days > li, .calendar-resource-planning-field-popup .resource-planning-view.with-hours .weekdays > li, .calendar-resource-planning-field-popup .resource-planning-view.with-hours .days > li {
  width: 960px;
  border-right: none;
}
calendar-resource-planning-in-form .resource-planning-view.with-hours .weekdays ul.hours, calendar-resource-planning-in-form .resource-planning-view.with-hours .days ul.hours, .calendar-resource-planning-field-popup .resource-planning-view.with-hours .weekdays ul.hours, .calendar-resource-planning-field-popup .resource-planning-view.with-hours .days ul.hours {
  padding-left: 0px;
  height: 100%;
}
calendar-resource-planning-in-form .resource-planning-view.with-hours .weekdays ul.hours > li, calendar-resource-planning-in-form .resource-planning-view.with-hours .days ul.hours > li, .calendar-resource-planning-field-popup .resource-planning-view.with-hours .weekdays ul.hours > li, .calendar-resource-planning-field-popup .resource-planning-view.with-hours .days ul.hours > li {
  width: 40px;
  height: 100%;
}
calendar-resource-planning-in-form .resource-planning-view.with-hours .weekdays ul.hours > li > div, calendar-resource-planning-in-form .resource-planning-view.with-hours .days ul.hours > li > div, .calendar-resource-planning-field-popup .resource-planning-view.with-hours .weekdays ul.hours > li > div, .calendar-resource-planning-field-popup .resource-planning-view.with-hours .days ul.hours > li > div {
  height: 100%;
}
calendar-resource-planning-in-form .calendar-back-button, .calendar-resource-planning-field-popup .calendar-back-button {
  min-height: 60px;
}
calendar-resource-planning-in-form .calendar-back-button span, .calendar-resource-planning-field-popup .calendar-back-button span {
  background: #2c3e50 !important;
  padding: 10px 31px;
  position: relative;
  left: 10px;
  border-radius: 5px;
  top: 14px;
}

@media (max-width: 979px) {
  .mat-calendar {
    top: -200px !important;
    background: white;
    left: auto !important;
    position: fixed;
  }
  .second-date-picker-popup {
    top: -400px !important;
  }
  calendar-resource-planning-in-form .single-day-with-hours .hours li {
    flex-basis: 50%;
  }
  calendar-resource-planning-in-form .vertical-month-view .days li {
    flex-basis: 100%;
  }
  calendar-resource-planning-in-form .vertical-month-view .header-container {
    font-size: 15px;
  }
  calendar-resource-planning-in-form .vertical-month-view .header-container-some-other-rule {
    font-size: 15px;
  }
}
autocomplete-search .mat-form-field-appearance-fill .mat-form-field-underline::before {
  height: 0px;
  background: none;
}
autocomplete-search .mat-form-field-appearance-fill .mat-form-field-flex {
  padding: 0px;
}
autocomplete-search .mat-form-field-appearance-fill .mat-form-field-ripple {
  height: 0px;
}
autocomplete-search .mat-input-element {
  padding: 10px;
  box-sizing: border-box;
  color: #000;
}
autocomplete-search .mat-input-element:focus {
  outline: auto;
}
autocomplete-search .mat-form-field {
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: fixed;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
}

#blocworx .multi-file-field-container .image-list-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding-right: 15%;
}
#blocworx .multi-file-field-container .image-list-container .single-file-or-image-container-inside-multi.full-screen {
  position: fixed;
  width: 90%;
  height: 90%;
  left: 5%;
  z-index: 9999;
  top: 5%;
  overflow: auto;
  display: block;
  border: none;
}
#blocworx .multi-file-field-container .image-list-container .single-file-or-image-container-inside-multi.full-screen .fas.fa-window-close {
  display: block;
  top: 5%;
  font-size: 40px;
}
#blocworx .multi-file-field-container .image-list-container .single-file-or-image-container-inside-multi.full-screen .fas.fa-window-close:before {
  border: 2px solid #fff;
  border-radius: 5px;
  background: #fff;
}
#blocworx .multi-file-field-container .image-list-container .single-file-or-image-container-inside-multi {
  flex-basis: 126px;
  box-sizing: border-box;
  padding: 5px;
  border: 1px solid #2a2f33;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-top: 21px;
  border-radius: 10px;
  margin-right: 15px;
}
#blocworx .multi-file-field-container .image-list-container .single-file-or-image-container-inside-multi .fas.fa-window-close {
  display: none;
}
#blocworx .multi-file-field-container .image-list-container .single-file-or-image-container-inside-multi img {
  width: 100%;
  cursor: pointer;
}
#blocworx .multi-file-field-container .image-list-container .single-file-or-image-container-inside-multi a {
  align-items: center;
  display: flex;
  justify-content: center;
  cursor: pointer;
}
#blocworx .multi-file-field-container .image-list-container .single-file-or-image-container-inside-multi a i.fa-file-alt {
  position: relative;
  bottom: auto;
  right: auto;
  color: white;
  font-size: 4em;
  display: block ruby;
  width: 100%;
  height: 100%;
}
#blocworx .multi-file-field-container .show-preview {
  position: relative;
  clear: both;
}
#blocworx .multi-file-field-container .show-preview i {
  cursor: pointer;
  position: absolute;
  bottom: 33px;
  right: 16px;
  background: white;
  font-size: 25px;
  background: none;
  color: #000;
}
#blocworx .multi-file-field-container .show-preview.full-screen i {
  position: fixed;
  top: 8%;
  right: 7%;
  z-index: 9999;
  border-radius: 5px;
  color: #000;
  bottom: auto;
}
#blocworx .multi-file-field-container .show-preview.full-screen {
  position: fixed;
  z-index: 999;
  top: 5%;
  width: 90%;
  left: 5%;
  height: 90%;
  overflow: auto;
}

@media (max-width: 979px) {
  #blocworx .multi-file-field-container .image-list-container .single-file-or-image-container-inside-multi {
    flex-basis: 90px;
  }
  #blocworx .multi-file-field-container button {
    display: inline;
    width: 100%;
    margin-left: 0px;
    margin-top: 3px;
  }
  #blocworx .multi-file-field-container input {
    width: 100%;
  }
}
#blocworx template-exporter .invisible-wrap.full-size-block {
  margin-bottom: 0px;
}
#blocworx template-exporter .export {
  padding: 4px 7px;
  border-radius: 9px;
  color: #fff;
  cursor: pointer;
}
#blocworx template-exporter .export.pdf {
  background: #c04343;
  color: #fff;
}
#blocworx template-exporter .export.doc {
  background: #5555c4;
  color: #fff;
}
#blocworx template-exporter .generated-pdf-button {
  display: none;
}
#blocworx template-exporter .template-box {
  margin-top: 20px;
}
#blocworx template-exporter .standard-box {
  text-align: left;
}
#blocworx template-exporter i {
  float: left;
}
#blocworx template-exporter download {
  position: relative;
  left: -80px;
  width: 200px;
  max-width: none;
}

data-edit-history-dialog .box-container .column {
  display: block;
  min-height: 600px;
}

.data-edit-history-popup div {
  max-width: 100% fit-content;
  overflow: auto;
}

.skin-setting-dark {
  background: #023038;
  color: #fff;
}
.skin-setting-dark .tox.tox-tinymce {
  border: 1px solid #011f25;
}
.skin-setting-dark .tox .tox-toolbar,
.skin-setting-dark .tox .tox-toolbar__overflow,
.skin-setting-dark .tox .tox-toolbar__primary,
.skin-setting-dark .tox div.tox.tinymce,
.skin-setting-dark .tox div.tox-menubar,
.skin-setting-dark .tox div.tox-editor-header,
.skin-setting-dark .tox div.tox-toolbar-overlord,
.skin-setting-dark .tox .tox-menu,
.skin-setting-dark .tox .tinymce-all-options.ng-pristine.ng-valid.ng-touched,
.skin-setting-dark .tox .tox-edit-area,
.skin-setting-dark .tox .tox-tinymce-aux .tox-toolbar__overflow,
.skin-setting-dark .tox .tox-statusbar {
  background-color: #011f25;
}
.skin-setting-dark .tox .tox-statusbar {
  border-top: 1px solid #011f25;
}
.skin-setting-dark .tox .tox-mbtn.tox-mbtn--select {
  color: white;
}
.skin-setting-dark .tox .tox-statusbar__branding svg,
.skin-setting-dark .tox .tox-tbtn svg {
  fill: white;
}
.skin-setting-dark .tox .tox-statusbar a,
.skin-setting-dark .tox .tox-statusbar__path-item,
.skin-setting-dark .tox .tox-statusbar__wordcount {
  color: white;
}
.skin-setting-dark .tox .tox-collection__item-label {
  color: black;
}

.skin-setting-bright {
  background: #fff;
  color: #626262;
}
.skin-setting-bright .tox.tox-tinymce {
  border: 1px solid #f0f4f5;
}
.skin-setting-bright .tox .tox-toolbar,
.skin-setting-bright .tox .tox-toolbar__overflow,
.skin-setting-bright .tox .tox-toolbar__primary,
.skin-setting-bright .tox div.tox.tinymce,
.skin-setting-bright .tox div.tox-menubar,
.skin-setting-bright .tox div.tox-editor-header,
.skin-setting-bright .tox div.tox-toolbar-overlord,
.skin-setting-bright .tox .tox-menu,
.skin-setting-bright .tox .tinymce-all-options.ng-pristine.ng-valid.ng-touched,
.skin-setting-bright .tox .tox-edit-area,
.skin-setting-bright .tox .tox-tinymce-aux .tox-toolbar__overflow,
.skin-setting-bright .tox .tox-statusbar {
  background-color: #f0f4f5;
}
.skin-setting-bright .tox .tox-statusbar {
  border-top: 1px solid #f0f4f5;
}
.skin-setting-bright .tox .tox-mbtn.tox-mbtn--select {
  color: black;
}
.skin-setting-bright .tox .tox-statusbar__branding svg,
.skin-setting-bright .tox .tox-tbtn svg {
  fill: black;
}
.skin-setting-bright .tox .tox-statusbar a,
.skin-setting-bright .tox .tox-statusbar__path-item,
.skin-setting-bright .tox .tox-statusbar__wordcount {
  color: black;
}
.skin-setting-bright .tox .tox-collection__item-label {
  color: black;
}

.tox.tox-silver-sink.tox-tinymce-aux {
  z-index: 9999999999 !important;
}

.skin-setting-dark {
  background: #023038;
  color: #fff;
}
.skin-setting-dark .tox.tox-tinymce {
  border: 1px solid #011f25;
}
.skin-setting-dark .tox .tox-toolbar,
.skin-setting-dark .tox .tox-toolbar__overflow,
.skin-setting-dark .tox .tox-toolbar__primary,
.skin-setting-dark .tox div.tox.tinymce,
.skin-setting-dark .tox div.tox-menubar,
.skin-setting-dark .tox div.tox-editor-header,
.skin-setting-dark .tox div.tox-toolbar-overlord,
.skin-setting-dark .tox .tox-menu,
.skin-setting-dark .tox .tinymce-all-options.ng-pristine.ng-valid.ng-touched,
.skin-setting-dark .tox .tox-edit-area,
.skin-setting-dark .tox .tox-tinymce-aux .tox-toolbar__overflow,
.skin-setting-dark .tox .tox-statusbar {
  background-color: #011f25;
}
.skin-setting-dark .tox .tox-statusbar {
  border-top: 1px solid #011f25;
}
.skin-setting-dark .tox .tox-mbtn.tox-mbtn--select {
  color: white;
}
.skin-setting-dark .tox .tox-statusbar__branding svg,
.skin-setting-dark .tox .tox-tbtn svg {
  fill: white;
}
.skin-setting-dark .tox .tox-statusbar a,
.skin-setting-dark .tox .tox-statusbar__path-item,
.skin-setting-dark .tox .tox-statusbar__wordcount {
  color: white;
}
.skin-setting-dark .tox .tox-collection__item-label {
  color: black;
}

.skin-setting-bright {
  background: #fff;
  color: #626262;
}
.skin-setting-bright .tox.tox-tinymce {
  border: 1px solid #f0f4f5;
}
.skin-setting-bright .tox .tox-toolbar,
.skin-setting-bright .tox .tox-toolbar__overflow,
.skin-setting-bright .tox .tox-toolbar__primary,
.skin-setting-bright .tox div.tox.tinymce,
.skin-setting-bright .tox div.tox-menubar,
.skin-setting-bright .tox div.tox-editor-header,
.skin-setting-bright .tox div.tox-toolbar-overlord,
.skin-setting-bright .tox .tox-menu,
.skin-setting-bright .tox .tinymce-all-options.ng-pristine.ng-valid.ng-touched,
.skin-setting-bright .tox .tox-edit-area,
.skin-setting-bright .tox .tox-tinymce-aux .tox-toolbar__overflow,
.skin-setting-bright .tox .tox-statusbar {
  background-color: #f0f4f5;
}
.skin-setting-bright .tox .tox-statusbar {
  border-top: 1px solid #f0f4f5;
}
.skin-setting-bright .tox .tox-mbtn.tox-mbtn--select {
  color: black;
}
.skin-setting-bright .tox .tox-statusbar__branding svg,
.skin-setting-bright .tox .tox-tbtn svg {
  fill: black;
}
.skin-setting-bright .tox .tox-statusbar a,
.skin-setting-bright .tox .tox-statusbar__path-item,
.skin-setting-bright .tox .tox-statusbar__wordcount {
  color: black;
}
.skin-setting-bright .tox .tox-collection__item-label {
  color: black;
}

.button-title {
  font-size: 18px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: rgba(52, 58, 64, 0.8117647059);
}

.button-icon {
  all: unset;
  border: none;
  cursor: pointer;
  padding: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: background-color 0.3s ease;
}

.button-icon .icon {
  width: 24px;
  height: 24px;
  fill: white;
}

.button-icon:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.button-icon:focus {
  outline: none;
  background-color: rgba(0, 0, 0, 0.2);
}

.button-icon:active {
  background-color: rgba(0, 0, 0, 0.3);
}

.modal-overlay-look-up-results {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80vw;
  display: block;
  align-items: center;
  justify-content: center;
  z-index: 1000000000000000000;
  overflow: auto;
  background-color: transparent;
  margin: 0 !important;
  padding: 0;
  box-sizing: border-box;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

.modal-overlay-look-up-results .box-container {
  margin: 0 !important;
}

.look-up-results-table-container {
  margin-bottom: 20px;
  border: 1px solid #d1d3d3;
  border-radius: 10px;
  overflow-x: auto;
  overflow-y: hidden;
  display: block;
}

.look-up-results-table-container::-webkit-scrollbar {
  height: 8px;
}

.look-up-results-table-container::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 5px;
}

.look-up-results-table-container::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.look-up-results-table-container table {
  width: 100%;
  min-width: 800px;
  border-collapse: collapse;
}

.look-up-results-table-container thead tr {
  background-color: #343a40;
  color: #d1d3d3;
}

.look-up-results-table-container th,
.look-up-results-table-container td {
  padding: 10px;
  text-align: center;
  border-bottom: 1px solid #d1d3d3;
  white-space: nowrap;
}

.look-up-results-table-container tbody tr:nth-child(odd) {
  background-color: #f8f9fa;
}

.look-up-results-table-container tbody tr:nth-child(even) {
  background-color: #e9ecef;
}

.look-up-results-table-container-lines {
  margin-bottom: 10px;
}

.look-up-results-table-headers {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  justify-items: center;
  align-items: center;
  width: 100%;
  padding: 10px;
  color: #d1d3d3;
  border-radius: 11px 11px 0px 0px;
  font-size: 20px;
}

.look-up-results-table-headers dl {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.look-up-results-table-headers dt {
  font-weight: bold;
  margin-bottom: 5px;
}

.look-up-results-table-headers a {
  font-size: 20px;
  text-align: center;
  color: #d1d3d3;
}

.look-up-results-table-headers .data-lookup-modules {
  color: red;
}

md-content, .stations-result {
  margin-bottom: 20px;
}

md-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: stretch;
}

.look-up-results-table-container th,
.look-up-results-table-container td {
  word-wrap: break-word;
  text-align: left;
}

.has-results {
  margin-bottom: 30px;
}

.autocomplete-container {
  position: relative;
  display: flex;
  align-items: center;
}

input[matInput] {
  flex: 1;
}

.clear-button {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: #666;
  cursor: pointer;
  font-size: 14px;
  line-height: 1;
  padding: 0;
}

.clear-button:hover {
  color: #000;
}

@media only screen and (max-width: 976px) {
  .modal-overlay-look-up-results {
    width: 85vw; /* Reduce modal width for better visibility on medium screens */
    padding: 15px; /* Add padding inside the modal */
  }
}
