.angular-date-picker {
    color: #333;
    font: normal 14px sans-serif;
    border: 1px solid #ddd;
    display: inline-block;
    background: #fff;
}
.angular-date-picker > ._month {
    text-align: center;
    line-height: 22px;
    padding: 10px;
    background: #fcfcfc;
    text-transform: uppercase;
    font-weight: bold;
    border-bottom: 1px solid #ddd;
    position: relative;
}
.angular-date-picker > ._month > button {
    color: #555;
    font: normal 14px sans-serif;
    outline: none;
    position: absolute;
    background: transparent;
    border: none;
    cursor: pointer;
}
.angular-date-picker > ._month > button:hover {
    color: #333;
}
.angular-date-picker > ._month > button._previous {
    left: 10px;
}
.angular-date-picker > ._month > button._next {
    right: 10px;
}
.angular-date-picker > ._days {
    width: 210px; /* 30 x 7 */
    margin: 10px;
    text-align: center;
}
.angular-date-picker > ._days > ._day-of-week, .angular-date-picker > ._days > ._day {
    box-sizing: border-box;
    border: 1px solid transparent;
    width: 30px;
    line-height: 28px;
    float: left;
}
.angular-date-picker > ._days > ._day-of-week {
    font-weight: bold;
}
.angular-date-picker > ._days > ._day.-padding {
    opacity: .5;
}
.angular-date-picker > ._days > ._day {
    cursor: pointer;
}
.angular-date-picker > ._days > ._day.-selected {
    background: #e0e0e0;
}
.angular-date-picker > ._days > ._day.-today {
    border-color: #aaa;
}
.angular-date-picker > ._days > ._day:hover {
    background: #eee;
}
.angular-date-picker > ._days:after {
    content: '';
    display: block;
    clear: left;
    height: 0;
}
