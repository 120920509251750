.help-mode {
    width: 53px;
    height: 26px;
    border: 1px solid #fff;
    float: right;
    margin-left: 10px;
    border-radius: 9px;
    position: relative;
    box-sizing: border-box;
    padding: 2px;
    cursor: pointer;
    background: #4d7c84;
}

.help-mode div {
    width: 46%;
    height: 100%;
    background: #faa21a;
    border-radius: 15px;
    -webkit-transition: all .25s linear 0s;
    -moz-transition: all .25s linear 0s;
    -ms-transition: all .25s linear 0s;
    -o-transition: all .25s linear 0s;
    transition: all .25s linear 0s;
    float: left;
}

.help-mode.on div, .help-mode.bright div {
    -webkit-transform: translateX(119%);
    -moz-transform: translateX(119%);
    -ms-transform: translateX(119%);
    -o-transform: translateX(119%);
    transform: translateX(119%);
}

.help-mode.on div {
    background: #33ac33;
}


.help-mode-container span {
    float: left;
    margin-top: 0px;
    font-size: 19px;
    font-weight: 500;
}

.desktop .help-mode-container span {
    color: #ffffff;
}

.skin-setting-dark .sidebar-switches .help-mode-container span {
    color: #004854;
}

.skin-setting-dark .sidebar-switches .help-mode-container span {
    color: #f0f4f5;
}

.help-message-box {
    position: fixed;
    bottom: 0px;
    width: 100%;
    height: 67px;
    background: #53b353;
    left: 0px !important;
    z-index: 1;
    color: #fff;
    padding: 20px 10px 15px 62px;
    box-sizing: border-box;
    font-size: 20px;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
    display: none;
}

.help-message-box.off {
    -webkit-transform: translateY(100px);
    -moz-transform: translateY(100px);
    -ms-transform: translateY(100px);
    -o-transform: translateY(100px);
    transform: translateY(100px);
}

.help-message-box button {
    float: left;
    background: #34833b !important;
    color: #fff !important;
    /* border: 2px solid green; */
    border-radius: 14px;
    font-size: 17px;
    margin-top: -7px;
    margin-right: 10px;
}

.turn-off-help-mode {
    float: right !important;
}

.main-help-text {
    float: left;
    margin-right: 50px;
}

.video-background {
    position: fixed;
    z-index: 4;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 50, .5);
    top: 0px;
}

.video-container {
    position: absolute;
    width: 1120px !important;
    left: 50%;
    margin-left: -560px !important;
    top: 40px;
}

.help-message-box-content {
    opacity: 0;
    -webkit-transition: opacity ease-in-out 0.7s;
    -moz-transition: opacity ease-in-out 0.7s;
    -ms-transition: opacity ease-in-out 0.7s;
    -o-transition: opacity ease-in-out 0.7s;
    transition: opacity ease-in-out 0.7s;
}


.animationIf.ng-enter,
.animationIf.ng-leave {
    -webkit-transition: opacity ease-in-out 1s;
    -moz-transition: opacity ease-in-out 1s;
    -ms-transition: opacity ease-in-out 1s;
    -o-transition: opacity ease-in-out 1s;
    transition: opacity ease-in-out 1s;
}

.animationIf.ng-enter,
.animationIf.ng-leave.ng-leave-active {
    opacity: 0;
}

.animationIf.ng-leave,
.animationIf.ng-enter.ng-enter-active {
    opacity: 1;
}

.help-text-container {
    position: absolute;
    width: 75%;
    height: auto;
    left: 12.5%;
    top: 40px;
    background-color: #1F3E43;
    padding: 20px 0 20px 10px;
    color: white;
    overflow: auto;
    max-height: 100%;
}

.help-text-container > div > div > ul > li {
    float: left;
    width: 100%;
    font-size: 16px;
    padding-bottom: 10px;
    border-bottom: 1px solid #0F2E43;
    padding-top: 8px;
    cursor: pointer;
}

.help-text-container > div > div > ul > li:hover {
    font-weight: bold;
}

.help-text-container .invisible-wrap.three-quarters-block.last {
    font-size: 24px;
    margin-top: 30px;
    padding-right: 10%;
    padding-left: 20px;
    margin-bottom: 30px;
    /* float: left; */
    /* width: 100%; */
}

.help-text-container .invisible-wrap.three-quarters-block button {
    /* float: left; */
    margin-top: 40px !important;
    /* display: block; */
    float: right;
    margin-left: 18px;
}

.help-text-background {
    position: fixed;
    z-index: 4;
    width: 100%;
    height: 100%;
    background: rgba(2,33, 38, 1);
    top: 0;
    left: 0;
}

.video-content {
    margin-right: auto;
    margin-left: auto;
    size: auto;
}