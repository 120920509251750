.product-name.obseckio {
    color: #fbaf4d;
    font-size: 21px;
    margin-bottom: 20px;
}

.breadcrumbs a, .breadcrumbs a {
    color: #ee3124;
}

.module-single-in-list {
    float: left;
    width: 100%;
    margin-top: 10px;
}

.module-single-in-list > div, .module-list-header-titles > div {
    float: left;
    width: 218px;
    font-size: 20px;
}

.single-question-setup-wrapper {
    float: left;
    width: 100%;
    box-sizing: border-box;
    position: relative;
    margin-bottom: 15px;
}

.single-question {
    float: left;
}

.single-step {
    float: left;
    width: 100%;
}

.step-image {
    float: none;
    width: 116px;
    margin-right: auto;
    padding-top: 16px;
    margin-left: auto;
    display: block;
}

.step-text {
    /* border: 1px solid #50597a; */
    float: left;
    width: 80%;
    box-sizing: border-box;
    margin-left: 10%;
    margin-top: 21px;
}

.step-text h3 {
    margin-top: 0;
}

.step-text h3 {
    margin-top: 0;
    font-size: 20px;
    color: #fff;
}

.step-text p {
    font-size: 17px;
}

.single-step {
    float: left;
    width: 19%;
    margin-bottom: 0;
    text-align: center;
    margin-right: 3%;
    position: relative;
    height: 288px;
}

.single-step.no-arrow::after {
    display: none;
}

.h2-my-modules {
    font-size: 25px;
}

.step-text h3 {
    margin-top: 0px;
    font-size: 17px;
    color: #fff;
    font-weight: normal;
    font-weight: lighter;
}

.icon-link.obseckio-module {
    margin-left: 0;
    border: none;
    background: #0fab55;
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    clear: both;
    float: left;
}

.single-step::after {
    position: absolute;
    height: 3px;
    width: 0;
    /* border-right: 10px solid #ddd; */
    border-left: 12px solid #455a81;
    content: "";
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    top: 31%;
    margin-top: -10px;
    right: -10%;
}

.module-setup-link a {
    color: #fbaf4d;
}

a.single-question {
    text-decoration: none !important;
    color: #c1c7db;
    font-size: 21px;
}

.new-field {
    float: left;
    width: 100%;
}

.invisible-wrap.one-third-block.first.question-section {
    margin-bottom: 32px;
}

.new-field-holder {
    float: left;
    width: 100%;
    font-size: 20px;
    margin-bottom: 11px;
}

.new-field-holder input[type="radio"] {
    margin-top: 5px;
    float: left;
}

.main-question {
    font-size: 24px;
    margin-bottom: 31px;
}

.answers-box a {
    text-decoration: none;
    color: #ee3124;
}

.sample-answer-box {
    float: left;
    width: 100%;
    /* margin-top: 30px; */
    /* background: #2b3145; */
    box-sizing: border-box;
    /* padding: 3px 20px 20px 20px; */
    border: none;
    padding-left: 0px;
    /* margin-bottom: 38px; */
}

.examples, .example.files, .user-files {
    float: left;
    margin-top: 0px;
    padding: 0;
    box-sizing: border-box;
    /* background: #2b3145; */
}

.question-box button.selected::after {
    border-left: 0px;
    content: "";
    position: absolute;
    left: 0;
    width: 16px;
    height: 16px;
    bottom: -9px;
    background: #fff;
    background: #dfe3e4 !important;
    border-top: none;
    transform: rotate(-135deg);
    left: 50%;
    margin-left: -11px;
}

.invisible-wrap.last.graph-and-message {
    margin-top: -1px !important;
    background: #f0f4f5 !important;
    float: right;
}

.skin-setting-dark .invisible-wrap.last.graph-and-message {
    background: #455052 !important;
}

.chart-explainer {
    visibility: hidden;
    position: absolute;
    background: #50597a;
    box-sizing: border-box;
    font-size: 13px;
    padding: 21px;
    color: #fff;
    transform: translateX(400px);
    -webkit-transform: translateX(400px);
}

.chart-container {
    float: left;
    width: 60%;
    margin-left: 20%;
    margin-bottom: 32px;
    position: relative;
}

.infolink::before {
    content: '?';
    display: inline-block;
    font-family: sans-serif;
    font-weight: bold;
    text-align: center;
    width: 15px;
    height: 14px;
    font-size: 15px;
    line-height: 1.8ex;
    border-radius: 4.2ex;
    padding: 3px;
    text-decoration: none;
    cursor: pointer;
}

.infolink:hover:before {
    text-decoration: none;
}

.compliance-content .invisible-wrap.two-thirds-block.first.position-relative {
    margin-top: 18px;
}

.go-to-review-link {
    color: #fff;
    text-align: left;
    margin-top: 20px;
    cursor: pointer;
}

.info-box {
    float: left;
    width: 100%;
    box-sizing: border-box;
    padding: 9% 10% 0% 10%;
    background: #003c46;
    color: #fff;
    position: relative;
}

.info-box > h2 {
    font-size: 22px;
    margin-bottom: 30px;
    font-weight: normal;
}

.navigation {
    float: left;
    width: 100%;
    margin-top: 35px;
}

.sub-question {
    float: left;
    width: 100%;
    margin-top: 5px;
}

img {
    max-width: 100%;
}

.example.files .inner-standard-box-wrapper div, .user-files > div {
    float: left;
    width: 99px;
    margin-right: 23px;
    text-align: center;
    font-size: 12px;
    cursor: pointer;
    /* padding: 19px; */
    font-weight: 600;
}

.question-box button,
.question-box input[type="submit"],
.question-box .standard-box input[type="button"],
.continual-assessment .answer-buttons button {
    /* color: #fff; */
    font-size: 17px;
    padding: 10px 27px;
    width: auto;
    border: none;
    cursor: pointer;
    float: left;
    position: relative;
    background: #0f1225 !important;
    color: #0ea9ad !important;
}

.question-box button.selected, .continual-assessment .answer-buttons button.selected {
    color: #0ea9ad;
    background: #dfe3e4 !important;
    /* border: 1px solid #0ea9ad; */
    font-weight: 600;
}

.delete {
    background: #e03232 !important;
}

/*button + button {*/
/*    margin-left: 10px;*/
/*}*/

.question-box {
    width: 100%;
    float: left;
    margin-bottom: 17px;
}

.hidden {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
}

.hidden.show {
    max-height: 1000px;
    transition: max-height 1s ease-in-out;
    overflow: visible;
}

.character-counter {
    display: block;
    margin-left: 0px;
    color: #fff;
    background: #E0AD34;
    padding: 2px 10px;
    border-radius: 10px;
    width: 50px;
    white-space: wrap !important;
    -moz-hyphens: none !important;
    hyphens: none !important;
    white-space: wrap !important;
    width: 71px;
    text-align: center !important;
    margin-top: 10px;
}

.character-counter.partially-complete {
    background: #E0AD34;
}

.character-counter.complete {
    background: #44A232;
}

.character-counter.incomplete {
    background: #FF6384;
}

.main-explainer-text {
    /* color: #0ea9ad; */
    font-size: 26px;
    float: left;
    width: 100%;
    box-sizing: border-box;
    /* padding-left: 6%; */
    margin-bottom: 30px;
    margin-top: 21px;
    position: relative;
}

.info-icon img {
    display: none;
}

.main-explainer-text .text {
    border-left: 3px solid;
    padding-left: 5%;
}

.info-icon {
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -21px;
}

.answers-box h3 {
    float: left;
    width: 100%;
    font-size: 17px;
    margin-bottom: -6px;
}

.sample-answer-box h3 {
    /* color: #b9bfd3; */
    margin-bottom: 8px;
    font-weight: bold;
    /* margin-top: 18px; */
}

.answer-buttons .yes, .answer-buttons .no {
    background: #f0f4f5 !important;
    color: #004854 !important;
}

.slide {
    float: left;
    /* max-width: 100px; */
    overflow-x: hidden;
    transform: translateX(111%);
    position: absolute;
    opacity: 0;
    -webkit-transition: all .6s ease 0s;
    -moz-transition: all .6s ease 0s;
    -ms-transition: all .6s ease 0s;
    -o-transition: all .6s ease 0s;
    transition: all .6s ease 0s;
}

.slide.show {
    transform: translateX(0%);
    position: relative;
    opacity: 1;
    margin-bottom: 100px;
}

.character-counter.partially-complete {
    background: #E0AD34;
}

.character-counter.complete {
    background: #44A232;
}

.character-counter.incomplete {
    background: #FF6384;
}

.chart-container {
    float: left;
    width: 70%;
    margin-left: 10%;
    margin-bottom: 32px;
    position: relative;
}

.chart-explainer {
    position: absolute;
    background: #50597a;
    box-sizing: border-box;
    font-size: 13px;
    padding: 21px;
    color: #fff;
    transform: translateX(500px);
    -webkit-transform: translateX(500px);
}

.review-numbers {
    /* position: absolute; */
    /* background: #50597a; */
    box-sizing: border-box;
    font-size: 19px;
    padding: 21px;
    float: left;
    width: 100%;
    margin-top: 25px;
    text-align: left;
    line-height: 1.5;
}

.chart-explainer.status-1 {
    visibility: visible;
    transform: translateX(0px);
    -webkit-transform: translateX(0px);
}

.chart-explainer div {
    margin-top: 8px;
}

.chart-explainer span {
    position: absolute;
    right: 12px;
    font-size: 20px;
    top: 5px;
    cursor: pointer;
}

.remaining-task, .assessment-question {
    float: left;
    width: 100%;
    padding: 16px 12px 16px 12px;
    box-sizing: border-box;
    background: rgb(255, 99, 132);
    border-bottom: 1px solid #fff;
    color: #fff;
}

.remaining-task.partially-complete {
    background: rgb(224, 173, 52);
}

.remaining-task.complete, .assessment-question.yes {
    background: rgb(68, 162, 50);
}

.assessment-question.n-a {
    background: rgb(164, 157, 160);
}

.previous-assessments {
    float: left;
    width: 50%;
    padding: 25px;
    box-sizing: border-box;
}

.previous-assessment {
    float: left;
    width: 100%;
    box-sizing: border-box;
    padding: 25px;
    border: 2px solid #68688b;
    margin-bottom: 35px;
}

.previous-assessment {
    float: left;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid aliceblue;
    padding: 25px;
    border: 2px solid #68688b;
}

.assessment-answers-wrapper {
    padding: 0;
    height: 0;
    overflow: hidden;
}

.previous-assessment.expired {
    opacity: .5;
}

.expired h2, .expired-text {
    color: #fff;
}

.view-more {
    cursor: pointer;
    float: right;
}

.remaining-task a, .assessment-question a {
    text-decoration: navajowhite;
    float: right;
    padding-right: 20px;
    font-size: 18px;
    color: #fff;
    font-weight: bold;
    clear: both;
}

.section-title {
    font-weight: bold !important;
}

.section-title input {
    border: none !important;
    font-size: 23px;
    padding: 0;
    font-weight: bold !important;
}

.previous {
    float: left;
    border-color:white ;
}

.next {
    border-color: white;
}

.slide .standard-box {
    margin-bottom: 30px;
}
.compliance-content .invisible-wrap.two-thirds-block {
    width: 65.5%;
}

.compliance-content {
    max-width: none !important;
    margin-left: 233px;
    background: none;
    height: 100%;
    float: left;
    width: calc(100% - 300px);
    box-sizing: border-box;
    padding-left: 25px;
    /* border-top: 1px solid #272c49; */
    position: relative;
}

.chart-container h2, .review-page h2 {
    text-align: left;
    width: 100%;
    margin-top: 30px;
    font-size: 17px;
}

.info-box > div {
    color: #fff;
    font-size: 17px;
    line-height: 31px;
    margin-top: -8px;
    padding-bottom: 92px;
    width: 100%;
    box-sizing: border-box;
    padding-right: 6%;
}

.invisible-wrap.one-third-block.graph-and-message.last {
    overflow: visible;
}

.hide {
    display: none;
}

.show {
    display: inline;
}

.notAnswered {
    opacity: .3;
}

.answers-box {
    position: relative;
    float: left;
    width: 100%;
    overflow: hidden;
}

.not-answered-overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    /* text-align: center; */
    z-index: 1;
}

.sample-answer-box.further-reading-link > div {
    float: left;
    width: 100%;
    margin-top: 0px;
}

.further-reading-link a {
    color: #ee3124;
    font-weight: 600;
}

.yes-answers h3 {
    margin-bottom: 21px;
    font-weight: 600;
}

input, select, textarea {
    float: left;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    margin-bottom: 10px;
    background: #fff !important;
    border: none !important;
    font-size: 15px;
    font-weight: 500 !important;
    font-family: 'Montserratt', sans-serif;
    border-radius: 5px !important;
}

.skin-setting-dark input, .skin-setting-dark select, .skin-setting-dark textarea {
    background: #c8cbcb !important;

}

.user-files {
    margin-top: 0;
}

.yes-answers > div {
    float: left;
    width: 100%;
    box-sizing: border-box;
    padding: 22px 26px;
    margin-bottom: 20px;
}

.upload-file-form {
    width: 100% !important;
}

.upload-file-form button {
    font-size: 15px;
    padding: 5px 25px;
    border-radius: 5px;
    float: left;
}

.uploaded-file .delete {
    font-size: 13px;
    padding: 8px 25px !important;
    margin-top: 10px;
    border-radius: 5px;
}

.sections-for-navigation {
    float: left;
    width: 100%;
    position: relative;
}

.sections-for-navigation {
    height: 0;
    padding: 0;
    overflow: hidden;
    position: absolute;
    left: 0;
    z-index: 5;
    background: #f0f4f5;
    width: 457px;
    top: 39px;
    border-radius: 8px;
}

.skin-setting-dark .sections-for-navigation {
    background: #455052;
}

.sections-for-navigation.show {
    height: auto;
    overflow: visible;
    padding: 10px;
}

.go-to-section-button {
    font-size: 20px;
    margin-top: 6px;
    color: #ee3124;
    cursor: pointer;
    width: auto;
    float: left;
    font-weight: 600;
}

.go-to-section-button.review {
    float: right;
}

.go-to-section-button.review.link {
    display: inline-block;
    position: relative;
    z-index: 1;
    padding: 2em;
    margin: -2em;
}

.go-to-section-button:hover {
    color: #d31609;
}

.position-relative {
    position: relative;
}

.single-section-for-navigation a {
    color: #ee3124;
    text-decoration: none;
    margin-bottom: 4px;
    float: left;
    clear: both;
    font-weight: 600;
}

button:focus {
    outline: 0;
}

.single-section-for-navigation {
    float: left;
    width: 100%;
    margin-bottom: 5px;
    border-bottom: 1px solid #fff;
    padding-bottom: 5px;
}


.skin-setting-dark .single-section-for-navigation {
    border-bottom: 1px solid #656565;
}
.invisible-wrap {
    overflow: visible;
}

.company-information-slide .character-counter {
    margin-left: 0;
    margin-top: -14px;
    float: left;
    margin-bottom: 14px;
}

.question-explainer-for-mobile, .question-explainer-for-mobile:after {
    display: none;
}

.sidebar-item.o-assessment span {
    margin-top: -2px;
}

.audit-item {
    float: left;
    width: 100%;
}

.audit-item-title {
    float: left;
    margin-right: 25px;
    margin-top: 5px;
}

.audit-item-statuses {
    clear: both;
    margin-top: 10px !important;
    float: left;
}

.audit-item .description {
    float: left;
    width: 40%;
    margin-top: 10px;
}

.audit-item .description input {
    width: 100%;
    height: 38px;
    box-sizing: border-box;
}

.audit-item button {
    float: left;
    margin-right: 10px;
    opacity: .5;
}

.delete {
    background: #e03232 !important;
}

.outstanding-assessment {
    float: left;
    width: 100%;
    border: 2px solid #68688b;
    box-sizing: border-box;
    padding: 25px;
    margin-bottom: 33px;
}

.submit-assessment button {
    float: left;
    margin-top: 20px;
    margin-bottom: 20px;
}

.h1-welcome {
    font-weight: normal;
    margin-bottom: 32px;
}

.save-button {
    background: #58cb78;
    background: -moz-linear-gradient(top, #58cb78 0%, #35b835 100%);
    background: -webkit-linear-gradient(top, #58cb78 0%, #35b835 100%);
    background: linear-gradient(to bottom, #58cb78 0%, #35b835 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#58cb78', endColorstr='#35b835', GradientType=0);
    border-color: white;

}

.saving {
    background: rgba(59, 119, 219, 0.4);
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -9999;
    opacity: 0;
}

.save-box {
    width: 100px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -50px;
    margin-top: -50px;
}

.save-box div {
    color: #fff;
    text-align: center;
    margin-bottom: 30px;
    font-size: 30px;
}

.saving.show {
    z-index: 9999;
    opacity: 1;
}

.company-information-slide .next {
    margin-left: 16px;
    border-color: white;
}

.continual-assessment .standard-box > .box-title {
    margin-top: 26px;
}

.continual-assessment .answer-buttons {
    text-align: center;
    margin-top: 23px;
    margin-bottom: 40px;
}

.continual-assessment .answer-buttons button {
    float: none;
    text-align: center;
    margin: 0;
    display: inline;
    margin-right: 20px;
}

.module-assessment-container {
    box-sizing: border-box;
    float: left;
    width: 100%;
    padding: 30px;
}

.module-assessment-container h3 {
    float: left;
}

.outstanding-assessment h3 span {
    border-right: 3px solid #a9b3d6;
    margin-right: 10px;
    padding-right: 10px;
}

.outstanding-assessment h3 span:last-child {
    border: none;
}

.field-answer {
    float: left;
    width: 100%;
    margin-top: 5px;
    font-style: italic;
}

.generated-pdf {
    float: left;
    width: 80px;
    margin-right: 20px;
    margin-top: -9px;
    margin-bottom: 30px;
    cursor: pointer;
}

.generated-pdf:hover {
    opacity: .8;
}

.help-mode-container {
}

.locked img {
    float: left;
    width: 61px;
    margin-right: 30px;
    margin-top: -16px;
}

.locked {
    margin-top: 37px;
    color: #fff;
    font-size: 21px;
}

.locked-explainer img {
    float: left;
    width: 52px;
    margin-right: 20px;
    margin-bottom: 30px;
    margin-top: 18px;
}

.review-page .locked img {
    margin-top: 0;
}

.sub-question {
    float: left;
    width: 100%;
    margin-top: 5px;
}

.progress-bar div {
    float: left;
    height: 100%;
}

.progress-bar {
    float: left;
    width: 100%;
    height: 50px;
    display: inline;
    border-radius: 10px;
    margin-bottom: 10px;
}


.progress-bar-wrapper h2 {
    color: #fff;
}

.complete-review-single-item .remaining-task {
    font-size: 14px;
    padding: 10px 10px 10px 10px;
}

.complete-review-single-item .remaining-task a {
    font-size: 15px;
}

.complete-review-single-item canvas {
    width: 80% !important;
    margin-left: 10%;
}

.complete-review-single-item canvas {
    width: 200px !important;
    margin-left: 10%;
    height: 200px !important;
}

.complete-review-single-item .review-numbers {
    font-size: 16px;
    margin-top: -2px;
    line-height: 1.3;
}

.complete-review-single-item {
    padding: 50px;
    box-sizing: border-box;
    /* border: 1px solid #515c62; */
    /* background: #455a65; */
}

.invisible-wrap.full-size-block.complete-review-single-item.standard-box.ng-scope {
    margin-top: 10px;
    margin-bottom: 20px;
}

.chart-container h2, .review-page h2 {
    /* color: #fff; */
    width: 100%;
    font-size: 22px;
    text-align: center;
    margin-bottom: 20px;
    font-weight: 600;
    margin-top: 0px;
}

.chart-container h2 {
    /* color: #fff; */
    margin-top: 20px;
}

.review-page h2 {
    text-align: left;
}

.go-to-review-link {
    color: #ee3124;
    text-align: center;
    margin-top: 20px;
    cursor: pointer;
    font-weight: 600;
}

.review-numbers {
    /* position: absolute; */
    /* background: #50597a; */
    box-sizing: border-box;
    font-size: 19px;
    padding: 21px;
    float: left;
    width: 100%;
    margin-top: 25px;
    text-align: left;
    line-height: 1.5;
}

.product-list > div {
    float: left;
    color: #1aabaa;
    font-size: 23px;
    width: 418px;
}

.product-list > div a {
    color: #1aabaa;
    text-decoration: none;
}

.product-list > .obseckio-link, .product-list > .obseckio-link a {
    color: #fbaf4d;
    text-decoration: none;
}

.product-list > .obseckio-link a {

}

.product-list div img {
    float: left;
    margin-right: 12px;
}

.product-list div div {
    float: left;
    margin-top: 13px;
}

.product-name {
    float: left;
    width: 100%;
}

.product-name img {
    margin-left: 0px;
}

.product-name div {
    margin-top: 8px;
}

.fields-preview .multi-button {
    opacity: 1;
}


.product-list > .obseckio-link, .product-list > .obseckio-link a {
    color: #fbaf4d;
    text-decoration: none;
}

.product-list > .obseckio-link a {

}

.logo.obseckio a span {
    font-size: 12px;
    margin-left: 2px;
    margin-top: -1px;
}

.logo.obseckio a {
    margin-top: 10px;
    color: #fbaf4d;
}

.update-field-wrapper {
    float: left;
    width: 100%;
    border: 3px solid #f2f2f2;
    box-sizing: border-box;
    padding: 15px;
}

.new-field-holder pre, .update-field-wrapper pre {
    display: inline !important;
    color: grey !important;
}

.field-mulitbutton-html-advice.ng-binding.ng-scope {
    background: #013a44;
    float: left;
    box-sizing: border-box;
    padding: 10px 20px;
    margin-bottom: 20px;
}

.hide.except-for19 {
    display: inline;
}

.field-mulitbutton-html-advice.hide.except-for19 {
    background: #013a44;
    padding: 10px 23px;
    float: left;
    margin-bottom: 20px;
}

.blocworx-forms-statement {
    float: left;
    clear: both;
    margin-right: 10px;
}

.blocworx-forms-container .operation-station {
    clear: both;
    width: auto;
    padding-right: 84px;
    min-width: 328px;
}