/* Badminton */

.blocworx-customer-badminton .header,.blocworx-customer-badminton .header .logo, .blocworx-customer-badminton .sidebar-item {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#00a5e6+0,187bc8+100 */
  background: #00a5e6; /* Old browsers */
  background: -moz-linear-gradient(top,  #00a5e6 0%, #187bc8 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top,  #00a5e6 0%,#187bc8 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom,  #00a5e6 0%,#187bc8 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00a5e6', endColorstr='#187bc8',GradientType=0 ); /* IE6-9 */

}


.blocworx-customer-badminton .sidebar .account span span {
  color: #004854 !important;
}

.blocworx-customer-badminton .sidebar .account span.username-text {
  color: #004854 !important;
  margin-left: 22px;
}

.blocworx-customer-badminton .sidebar .account span.username-text {
  color: #004854;
}

.blocworx-customer-badminton .sidebar-item {
  margin-bottom: 0px;
  border-left: 0px;
}

.blocworx-customer-badminton .header {
  border-bottom: 20px solid #80c036 !important;
  height: 97px;
}


.blocworx-customer-badminton .account::before {
  float: left;
  width: 200px;
  content: "";
  height: 57px;
  background: url(https://static.tournamentsoftware.com/images/style/badmintonireland/logo.png);
  z-index: 89;
  background-size: 198px;
  background-position: 13px 0px;
  display: none;
}

.blocworx-customer-badminton .content {
  border-top: 0px;
}


.blocworx-customer-badminton .header .logo {
  padding-left: 0px;
  width: 359px;
}

.blocworx-customer-badminton .logo a {
  margin-top: 0px;
}

.blocworx-customer-badminton .logo a::before {
  width: 190px;
  float: left;
  height: 76px;
  content: "";
  background: url(https://static.tournamentsoftware.com/images/style/badmintonireland/logo.png);
  background-color: #fff;
  background-size: 193px;
  background-position: 14px 6px;
}

.blocworx-customer-badminton .sidebar-item img,.blocworx-customer-badminton  .sidebar-item span {
  float: left;
  color: #fff !important;
}

.blocworx-customer-badminton .sidebar-item path {
  fill:#fff !important;
}

.blocworx-customer-badminton .customer-logo {
  display: none;
}

.blocworx-customer-badminton .logo img {
  max-width: none;
  width: 213px;
  margin-left: -59px;
  margin-top: 14px;
  /* display: none; */
  height: 94px;
}

.blocworx-customer-badminton .account::before {
  display: none;
}

.blocworx-customer-badminton .login-page h1 {
  color: #023038  !important;
}

.blocworx-customer-badminton .login-page h3 {
  color: #023038  !important;
}

.blocworx-customer-badminton .login-page label {
  color: #013E4A  !important;
  font-weight: bold  !important;
}

.blocworx-customer-badminton .breadcrumbs a,.blocworx-customer-badminton .breadcrumbs a {
  color: #0e8bd3 !important;
}

.blocworx-customer-badminton .field-button {
  background: #0e8bd3 !important;
}

.blocworx-customer-badminton button:not(.multi-button),.blocworx-customer-badminton  input[type="submit"]:not(.multi-button), .blocworx-customer-badminton .standard-box input[type="button"]:not(.multi-button),.blocworx-customer-badminton  .button:not(.multi-button) {
  background: #0e8bd3 !important;
}

.blocworx-customer-badminton .login-page input, .blocworx-customer-badminton  select,.blocworx-customer-badminton  textarea {
  border: 1px solid #004854;
  color: #013E4A;
}


/* Biofab */

.blocworx-customer-biofab .header .logo.biofab img {
  display: none;
}

.blocworx-customer-biofab .logo.biofab::before {
  content: "";
  background: url(https://images.squarespace-cdn.com/content/5dce678eec8b6014128d9cc6/1573808781730-74DNGW2K78D5ELDRHLYB/MediumSquareLogo.png?content-type=image%2Fpng);
  float: left;
  width: 200px;
  height: 100%;
  background-size: 35%;
  background-repeat: no-repeat;
  background-position: 26px;
}

.blocworx-customer-biofab .customer-logo {
  display: none;
}

.blocworx-customer-iqutech-ir .section-id-134, .blocworx-customer-iqutech-ir .section-id-327 {
  display: none !important;
}


/* ODM Eazy Account */

.blocworx-customer-odm-eazy .header .logo.odm-eazy img {
  display: none;
}

.blocworx-customer-odm-eazy .logo.odm-eazy::before {
  content: "";
  background: url('https://www.blocworx.com/odm-eazy.png');
  float: left;
  width: 231px;
  height: 100%;
  background-size: 68%;
  background-repeat: no-repeat;
  background-position: 26px;
  left: 0px;
  padding-left: 0px;
}

.blocworx-customer-odm-eazy .header {
  border-bottom: 3px solid #335884;
  background: #63bed7;
}

.blocworx-customer-odm-eazy .customer-logo {
  display: none;
}

.blocworx-customer-eazy-accounts .form-results-section td img {
  max-width: 90px;
}

.blocworx-customer-eazy-accounts #calendar-wrapper, .blocworx-customer-eazy-accounts .calendar-click {
  display: none;
}

.blocworx-customer-eazy-accounts .locked-filter {
  opacity: 1 !important;
}

/*!* TODO: this should be removed afterwards. *!*/
/*.blocworx-customer-eazy-accounts .breadcrumbs {*/
/*  display: none !important;*/
/*}*/

.blocworx-customer-eazy-accounts .undo-button, .blocworx-customer-eazy-accounts .undo-button[style] {
  display: none !important;
}

/*.blocworx-customer-eazy-accounts .manage-data-sidebar {*/
/*  display: none !important;*/
/*}*/

.blocworx-customer-eazy-accounts .pagination-top .checkbox-wrapper {
  display: none;
}

.blocworx-customer-eazy-accounts .scan-station-header-edit-data.header-button {
  display: none !important;
}

.blocworx-customer-eazy-accounts th .data-filtering-wrapper {
  display: none;
}

#iframe.blocworx-customer-eazy-accounts edit-data , #iframe.blocworx-customer-eazy-accounts .pagination-top, #iframe.blocworx-customer-eazy-accounts .data-filtering-section, #iframe.blocworx-customer-eazy-accounts search-data {
  display: none;
}

.blocworx-customer-eazy-accounts .data-results-fieldid-122335.red, .blocworx-customer-eazy-accounts .data-results-fieldid-122335.green {
  background: none !important;
  color: #004854 !important;
}

.blocworx-customer-eazy-accounts td.flex-row:not(.data-results-fieldid-122408.red, .data-results-fieldid-122408.green) {
  background: none !important;
  color: #004854 !important;
}


@media (max-width: 976px) {
  .flex-row.data-results-fieldid-122434, .flex-row.data-results-fieldid-122434 a, .flex-row.data-results-fieldid-122435, .flex-row.data-results-fieldid-122435 a {
    font-size: 10px;
  }
  .flex-row.data-results-fieldid-122434 a, .flex-row.data-results-fieldid-122435 a {
    font-weight: normal;
  }

  .data-results-fieldid-122434, .flex-row.data-results-fieldid-122434 a, .data-results-fieldid-122435, .flex-row.data-results-fieldid-122435 a {
    padding: 6px;
    border: none;
  }

  .flex-row.data-results-fieldid-122297 {
    font-size: 10px;
  }

  .flex-row.data-results-fieldid-122361, .flex-row.data-results-fieldid-122359 {
    font-size: 12px;
  }

  .blocworx-customer-eazy-accounts #add_invoice_lines {
    border: 9px solid #9e9c9c;
  }

  .blocworx-customer-eazy-accounts .table-container td {
    font-size: 12px;
  }

}
