.sym {
  display: inline-block;
  vertical-align: middle;
  border-color: #000;
}

.sym.caret-up {
  border-left: 0.250em solid transparent;
  border-right: 0.250em solid transparent;
  border-bottom: 0.250em solid;
  width: 0;
  height: 0;
}
.sym.caret-down {
  border-left: 0.250em solid transparent;
  border-right: 0.250em solid transparent;
  border-top: 0.250em solid;
  width: 0;
  height: 0;
}
.sym.caret-left {
  border-top: 0.250em solid transparent;
  border-bottom: 0.250em solid transparent;
  border-right: 0.250em solid;
  width: 0;
  height: 0;
}
.sym.caret-right {
  border-bottom: 0.250em solid transparent;
  border-top: 0.250em solid transparent;
  border-left: 0.250em solid;
  width: 0;
  height: 0;
}

.cal-container {
  min-width: 320px;
  max-width: 100%;
  width: 100%;
  background: #f0f0f0;
}

.controls .fa {
  border: none;
}



.calendar {
  display: block;
  position: relative;
  width: 100%;
  border: 1px solid #000;
  clear: both;
  overflow: hidden;
  padding: 5px;
  margin: 0;
}

.calendar .cal-header {
  position: relative;
  width: 100%;
  padding: 10px;
}
.calendar .cal-header .title {
  color: #006;
  font-size: 1.5em;
  line-height: 1em;
  text-align: center;
}

.calendar .controls {
  font-size: 1.5em;
  height: auto;
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
}
.calendar .controls > * {
  pointer-events: initial;
}

.calendar .controls .btn-prev, .calendar .controls .btn-next {
  font-size: 1.5em;
  position: absolute;
  overflow: hidden;
  height: 40px;
}

.controls .fa {
  border: none;
  /* height: 10px; */
  position: relative;
  float: left;
  position: relative;
  top: -15px;
  left: -3px;
}

.controls .fa::before {
  position: absolute;
  top: 0px;
  left: 0px;
}
.calendar .controls .btn-prev {
  left: 0;
}
.calendar .controls .btn-next {
  right: 0;
}

.calendar .cal-view {
  font-size: 1.5em;
  height: auto;
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
}
.calendar .cal-view > * {
  pointer-events: initial;
}
.calendar .cal-view .btn-week,
.calendar .cal-view .btn-month {
  font-size: 1em;
  position: absolute;
}
.calendar .cal-view .btn-week {
  left: 2em;
}
.calendar .cal-view .btn-month {
  right: 2em;
}

.calendar .weekdays {
  position: relative;
  width: 100%;
  border-bottom: 1px dotted rgba(0,0,0,0.3);
}
.calendar .weekdays .weekday {
  display: inline-block;
  width: 14.2857142857%;
  padding: 10px 5px;
  color: rgba(0,0,0,0.3);
  text-align: center;
}







.calendar .dates {
  list-style-type: none;
  padding: 0;
  margin: 0;
  width: 100%;
  overflow: hidden;
}
.calendar .date-box {
  display: block;
  position: relative;
  float: left;
  width: 14.2857142857%;
  color: #000;

  border-width: 0 1px 1px 0;
  border-color: rgba(0,0,0,0.3);
  border-style: dotted;
}
/* every 7th child no right */
.calendar .date-box:nth-child(7n) {
  border-right-color: transparent;
}
/* last 7 children no bottom */
.calendar .date-box:nth-last-child(-n+7) {
  border-bottom-color: transparent;
}


.calendar .date-box {
  cursor: pointer;
}
.calendar .date-box:before {
  content:"";
  display:block;
  margin-top: 100%;
}
.calendar .date-box > .date-box-content {
  bottom: 10%;
  left: 10%;
  position: absolute;
  right: 10%;
  text-align: center;
  top: 10%;
}

.calendar .date {
  display: inline-block;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}


.calendar .date-box.dim .date {
  color: #888;
}

.calendar .date-box.has-event > .date-box-content {
  border-radius: 50%;
  background-color: rgba(0,0,0,0.2);
}
.calendar .date-box.has-event .date {
  color: #000;
}

.calendar .date-box.today > .date-box-content  {
  border-radius: 50%;
  background-color: rgba(0,0,0,0.6);
}
.calendar .date-box.today .date {
  color: #fff;
}

.calendar .date-box.selected > .date-box-content  {
  border-radius: 50%;
  background-color: rgba(0,126,0,0.6);
}
.calendar .date-box.today .date {
  color: #fff;
}

.calendar .date-box.today.selected > .date-box-content  {
  background-color: rgba(0,0,126,0.6);
}
.calendar .date-box.today.selected .date {
  color: #fff;
}

.cal-events {
  display: block;
  position: relative;
  width: 100%;
  border: 1px solid #000;
  border-top: none;
  clear: both;
  overflow: hidden;

  padding: 5px;
  margin: 0;
}
.cal-events .events {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.cal-events .no-event {
  width: 100%;
  padding: 5px 0;
  font-size: 1em;
  text-align: center;
}
.cal-events .event {
  width: 100%;
  padding: 5px 0;
  border-top: 1px solid rgba(0,0,0,0.5);
  font-size: .8em;
}
.cal-events .event:first-child {
  border-top: none;
}
.cal-events .event .title {
  font-weight: bold;
}
.cal-events .event .dtstart {

}
.cal-events .event .description {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.cal-events .event .location {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}