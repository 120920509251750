/* Generated by Glyphter (http://www.glyphter.com) on  Tue Jan 12 2021*/
@font-face {
  font-family: 'Glyphter';
  src: url('fonts/Glyphter.eot');
  src: url('fonts/Glyphter.eot?#iefix') format('embedded-opentype'),
  url('fonts/Glyphter.woff') format('woff'),
  url('fonts/Glyphter.ttf') format('truetype'),
  url('fonts/Glyphter.svg#Glyphter') format('svg');
  font-weight: normal;
  font-style: normal;
}
[class*='icon-']:before{
  display: inline-block;
  font-family: 'Glyphter';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}
#full-calendar [class*='icon-']:before{
    font-family: inherit;
}

.icon-computer-and-infobox:before{content:'\0041';}
.icon-gear-in-profile:before{content:'\0042';}
.icon-guy-with-headset:before{content:'\0043';}
.icon-hanging-notes-unsure:before{content:'\0044';}
.icon-home:before{content:'\0045';}
.icon-i-symbol-in-word-bubble:before{content:'\0046';}
.icon-large-circle-branching-to-three-circles:before{content:'\0047';}
.icon-magnifying-glass-search:before{content:'\0048';}
.icon-movie-camera:before{content:'\0049';}
.icon-padlock:before{content:'\004a';}
.icon-paper-plane-in-word-bubble:before{content:'\004b';}
.icon-pencil-ruler:before{content:'\004c';}
.icon-people:before{content:'\004d';}
.icon-profile-in-circle:before{content:'\004e';}
.icon-reload-symbol:before{content:'\004f';}
.icon-right-arrow:before{content:'\0050';}
.icon-sand-timer:before{content:'\0051';}
.icon-security-badge-lock:before{content:'\0052';}
.icon-seismograph:before{content:'\0053';}
.icon-signature:before{content:'\0054';}
.icon-temperature:before{content:'\0055';}
.icon-three-paper-sheets-with-text:before{content:'\0056';}
.icon-two-paper-sheets-with-text:before{content:'\0057';}
.icon-vertical-piled-layers:before{content:'\0058';}
.icon-virus-hazard:before{content:'\0059';}
.icon-visitor-sign-in:before{content:'\005a';}
.icon-4-boxes-coms:before{content:'\0061';}
.icon-4-boxes-with-one-filled:before{content:'\0062';}
.icon-antistatic:before{content:'\0063';}
.icon-barcodes:before{content:'\0064';}
.icon-basic-line-graph:before{content:'\0065';}
.icon-big-gear-small-gear:before{content:'\0066';}
.icon-blocks-and-shapes-piled-up:before{content:'\0067';}
.icon-box-with-lid:before{content:'\0068';}
.icon-bullet-points:before{content:'\0069';}
.icon-certificate-training:before{content:'\006a';}
.icon-clipboard-with-tick:before{content:'\006b';}