.multi-button[data-field-id="85843"] + .multi-button[data-field-id="85843"] {
    background: #bfbf2a !important;
}

.multi-button[data-field-id="85843"] + .multi-button[data-field-id="85843"] + .multi-button[data-field-id="85843"] {
    background: #dd9612 !important;
}

.multi-button[data-field-id="85843"] + .multi-button[data-field-id="85843"] + .multi-button[data-field-id="85843"] + .multi-button[data-field-id="85843"] {
    background: red !important;
}

.blocworx-customer-eazy-accounts .file-upload-container #getUserMediaButton {
    display: none;
}

.blocworx-customer-eazy-accounts .scan-station-buttons {
    position: relative;
}

.blocworx-customer-eazy-accounts .invisible-wrap.full-size-block.back-button {
    display: inline !important;
}


.blocworx-customer-eazy-accounts .data-results-fieldid-122335.red, .blocworx-customer-eazy-accounts .data-results-fieldid-122335.green {
    background: none !important;
    color: #004854 !important;
}

