@media (min-width: 979px) and (max-width:1340px)  {


    .mobile {
        display:none;
    }

    .icon-link {
        font-size: 17px;
    }

    .standard-box .box-title {
        font-size: 18.2px;
    }

    .standard-box.digital-signature {
        width: 80%;
    }

   .standard-box.import-job select {
        width: 100%;
    }

}